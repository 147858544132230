export const ImageDataset = {
  // Backgrounds

  background: require("../../assets/Background.webp"),
  backgroundCharms: require("../../assets/BackgroundCharms.webp"),
  backgroundMessage: require("../../assets/BackgroundMessage_d.webp"),
  backgroundMessageDesktop: require("../../assets/BackgroundMessage_d.webp"),

  logo_b: require("../../assets/icons/logo_b.png"),

  frameFinalVideo: require("/assets/frame_final_video.webp"),
  frameFinalVideoQuad: require("/assets/frame_final_video_quad.webp"),

  header: require("../../assets/icons/logo.png"),

  // Mood Selector

  moodCare: require("../../assets/moods/mood_care.webp"),
  moodCareMobile: require("../../assets/moods/mood_care_m.webp"),
  moodCareDesktop: require("../../assets/moods/mood_care_d.webp"),
  moodLove: require("../../assets/moods/mood_love.webp"),
  moodLoveMobile: require("../../assets/moods/mood_love_m.webp"),
  moodLoveDesktop: require("../../assets/moods/mood_love_d.webp"),
  moodJoy: require("../../assets/moods/mood_joy.webp"),
  moodJoyMobile: require("../../assets/moods/mood_joy_m.webp"),
  moodJoyDesktop: require("../../assets/moods/mood_joy_d.webp"),

  // Templates
  messageCard: require("../../assets/MessageCard1.png"),

  // Icons

  icon_magic: require("../../assets/icons/magic.png"),
};
