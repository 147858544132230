import IStep, { OperationType } from '../../data/dataInterfaces'
import { useJourneyStore } from '../../store/JourneyStore'
import { __ } from '../../translations/translations'
import { Identifiers } from '../../utils/Indentifiers'
import { localeSelectDataset, shopSelectDataset } from '../selectsDataset'
import { askForUserConfirm, translate } from '../../utils/utils'

export const storeSetupData: IStep = {
  title: '',
  id: Identifiers.SETUP_STORE_LOCALE,
  operations: {
    type: OperationType.store_setup,
    config: {
      // ------- SELECT ------- //
      // ------- To be integrated with the correct data ------- //
      select: [
        {
          id: Identifiers.SETUP_STORE_LOCALE,
          title: '$storeSetup.locale',
          options: localeSelectDataset,
          hideSelector: false,
        },
        {
          id: Identifiers.SETUP_STORE_ID_STORE,
          title: '$storeSetup.shopId',
          storeCode: true,
          withIdText: true,
          options: shopSelectDataset,
          hideSelector: false,
        },
      ],
    },
  },
  actions: [
    {
      id: '',
      title: '$next',
      btnType: 'filled',
      size: 'default',
      action: () => {
        const selectedLocale = useJourneyStore.getState().selectedLocale
        const selectedStoreId = useJourneyStore.getState().selectedStoreId
        if (!selectedLocale && !selectedStoreId) {
          askForUserConfirm('', translate('$errors.fillAllFields'))
          return
        }
        if (!selectedLocale) {
          askForUserConfirm('', translate('$errors.fillAField') + '' + translate('$storeSetup.locale').toUpperCase())
          return
        }

        if (!selectedStoreId) {
          askForUserConfirm('', translate('$errors.fillAField') + translate('$storeSetup.shopId').toUpperCase())
          return
        }

        if (selectedLocale && selectedStoreId) {
          useJourneyStore.getState().setDoExitAnimation()
        }
      },
    },
  ],
}
