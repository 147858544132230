import { useRef, useLayoutEffect } from "react"

export const SuspendPinchZoom = ({ children }: { children: any }) => {
    const ref = useRef(null)
    // const ref = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        const target: any = ref.current
        if (!target) return
        const disablePinchZoom = (e) => {
            if (e.touches.length > 1) {
                e.preventDefault()
            }
        }
        target.addEventListener('touchmove', disablePinchZoom, { passive: false })
        return () => {
            target.removeEventListener('touchmove', disablePinchZoom)
        }
    }, [])
    return <div style={{
        width: '100%',
        height: '100%',
    }} ref={ref}>{children}</div>
}
