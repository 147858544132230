import {
	Dimensions,
	NativeModules,
	Platform,
	Pressable,
	ScrollView,
	View,
} from "react-native";
import { useJourneyStore } from "../../../store/JourneyStore";
import { styles } from "./SendMultiEmail.style";
import { useEffect, useRef, useState } from "react";
import { Checkbox } from "../../Checkbox/Checkbox";
import BvlgariText from "../../TextElement/BvlgariText";
import { ActionButton } from "../../ActionButton/ActionButton";
import { IStepAction } from "../../../data/dataInterfaces";
import { MotiView, View as MView } from "moti";
import CryptoJS, { enc } from "react-native-crypto-js";
import { Identifiers } from "../../../utils/Indentifiers";
import InputElement from "../inputElement/InputElement";
import SelectElement from "../../Select/SelectElement";
import ReCAPTCHA from "react-google-recaptcha";
import Constants from "expo-constants";

var Aes = NativeModules.Aes;

interface SendMultiEmailProps {
	config: any;
	isInvite?: boolean;
}

const SendMultiEmail = ({ config, isInvite = false }: SendMultiEmailProps) => {
	const appInLocal = window?.location?.href.includes("localhost");
	const appProd = Constants?.manifest?.extra?.APP_PROD;
	/* ------------------- PROPS ------------------- */
	const { config: configuration, actions } = config;
	const { firstInfo, secondInfo, privacyPolicyConditions, lastCheckbox } =
		configuration;

	/* ------------------- STATE ------------------- */

	const [mobileView, setMobileView] = useState<boolean>(
		Platform.OS === "android" || Platform.OS === "ios",
	);
	const [windowWidth, setWindowWidth] = useState<number>(
		Dimensions.get("window").width,
	);
	const [windowHeight, setWindowHeight] = useState<number>(
		Dimensions.get("window").height,
	);

	/* ------------ FOR RECAPCHA ------------- */
	const recaptchaRef = useRef<ReCAPTCHA>(null);
	const [sendEnabled, setSendEnabled] = useState<boolean>(false);
	function onChange(value: any) {
		/* console.log("On Change ReCaptcha: ", value); */
		if (value) {
			setSendEnabled(true);
		}
	}

	const _actions = actions as IStepAction[];
	/* ------------------- ZUSTAND ------------------- */

	const { CRIPT_KEY, VECTOR_KEY, experienceId, experienceQtyId, isIos } =
		useJourneyStore.getState();

	/* ------------------- METHODS ------------------- */
	/* -------- CRIPTAGGIO -------- */
	const encrypt = (text: string) => {
		// const wordArray = Crypto.enc.Utf8.parse(iv)
		// const encrypted = AES.encrypt(text, Crypto.enc.Utf8.parse(key), {
		//   iv: wordArray,
		//   padding: Crypto.pad.Pkcs7,
		//   mode: Crypto.mode.CBC,
		// })
		// console.log('encrypted', encrypted)
		// return encrypted.ciphertext.toString(Crypto.enc.Base64)
		var key = CryptoJS.enc.Utf8.parse("H&#XyGcbqX6ZG%d-XZC*wr$E?Rp67#M6");
		var iv = CryptoJS.enc.Utf8.parse("N6b%.xn8pgs*Jz!@");
		var encryptedlogin = CryptoJS.AES.encrypt(
			CryptoJS.enc.Utf8.parse(text),
			key,
			{
				keySize: 256 / 8,
				iv: iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7,
			},
		);
		return encryptedlogin.ciphertext.toString(CryptoJS.enc.Base64);
	};

	const encryptData = () => {
		/*   if (isIos === null) return;
        else { */
		const readerId = encrypt(
			`${appProd ? experienceId : experienceQtyId}_${isIos}`,
		);
		// console.log("roba da criptare", `${experienceId}_${isIos}`);
		// console.log("readerId criptato", readerId);

		return useJourneyStore.getState().setReaderId(encodeURI(readerId));
		/* } */

		/*   return useJourneyStore.getState().setReaderId('tus3XksQyk/r2SmpamH7lC8NDv1MHbaHcVdXmac8m+risLmd+Yd7enveGn2rxuqZ') */
	};

	// controllo viewport width se non siamo nella mobile mode
	const controlViewport = () => {
		const { width, height } = Dimensions.get("window");
		setMobileView(width < 525); // 600 per dire che è mobile
		setWindowWidth(width);
		setWindowHeight(height);
	};

	// ----------------------- Invio dati allo store ( ZUSTAND )
	const onFormChange = (id: string, value: any /* isValid?: boolean */) => {
		/*    console.log("id", id, "value", value); */

		// ------- update userSendSelfEmail checkbox
		if (id === "sendToMySelf") {
			useJourneyStore.getState().toggleUserSendSelfEmail(value);
			return;
		}

		// ------- send email to user
		if (id === Identifiers.FIELD_USER_PERSON_SEND_EMAIL) {
			// console.log('authorization privacy', value)
			useJourneyStore.getState().setEmailToSend(value);
			return;
		}

		// ------- update per il l'autorizzazione della privacy
		if (id === Identifiers.FIELD_USER_PRIVACY_AUTHORIZATION) {
			// console.log('authorization privacy', value)
			useJourneyStore.getState().setUserPrivacyAuthorization(value);
			return;
		}

		// ------- update country region
		if (id === Identifiers.FIELD_USER_COUNTRY_REGION) {
			const options = configuration.firstInfo.country.select.options;

			const option = options.find((c: any) => c.title === value);

			useJourneyStore.getState().updateUserDataField(id, option!.id);

			return;
		}

		useJourneyStore.getState().updateUserDataField(id, value);
	};

	/* ------------------  ACTIONS */
	const Actions = () => {
		const allActionsIsSmall = _actions.every(
			(action) => action.size === "smallBtn",
		);
		return (
			<View
				style={[
					styles.actionsContainer,
					allActionsIsSmall && styles.allSmallBtn,
				]}
			>
				{_actions.map((action, index) => {
					return (
						<ActionButton
							key={index}
							action={action}
							isDisabled={!sendEnabled}
						/>
					);
				})}
			</View>
		);
	};

	useEffect(() => {
		encryptData();
		controlViewport();
		if (!mobileView) {
			Dimensions.addEventListener("change", controlViewport);
		}

		return () => {
			removeEventListener?.("change", controlViewport);
		};
	}, []);

	useEffect(() => {
		if (!sendEnabled) {
			recaptchaRef?.current?.execute();
		}
	});

	return (
		<>
			<MotiView
				from={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={() => {
					"worklet";
					return {
						opacity: 0,
					};
				}}
				exitTransition={{ type: "timing", duration: 1500 }}
				transition={{ type: "timing", duration: 1500 }}
				style={styles.absoluteContainer}
			>
				<MView
					from={{
						opacity: 0,
						scale: 0.8,
					}}
					animate={{
						opacity: 1,
						scale: 0.8,
					}}
					transition={{
						type: "timing",
						duration: 800,
					}}
					style={[
						styles.content,
						windowWidth < 400
							? styles.contentSmallMobile
							: windowWidth < 600
							? styles.contentMobile
							: isIos && windowWidth > 520 && windowWidth < 1280
							? styles.contentTabletWeb
							: windowWidth > 1280
							? styles.contentDesktop
							: {},
					]}
				>
					{/* ---------------- TITLE ---------------- */}
					<BvlgariText
						text={configuration.title}
						textType='xlLightUp'
						style={{
							color: "#473213",
							fontSize:
								windowWidth < 390
									? 22
									: windowWidth < 600
									? 26
									: 34,
							fontWeight: "300",
							lineHeight:
								windowWidth < 390
									? 24
									: windowWidth < 600
									? 32
									: 48,
							letterSpacing: 5,
						}}
					/>
					{/* ---------------- SCROLL FORM CONTAINER ---------------- */}
					<ScrollView
						style={[
							styles.scrollContainer,
							mobileView &&
								windowWidth < 600 &&
								styles.scrollMobile,
							windowHeight < 900
								? windowWidth > 1600
									? styles.scrollDesktop
									: windowWidth > 600 && windowWidth < 1600
									? styles.scrollDesktopSmall
									: styles.scrollMobile
								: {},
						]}
						showsVerticalScrollIndicator={false}
					>
						{/* ----------- INFO CONTAINER (FORM) ----------- */}
						<View
							style={[
								styles.infoContainer,
								windowWidth < 390
									? styles.infoContainerSmallMobile
									: windowWidth < 600
									? styles.infoContainerMobile
									: !isIos &&
									  isInvite &&
									  windowWidth > 520 &&
									  windowWidth < 1280
									? styles.InfoContainerTabletWebInvite
									: {},
								isInvite
									? windowWidth < 390
										? styles.infoContainerSmallMobileInvite
										: windowWidth < 600
										? styles.infoContainerMobileInvite
										: windowWidth > 1200
										? styles.InfoContainerDesktopInvite
										: {}
									: {},
							]}
						>
							{/* ---------------------------- FROM SECTION */}
							<View
								style={[
									styles.firstInfo,
									windowWidth < 600
										? styles.firstInfoMobile
										: {},
								]}
							>
								{/* ---------------------------- TITLE */}
								<BvlgariText
									text={configuration.firstInfo.title}
									textType='xlBoldUp'
									style={{
										fontWeight: "700",
										fontSize: 28,
										color: "#473213",
									}}
								/>
								{/* ---------------------------- INPUT & SELECT CONTAINER */}
								<View
									style={[
										styles.inputForm,
										windowWidth < 600
											? styles.inputFormMobile
											: {},
									]}
								>
									<View
										style={[
											styles.inputFromContainer,
											windowWidth < 600
												? { rowGap: 16, columnGap: 16 }
												: {},
										]}
									>
										<InputElement
											{...firstInfo.input[0]}
											onChange={onFormChange}
											isRequired={
												firstInfo.input[0].isRequired
											}
											styleCustom={
												windowWidth < 600
													? {
															minWidth: "100%",
															maxWidth: "100%",
															maxHeight: 42,
													  }
													: undefined
											}
										/>
										<InputElement
											{...firstInfo.input[1]}
											onChange={onFormChange}
											isRequired={
												firstInfo.input[1].isRequired
											}
											styleCustom={
												windowWidth < 600
													? {
															minWidth: "100%",
															maxWidth: "100%",
															maxHeight: 42,
													  }
													: undefined
											}
										/>
										<InputElement
											{...firstInfo.input[2]}
											onChange={onFormChange}
											isRequired={
												firstInfo.input[2].isRequired
											}
											styleCustom={
												windowWidth < 600
													? {
															minWidth: "100%",
															maxWidth: "100%",
															maxHeight: 42,
													  }
													: undefined
											}
										/>
										<SelectElement
											{...firstInfo.country}
											onChange={onFormChange}
											styleCustom={
												windowWidth < 600
													? {
															minWidth: "100%",
															maxWidth: "100%",
															maxHeight: 42,
													  }
													: undefined
											}
										/>
									</View>
								</View>
								{/* ---------------------------- CHECKBOX CONTAINER */}
								{firstInfo.checkbox && (
									<View
										style={[
											styles.checkboxForm,
											windowWidth < 390
												? styles.checkboxFormSmallMobile
												: windowWidth < 600
												? styles.checkboxFormMobile
												: {},
										]}
									>
										<Checkbox
											checkOpt={firstInfo.checkbox}
											id={firstInfo.checkbox.id}
											rtl={false}
											onChange={onFormChange}
										/>
									</View>
								)}
							</View>
							{/* ---------------------------- TO SECTION*/}
							<View
								style={[
									styles.secondInfo,
									,
									windowWidth < 600
										? styles.secondInfoMobile
										: {},
								]}
							>
								{/* ---------------------------- TITLE */}
								<BvlgariText
									text={secondInfo.title}
									textType='xlBoldUp'
									style={{
										fontWeight: "700",
										fontSize: 28,
										color: "#473213",
									}}
								/>

								{/* ---------- RECIPIENT'S EMAIL */}
								<View style={styles.emailForm}>
									<InputElement
										{...secondInfo.input[0]}
										onChange={onFormChange}
										isRequired={
											secondInfo.input[0].isRequired
										}
									/>
								</View>
							</View>
						</View>

						{/* -----------  CHECKBOX ----------- */}
						<View
							style={[
								styles.checkboxContainer,
								windowWidth < 400
									? {
											minWidth: 0,
											maxWidth: 635,
											paddingHorizontal: 6,
									  }
									: windowWidth < 600
									? {
											minWidth: 0,
											maxWidth: 635,
											paddingHorizontal: 10,
									  }
									: {},
							]}
						>
							{/* ----------- CHECKBOX PRIVACY POLICY CONDITIONS ----------- */}
							<View
								style={[
									styles.privacyPolicyConditions,
									windowWidth < 600
										? {
												paddingVertical: 10,
												paddingHorizontal: 10,
										  }
										: {},
								]}
							>
								<Checkbox
									checkOpt={privacyPolicyConditions}
									id={privacyPolicyConditions.id}
									rtl={false}
									onChange={onFormChange}
								/>
							</View>

							{/* ----------- SECOND CHECKBOX ----------- */}
							<View
								style={[
									styles.lastCheckboxContainer,
									windowWidth < 600
										? {
												paddingHorizontal: 10,
												paddingVertical: 14,
										  }
										: {},
								]}
							>
								<BvlgariText
									text={lastCheckbox.title}
									textType='sItalic'
									style={{
										color: "#473213",
										fontSize: 14,
										fontWeight: "700",
										lineHeight: 19.46,
										textAlign: "justify",
										paddingLeft: 8,
										paddingRight: 8,
									}}
								/>
								<View style={styles.lastCheckbox}>
									<Checkbox
										checkOpt={lastCheckbox.dataProcessing}
										id={lastCheckbox.dataProcessing.id}
										rtl={false}
										onChange={onFormChange}
										isAccordion={true}
									/>
									<Checkbox
										checkOpt={lastCheckbox.marketing}
										id={lastCheckbox.marketing.id}
										rtl={false}
										onChange={onFormChange}
										isAccordion={true}
									/>
								</View>
							</View>
						</View>

						{/* ---------------------------- Action container */}
						<View style={[styles.bottomContainer]}>
							<Pressable
								style={[{ minWidth: "100%" }]}
								onPress={() => recaptchaRef?.current?.execute()}
							>
								{Actions()}
							</Pressable>
						</View>
					</ScrollView>
				</MView>
			</MotiView>
			{/* ------------- RECATPCHA ---------- */}
			<View
				style={{
					alignContent: "center",
					width: "100%",
					alignItems: "center",
					zIndex: 9999999999,
				}}
			>
				<ReCAPTCHA
					ref={recaptchaRef}
					sitekey='6LcTq4EpAAAAAD-TQqUko3dvHhiIbKZ-ioePOM9o'
					size='invisible' //invisible, normal, compact
					onChange={onChange}
					badge='none'
				/>
			</View>
		</>
	);
};

export default SendMultiEmail;
