import IStep, { OperationType } from "../../data/dataInterfaces";
import { useJourneyStore } from "../../store/JourneyStore";
import { translate } from "../../utils/utils";

export const reloadModal: IStep = {
    title: "",
    id: "tutorial",
    operations: {
        type: OperationType.reloadModal,
        config: {
            icon: "x-icon",
            cards: [
                {
                    id: "restart",
                    title: "$reloadModal.title",
                    titleLong: "$reloadModal.titleLong",
                    questions: "$reloadModal.questions",
                    btnRestartLang: "$reloadModal.btnRestartLang",
                    btnRestart: "$reloadModal.btnRestart",
                    btnError: "$reloadModal.btnError",
                    btnBack: "$reloadModal.btnBack",
                },
            ],
            action: () => {
                useJourneyStore.getState().onChangeFalseModal(null);
                useJourneyStore.getState().setNewLanguage(null);
                useJourneyStore.getState().setUserConfirm("", null);
            },
        },
    },
};
