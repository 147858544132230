import React from "react";
import { View, Image } from "react-native";
import { styles } from "./Tutorial.styles";
import TutorialChip from "./TutorialChip";
import BvlgariText from "../TextElement/BvlgariText";
import { translate } from "../../utils/utils";

interface Props {
    card: { id: string; title: string; image: string };
}

const TutorialCard = ({ card }: Props) => {
    return (
        <View style={styles.card}>
            <TutorialChip step={translate(card.id)} />
            <BvlgariText
                text={translate(card.title)}
                textType='xlLight'
                style={{ color: "#B0882F", fontSize: 18 }}
            />
            <Image
                style={{ flex: 1 }}
                resizeMode='contain'
                source={card.image}
            />
        </View>
    );
};

export default TutorialCard;
