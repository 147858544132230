import { Platform, StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  titleContainer: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },

  shadow: {
    textShadowColor: 'rgba(0, 0, 0, 0.35)',
    textShadowOffset: { width: -1, height: -1 },
    textShadowRadius: 6,
  },

  // --------- EXTRA LARGE TEXTS --------- //
  xlLight: {
    color: '#ffffff',
    fontSize: 34,
    fontFamily: 'BulgariLight',
    fontWeight: '300',
  },
  xlLightUp: {
    color: '#ffffff',
    fontSize: 34,
    fontFamily: 'BulgariLight',
    fontWeight: '300',
    textTransform: 'uppercase',
  },
  xlBold: {
    color: '#ffffff',
    fontSize: 34,
    fontFamily: 'BulgariBold',
    fontWeight: 'bold',
    letterSpacing: 0.64,
  },
  xlBoldUp: {
    color: '#ffffff',
    fontSize: 34,
    fontFamily: 'BulgariBold',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  // --------- LARGE TEXTS --------- //
  l: {
    color: '#ffffff',
    fontSize: 26,
    fontFamily: 'BulgariBold',
    fontWeight: '400',
  },
  lBold: {
    color: '#ffffff',
    fontSize: 26,
    fontFamily: 'BulgariBold',
    fontWeight: 'bold',
  },
  // --------- MEDIUM TEXTS --------- //
  mRegular: {
    color: '#ffffff',
    fontSize: 18,
    lineHeight: 20,
    fontFamily: 'BulgariRegular',
    fontWeight: '400',
    letterSpacing: 0.6,
    ...Platform.select({ ios: {}, default: { wordSpacing: 2.2 } }),
  },
  mRegularBtn: {
    color: '#ffffff',
    fontSize: 18,
    lineHeight: 20,
    fontFamily: 'BulgariRegular',
    fontWeight: '400',
    letterSpacing: 1.28,
  },
  mBold: {
    color: '#ffffff',
    fontSize: 18,
    lineHeight: 20,
    fontFamily: 'BulgariBold',
    fontWeight: 'bold',
    letterSpacing: 0.64,
  },
  mBoldUnderline: {
    color: '#ffffff',
    fontSize: 18,
    lineHeight: 20,
    fontFamily: 'BulgariRegular',
    fontWeight: 'bold',
    letterSpacing: 0.64,
    textDecorationLine: 'underline',
  },
  mBoldUp: {
    color: '#ffffff',
    fontSize: 18,
    lineHeight: 20,
    fontFamily: 'BulgariRegular',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: 1.28,
  },
  // --------- REGULAR TEXTS --------- //
  sRegular: {
    color: '#ffffff',
    fontSize: 16,
    fontFamily: 'BulgariBold',
    fontWeight: '400',
    textDecorationLine: 'none',
  },
  sItalic: {
    color: '#ffffff',
    fontSize: 16,
    fontFamily: 'BulgariBold',
    fontWeight: '400',
    fontStyle: 'italic',
  },
  sBold: {
    color: '#ffffff',
    fontSize: 16,
    fontFamily: 'BulgariBold',
    fontWeight: 'bold',
  },
})
