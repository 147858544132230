import { View, Text } from 'react-native'
import React from 'react'
import { styles } from './CarouselPoint.styles'

export interface CarouselPointProps {
  totalSteps: number
  indefOfActiveStep: number
}

export default function CarouselPoint({ totalSteps, indefOfActiveStep }: CarouselPointProps) {
  return (
    <View style={styles.container}>
      {Array.from({ length: totalSteps }, (_, i) => (
        <View
          key={i}
          style={[
            {
              backgroundColor: i === indefOfActiveStep ? 'rgba(81, 42, 4, 1)' : 'rgba(81, 42, 4, 0.25)',
              width: 8,
              height: 8,
              borderRadius: 4,
              margin: 4,
            },
          ]}
        ></View>
      ))}
    </View>
  )
}
