import React, { useEffect } from 'react'
import { View } from 'react-native'
import { styles } from './RegisterDropdown.style'
import { Identifiers } from '../../../utils/Indentifiers'
import { FormGenerator } from '../Generator/FormGenerator'
import { ScrollView } from 'react-native'
import { isIos } from '../../../utils/utils'
import { countrySelectDataset, titlesDataset } from '../../../configuration/selectsDataset'
import { useJourneyStore } from '../../../store/JourneyStore'

interface Props {
  isOpen: boolean
  registerConfig: any
  onChange: (id: string, value: any) => void
}
const RegisterDropdown = ({ isOpen, registerConfig, onChange }: Props) => {
  // console.log(registerConfig)

  const { gender, country, japanName, japanSurname, phoneNumber, interestField, policyPrivacy, otherCheckbox } =
    registerConfig

  const formData = [
    // gender
    {
      id: Identifiers.FIELD_USER_GENDER,
      type: 'select',
      select: gender,
    },
    // country region
    {
      id: Identifiers.FIELD_USER_COUNTRY_REGION,
      type: 'select',
      select: country,
    },
    // japan name
    {
      type: 'input',
      ...japanName,
    },
    // japan surname
    {
      type: 'input',
      ...japanSurname,
    },
    // phone number
    {
      type: 'input',
      ...phoneNumber,
    },
    // interests list
    {
      id: Identifiers.FIELD_USER_INTEREST,
      type: 'checkbox-selector',
      subtitle: interestField.interestTitle,
      grid: true,
      exclusive: false,
      options: interestField.options,
    },
    // privacy policy
    {
      type: 'checkbox',
      id: Identifiers.FIELD_USER_PRIVACY_AUTHORIZATION,
      rtl: false,
      highligted: true,
      checkOpt: policyPrivacy,
    },
    // otherCheckbox
    {
      type: 'text',
      id: Identifiers.FIELD_USER_PRIVACY_AUTHORIZATION,
      text: otherCheckbox.text,
      textType: 'sItalic',
      style: { textAlign: isIos ? 'left' : 'start', maxWidth: '100%' },
    },
    {
      type: 'checkbox',
      id: Identifiers.FIELD_USER_PROFILE_AUTHORIZATION,
      rtl: false,
      checkOpt: otherCheckbox.checkBox[0],
    },
    {
      type: 'checkbox',
      id: Identifiers.FIELD_USER_MARKETING_AUTHORIZATION,
      rtl: false,
      checkOpt: otherCheckbox.checkBox[1],
    },
  ]

  return (
    <View style={[styles.registerContainer, isOpen && styles.registerOpen]}>
      <ScrollView style={{ width: '100%' }}>
        <FormGenerator isRegister={true} form={formData} onChange={onChange} />
      </ScrollView>
    </View>
  )
}

export default RegisterDropdown
