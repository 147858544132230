import { Dimensions, Image, TouchableOpacity } from "react-native";
import { styles } from "./ActionButton.styles";
import { BtnType, IStepAction } from "../../data/dataInterfaces";
import BvlgariText from "../TextElement/BvlgariText";
import { useState } from "react";
import SoundPlayer from "../../utils/SoundManager";

export interface IActionButtonProps {
    action: IStepAction;
    currentStepIndex?: number;
    is3Btn?: boolean;
    isDisabled?: boolean | undefined;
}

export function ActionButton(props: IActionButtonProps) {
    const { title, icon, action, disabled, btnType, size } = props.action;
    const { currentStepIndex, is3Btn, isDisabled = undefined } = props;
    const [enabled, setEnabled] = useState(true);
    const [windowWidth] = useState<number>(Dimensions.get("window").width);

    const debounceAction = () => {
        if (enabled) {
            setEnabled(false);
            SoundPlayer.playSound("click");
            action();
            setTimeout(() => {
                setEnabled(true);
            }, 1500);
        }
    };

    const allSmallBtnFontSizeMobile =
        windowWidth < 600 ? { fontSize: 12, lineHeight: "96.5%" } : {};

    const enumBtnText = {
        default: (
            <BvlgariText
                text={title}
                textType='mBRegular'
                style={{ color: "#473213", textTransform: "uppercase" }}
            />
        ),
        outlined: (
            <BvlgariText
                text={title}
                textType='mBRegular'
                style={{ color: "#FFFAEC", textTransform: "uppercase" }}
            />
        ),
        filled: (
            <BvlgariText
                text={title}
                textType='mBRegular'
                style={{ color: "#473213", textTransform: "uppercase" }}
            />
        ),
        gold: (
            <BvlgariText
                text={title}
                textType='mBRegular'
                style={{ color: "#ffffff", textTransform: "uppercase" }}
            />
        ),
        backButton: (
            <BvlgariText
                text={title}
                textType='mBRegular'
                style={{ color: "#473213", textTransform: "uppercase" }}
            />
        ),
        browned: (
            <BvlgariText
                text={title}
                textType='mBRegular'
                style={{ color: "#ffffff", textTransform: "uppercase" }}
            />
        ),
        downloadBrown: (
            <BvlgariText
                text={title}
                textType='mBRegular'
                style={{
                    color: "#FFFAEC",
                    textTransform: "uppercase",
                    ...allSmallBtnFontSizeMobile,
                }}
            />
        ),
        sharedWhite: (
            <BvlgariText
                text={title}
                textType='mBRegular'
                style={{
                    color: "#A4651E",
                    textTransform: "uppercase",
                    ...allSmallBtnFontSizeMobile,
                }}
            />
        ),
    };

    if (btnType && size)
        return (
            <TouchableOpacity
                accessibilityLabel={
                    title === "$send"
                        ? "Send Button"
                        : title === "$download"
                        ? "Download Button"
                        : title === "$finalVideoPage.buttonSendArtwork"
                        ? "Go to Send Artwork Page"
                        : title === "$finalVideoPage.buttonDownloadArtwork"
                        ? "Go to Download Artwork Page"
                        : "Go next Page"
                }
                style={[
                    styles.container,
                    styles[btnType as BtnType],
                    styles[size ?? "default"],
                    currentStepIndex === 6 && windowWidth < 376 && is3Btn
                        ? { height: 32, maxWidth: "48%", minWidth: "40%" }
                        : windowWidth < 600
                        ? { height: 36 }
                        : {},
                    { opacity: 1 },
                ]}
                disabled={isDisabled !== undefined ? isDisabled : disabled}
                onPress={debounceAction}
            >
                {enumBtnText[btnType as BtnType]}
                {icon && <Image style={styles.icon} source={icon} />}
            </TouchableOpacity>
        );

    return (
        <BvlgariText text={title} textType='mBoldUp' style={styles.onlyText} />
    );
}
