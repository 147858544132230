import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  registerScrollContainer: {
    backgroundColor: 'rgba(255,255,255, 0.2)',
    height: 0,
    maxHeight: 0,
    width: '100%',
    overflow: 'hidden',
    opacity: 0,
    borderRadius: 10,
    transition: '.3s ease-in-out',
    display: 'flex',
  },
  registerContainer: {
    backgroundColor: 'rgba(255,255,255, 0.2)',
    height: 0,
    maxHeight: 0,
    width: '100%',
    overflow: 'hidden',
    opacity: 0,
    borderRadius: 10,
    transition: '.3s ease-in-out',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: 28,
  },

  registerOpen: {
    height: 325,
    maxHeight: 325,
    overflow: 'hidden',
    opacity: 1,
    padding: 20,
  },

  genderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 16,
  },

  checkBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  interestContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: 32,
  },

  checkBoxInterestContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    rowGap: 24,
  },

  interestItem: {
    width: '35%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  wrapperLastCheckbox: {},

  policyPrivacyContainer: {
    flex: 1,
    backgroundColor: 'rgba(71,50,19, 0.2)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
    padding: 12,
    borderRadius: 10,
  },

  otherCheckboxContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    gap: 16,
    paddingLeft: 12,
  },

  otherCheckbox: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    gap: 16,
  },
})
