import React, { useCallback, useEffect } from "react";
import { SocketManager } from "../utils/SocketManager";
import { VIDEO_IDLE_DURATION } from "../utils/utils";
import { ImageBackground } from "react-native";
import { ImageDataset } from "../configuration/ImageDataset";
import { AnimatePresence, MotiView } from "moti";

function VideoScreen() {
    const idleVideoUrl =
        "https://bulgari-digital-craft.s3.eu-central-1.amazonaws.com/bulgari_videos/video-loop-magnificent.mp4";

    const [videoUrl, setVideoUrl] = React.useState<string | undefined>(
        undefined,
    );
    const videoRef = React.useRef<HTMLVideoElement>(null);

    useEffect(() => {
        // da rendere dinamico tramite recupero parametro da url della stanza
        SocketManager.getInstance().connectAndListen(handleMessageReceived);
    }, []);

    const handleMessageReceived = useCallback((payload: any) => {
        if (payload.video_url) {
            setVideoUrl(payload.video_url);
            //console.log('payload', payload)
            videoRef.current?.load();
            startCountDownForIdle();
        } else if (payload.video_status === "start_new_experience") {
            setVideoUrl(undefined);
        }
    }, []);

    let countDownTimeout: any = null;

    const startCountDownForIdle = () => {
        if (countDownTimeout) {
            clearTimeout(countDownTimeout);
            countDownTimeout = null;
        }
        countDownTimeout = setTimeout(() => {
            /*   setVideoUrl(idleVideoUrl); */
            setVideoUrl(undefined);
            videoRef.current?.load();
            clearTimeout(countDownTimeout);
            countDownTimeout = null;
        }, VIDEO_IDLE_DURATION);
    };

    return (
        <div className='VideoScreen' style={{ display: "flex", flex: 1 }}>
            <AnimatePresence exitBeforeEnter>
                {videoUrl ? (
                    <MotiView
                        from={{ opacity: 0 }}
                        key={`video_generated_messageTemplate`}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        exitTransition={{ type: "timing", duration: 1500 }}
                        transition={{ type: "timing", duration: 1500 }}
                        style={{
                            position: "absolute",
                            bottom: 0,
                            top: 0,
                            height: "100%",
                            width: "100%",
                        }}
                    >
                        <video
                            ref={videoRef}
                            style={{
                                objectFit: "contain",
                                bottom: 0,
                                top: 0,
                                height: "100%",
                                width: "100%",
                            }}
                            autoPlay
                            muted
                            loop
                            playsInline
                        >
                            <source src={videoUrl} type='video/mp4' />
                        </video>
                    </MotiView>
                ) : (
                    <MotiView
                        from={{ opacity: 0 }}
                        key={`video_idle_messageTemplate`}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        exitTransition={{ type: "timing", duration: 1500 }}
                        transition={{ type: "timing", duration: 1500 }}
                        style={{
                            position: "absolute",
                            bottom: 0,
                            top: 0,
                            height: "100%",
                            width: "100%",
                        }}
                    >
                        <video
                            ref={videoRef}
                            style={{
                                objectFit: "contain",
                                bottom: 0,
                                top: 0,
                                height: "100%",
                                width: "100%",
                            }}
                            autoPlay
                            muted
                            loop
                            playsInline
                        >
                            <source src={idleVideoUrl} type='video/mp4' />
                        </video>
                    </MotiView>
                )}
            </AnimatePresence>
        </div>
    );
}

export default VideoScreen;
