import IStep, { OperationType, TFalseModal } from "../../data/dataInterfaces";
import { useJourneyStore } from "../../store/JourneyStore";
import { __ } from "../../translations/translations";
import { Identifiers } from "../../utils/Indentifiers";
import { SocketManager } from "../../utils/SocketManager";
import SoundPlayer from "../../utils/SoundManager";
import { countrySelectDataset } from "../selectsDataset";

export const welcomePageData: IStep = {
    id: "",
    operations: {
        type: OperationType.welcome_page,
        config: {
            firstAction: {
                title: "$welcomePage.firstAction.title",
                subtitle: "$welcomePage.firstAction.subtitle",
            },
            secondAction: {
                title: "$welcomePage.secondAction.title",
                subtitle: "$welcomePage.secondAction.subtitle",
            },
            action: {
                id: "generate_now",
                title: "$welcomePage.buttonGenerate",
                btnType: "filled",
                size: "bigBtn",
                action: () => {
                    useJourneyStore
                        .getState()
                        .setDoExitAnimation(
                            undefined,
                            undefined,
                            undefined,
                            true,
                        );
                    SocketManager.getInstance().emitNewExperience();
                    SoundPlayer.playSoundLoop(), 200;
                    setTimeout(() => {
                        useJourneyStore.setState({
                            hideHeader: false,
                            hideActionButton: true,
                            hideFooter: false,
                        });
                    }, 1500);
                },
            },
        },
    },
};
