import React, { useEffect, useRef, useState } from "react";
import {
    KeyboardTypeOptions,
    Pressable,
    Text,
    TextInput,
    View,
} from "react-native";
import { styles } from "./InputElement.styles";
import BvlgariText from "../../TextElement/BvlgariText";
import PrefixSelect from "./prefixSelect/PrefixSelect";
import { translate } from "../../../utils/utils";
import { useJourneyStore } from "../../../store/JourneyStore";

interface Props {
    id?: string;
    label: string;
    error: string;
    placeholder: string;
    defaultValue: string;
    keyboardType: KeyboardTypeOptions;
    prefix?: string;
    onReturnInputValue?: (text: string, isError: boolean, id: string) => void;
    onReturnPhoneNumber?: (
        phoneNumber: { prefix: string; num: string },
        isError: boolean,
    ) => void;
    isRequired: boolean;
    onChange?: (id: string, value: string /* isValid?: boolean */) => void;
    customWidth?: string;
    styleCustom?: any;
}

const InputElement = ({
    label,
    error,
    placeholder,
    defaultValue,
    keyboardType,
    prefix,
    onReturnInputValue,
    onReturnPhoneNumber,
    isRequired,
    id,
    customWidth,
    onChange,
    styleCustom: stylesCustom,
}: Props) => {
    const [inputValue, setInputValue] = useState<string>(defaultValue);
    const [isError, setIsError] = useState<boolean>(false);
    const [prefixValue, setPrefixValue] = useState<
        { title: string; id: string } | undefined
    >({ id: "-1", title: "+39" } ?? undefined);

    const enumRegex = {
        emailaddress: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        default: /^[a-zA-Z ]{3,40}$/,
        numeric: /^[0-9]{10,11}$/,
    };

    const onChangePrefix = (data: { title: string; id: string }) =>
        setPrefixValue(data);

    const inputValidation = (value: string) => {
        const inputType = keyboardType
            .toLowerCase()
            .replace("-", "") as keyof typeof enumRegex;
        const regexType = enumRegex[inputType];
        const isValid = !regexType.test(value);
        // TODO da valutare se mettere un controllo per il nome o per il required
        // per ora ho messo l'input name ma si potrebbe mettere !isRequired
        if (label.toLocaleLowerCase() === "name") return isValid;
        setIsError(isValid);
        return isValid;
    };

    const onChangeAndValidation = (value: string) => {
        let valueToSendAtParent = value;
        if (keyboardType === "numeric") {
            valueToSendAtParent = value.replace(/[^0-9]/g, "");
        }
        const isValid = inputValidation(valueToSendAtParent);
        setInputValue(valueToSendAtParent);

        const _value = prefix
            ? `${prefixValue!.title} ${valueToSendAtParent}`
            : valueToSendAtParent;

        /*   if (keyboardType === 'email-address' && onChange) {
      onChange?.(id!, _value, isValid)
    } else if (onChange) onChange?.(id!, _value) */

        onChange?.(id!, _value);

        // passaggio dati al padre
        if (onReturnPhoneNumber) {
            onReturnPhoneNumber(
                { prefix: prefixValue!.title, num: valueToSendAtParent },
                !isValid,
            );
            return;
        }
        if (onReturnInputValue) {
            onReturnInputValue(valueToSendAtParent, !isValid, id!);
        }
    };

    useEffect(() => {
        if (defaultValue !== "") {
            setInputValue(defaultValue);
        }
    });

    //cose brutte by lore
    const { userData, emailToSend } = useJourneyStore((state) => {
        return { userData: state.userData, emailToSend: state.emailToSend };
    });

    if (id === "JapanFirstName" || id === "JapanLastName") {
        if (userData?.CountryRegion !== "JP") {
            return <></>;
        }
    }

    if (id === "FirstName") {
        inputValue === userData?.FirstName;
    }

    if (id === "LastName") {
        inputValue === userData?.LastName;
    }

    if (id === "PersonEmail") {
        inputValue === userData?.PersonEmail;
    }

    if (id === "SendEmail") {
        inputValue === emailToSend;
    }

    const calculateValue = (value: string) => {
        if (id === "FirstName") {
            return userData?.FirstName;
        }

        if (id === "LastName") {
            return userData?.LastName;
        }
        if (id === "PersonEmail") {
            return userData?.PersonEmail;
        }

        return value;
    };
    return (
        <View
            style={[
                styles.view,
                customWidth
                    ? {
                          minWidth: customWidth,
                          maxWidth: customWidth,
                          width: customWidth,
                      }
                    : {},
                stylesCustom ? stylesCustom : {},
            ]}
        >
            <Pressable
                style={[
                    styles.inputContainer,
                    prefix !== undefined && styles.inputContainerWithPrefix,
                ]}
                onPress={() => {
                    if (
                        inputValue === "" &&
                        isRequired &&
                        keyboardType === "email-address"
                    ) {
                        onChangeAndValidation(" ");
                        setIsError(true);
                    }
                }}
            >
                {prefix && (
                    <PrefixSelect
                        prefix={prefixValue!.title}
                        error={isError}
                        onReturnPrefix={onChangePrefix}
                    />
                )}
                <TextInput
                    accessibilityLabel={`insert ${translate(placeholder)} ${
                        isRequired ? "is required" : ""
                    }`}
                    style={[
                        styles.input,
                        prefix !== undefined && styles.inputPhoneNumber,
                        isError && styles.errorInput,
                    ]}
                    onChangeText={onChangeAndValidation}
                    placeholder={`${translate(placeholder)} ${
                        isRequired ? "*" : ""
                    }`}
                    value={calculateValue(inputValue)}
                    placeholderTextColor={styles.placeholder.color}
                    keyboardType={keyboardType! as KeyboardTypeOptions}
                />
            </Pressable>

            {/* ___________Se si vuole aggiungere l'errore sotto l'input di riferimento
      {error !== '' && isError && <Text style={styles.textError}>{error}</Text>} */}
        </View>
    );
};

export default InputElement;
