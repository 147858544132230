export const Identifiers = {
    /// -- Setup Store -- ///
    SETUP_STORE_LOCALE: "Locale",
    SETUP_STORE_ID_STORE: "Shop",
    SETUP_STORE_NAME_STORE: "ShopName",

    /// -- Authenticate -- ///
    FIELD_USER_GENDER: "Title",
    FIELD_USER_TITLE: "Title",
    FIELD_USER_FIRST_NAME: "FirstName",
    FIELD_USER_LAST_NAME: "LastName",
    FIELD_USER_JAPAN_FIRST_NAME: "JapanFirstName",
    FIELD_USER_JAPAN_LAST_NAME: "JapanLastName",
    FIELD_USER_COUNTRY_REGION: "CountryRegion",
    FIELD_USER_PERSON_EMAIL: "PersonEmail",
    FIELD_USER_PERSON_SEND_EMAIL: "SendEmail",
    FIELD_USER_MARKETING_AUTHORIZATION: "MarketingAuthorization",
    FIELD_USER_SHOP: "Shop",
    FIELD_USER_SHOP_NAME: "ShopName",
    FIELD_USER_SOURCE: "Source",
    FIELD_USER_DATA_ACQUISITION_TYPE: "DataAcquisitionType",
    FIELD_USER_LOCALE: "Locale",
    FIELD_USER_FIRST_NAME_PRONUNCIATION: "FirstNamePronunciation",
    FIELD_USER_LAST_NAME_PRONUNCIATION: "LastNamePronunciation",
    FIELD_USER_PREFIX: "Prefix",
    FIELD_USER_PERSON_MOBILE_PHONE: "PersonMobilePhone",
    FIELD_USER_INTEREST: "Interest",
    FIELD_USER_PROFILE_AUTHORIZATION: "ProfileAuthorization",
    FIELD_USER_PRIVACY_AUTHORIZATION: "PrivacyAuthorization",

    /// -- STEP -- ///

    /// -- Loading -- ///
    LOADING_AFTER_ACTION: "LoaderAfterAction",

    /// -- Mood Selector -- ///
    MOOD_SELECTOR: "MoodSelector",
    /// -- Charm Selector -- ///
    CHARM_SELECTOR: "CharmSelector",
    /// -- Mail Template -- ///
    MAIL_TEMPLATE: "MailTemplate",
    /// -- Video GenerationLoading -- ///
    VIDEO_GENERATION_LOADING: "VideoGenerationLoading",
};
