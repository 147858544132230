import axios from "axios";
import { useJourneyStore } from "../store/JourneyStore";
import { getIsMadrid } from "../utils/utils";
import { SocketManager } from "../utils/SocketManager";

export interface VideoGenerationData {
	type?: string;
	id?: string;
}
const baseUrlQuality = "nuovo-endpoint-generazione-video";

const defaultLink =
	"https://bulgari-digital-craft.s3.eu-central-1.amazonaws.com/magnificentmoments/01_Cuore_Infinito_Scatola.mp4";
const defaultCubeLink =
	"https://bulgari-digital-craft.s3.eu-central-1.amazonaws.com/holidayseasonexperience2023/b-giftbox-dadi-serpenti-20231115023804-542315.mp4"; //vecchio

// ========= Authentications =========
const instance = axios.create({
	baseURL: baseUrlQuality,
	headers: {
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin": "*",
	},
});

export const sendGeneratedVideo = () => {
	const _url = useJourneyStore.getState().urlVideoGenerated;
	if (!!_url) {
		SocketManager.getInstance().emitMessage(_url);
	} else {
		SocketManager.getInstance().emitMessage(
			getIsMadrid() ? defaultCubeLink : defaultLink,
		);
	}
};

export const requestGeneratedVideo = async (tags?: { title: string }[]) => {
	try {
		const data: any = await generateVideo(getIsMadrid(), tags);
		/* console.log("Body: "); */
		useJourneyStore.getState().setUrlVideoGenerated(
			//"https://bulgari-digital-craft.s3.eu-central-1.amazonaws.com/bulgari_videos/test_video_AI.mp4"
			!!data && !!data.link ? data.link : defaultLink,
		);
		if (!!data && !!data.link) {
			//SocketManager.getInstance().emitMessage(data.link)
			await consumeGenerateVideo(data._id);
		} else {
			//SocketManager.getInstance().emitMessage(getIsMadrid() ? defaultCubeLink : defaultLink)
		}
	} catch (error) {
		console.log(error);
	}
};

export const generateVideo = async (
	cube: boolean,
	tags?: { title: string }[],
) => {
	return new Promise(async (resolve, reject) => {
		const cubeResolution = "640x640";
		const screenResolution = "536x960";
		const resolution = cube ? cubeResolution : screenResolution;
		const _tags = tags ?? useJourneyStore.getState().selectedCharms;
		const charms = _tags.map((it) => it.title.split(" ")[1]).join("&tags=");
		const mood = useJourneyStore.getState().mood ?? "serpenti";
		const tagsToSend = `${charms}&tags=${mood}`;
		const url = `https://bulgari-christmas-backend-61feacc5c98e.herokuapp.com/medias/random?tags=${tagsToSend}&resolution=${resolution}`;
		try {
			const response = await instance.get(url, {});
			resolve(response.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const consumeGenerateVideo = async (videoId: string) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await instance.put(
				`https://bulgari-christmas-backend-61feacc5c98e.herokuapp.com/medias/${videoId}/used`,
			);
			resolve(response.data);
		} catch (error) {
			reject(error);
		}
	});
};
