import { Dimensions, StyleProp, View, ViewStyle } from 'react-native'
import styles from './LottieComponent.styles'
import LottieView, { AnimationObject, LottieViewProps } from 'lottie-react-native'
import { useEffect, useState } from 'react'

interface ILottieComponentProps extends Partial<LottieViewProps> {
  lottie: AnimationObject
  loop?: boolean
  styleContainer?: StyleProp<ViewStyle>
}

export default function LottieComponent({ lottie, loop, styleContainer, ...rest }: ILottieComponentProps) {
  const getWebStyle = () => {
    const { width, height } = Dimensions.get('window')
    const ratioImage = 1920 / 1080
    const ratioWindow = width / height
    // return the style for web to make lottie to maintain aspect ratio
    if (ratioWindow > ratioImage) {
      return {
        width: '100%',
        height: 'auto',
        aspectRatio: ratioImage,
      }
    } else {
      return { height: '100vh', aspectRatio: ratioImage }
    }
  }

  const [webStyle, setWebStyle] = useState(getWebStyle())

  window.addEventListener('resize', () => {
    setWebStyle(getWebStyle())
  })

  return (
    <View style={[styles.container, styleContainer]}>
      <LottieView autoPlay {...rest} source={lottie} loop={loop ?? false} webStyle={webStyle} />
    </View>
  )
}
