import React, { useEffect, useState } from "react";
import { Dimensions, Pressable, View } from "react-native";
import { styles } from "./ReloadModal.styles";
import { MotiView } from "moti";
import SvgComponent from "../Svg/SvgComponent";
import ReloadCard from "./ReloadCard";
import { useStepManagerStore } from "../../store/ExperienceStore";
import { reloadModal } from "../../configuration/steps/reloadModal";
import { useJourneyStore } from "../../store/JourneyStore";
import { useReloadStore } from "../../store/ReloadStore";
import { TFalseModal } from "../../data/dataInterfaces";
import SoundPlayer from "../../utils/SoundManager";

interface Props {
  longPress?: boolean;
}

export const ReloadModal = ({ longPress = false }: Props) => {
  const [windowWidth] = useState(Dimensions.get("window").width);
  /* -------------- CONFIG -------------- */
  const configuration = reloadModal;
  const config = configuration.operations?.config;

  /* -------------- ZUSTAND -------------- */
  const {
    userConfirm,
    selectedLanguage,
    newLanguage,
    clearUserData,
    onChangeFalseModal,
    falseModalHistory,
  } = useJourneyStore((state) => {
    return {
      userConfirm: state.userConfirm,
      selectedLanguage: state.selectedLanguage,
      newLanguage: state.newLanguage,
      clearUserData: state.clearUserData,
      onChangeFalseModal: state.onChangeFalseModal,
      falseModalHistory: state.falseModalHistory,
    };
  });

  const currentStepIndex = useStepManagerStore((state) => {
    return { currentStepIndex: state.currentStepIndex };
  });

  /* -------------- METHODS -------------- */
  const reloadExperience = () => {
    SoundPlayer.playSound("click");
    if (newLanguage) {
      const toggleReloadExperience =
        useReloadStore.getState().toggleReloadExperience;
      toggleReloadExperience(newLanguage);
      config.action();

      return;
    }

    clearUserData();

    useStepManagerStore
      .getState()
      .restart(longPress, newLanguage ? newLanguage : selectedLanguage);
  };

  const closeModalWithoutInternet = () => {
    SoundPlayer.playSound("click");
    useJourneyStore.getState().onChangeFalseModal(null);
    useJourneyStore.getState().setNewLanguage(null);
    useJourneyStore.getState().setUserConfirm("", null);
  };

  const handleCloseModal = () => {
    SoundPlayer.playSound("click");
    //  console.log(userConfirm)
    if (userConfirm?.error === "check-email") {
      onChangeFalseModal(
        falseModalHistory[
          falseModalHistory.length === 1
            ? falseModalHistory[0]
            : falseModalHistory.length - 2
        ] as TFalseModal
      );
      useJourneyStore.setState({ userConfirm: null });
      return;
    } else if (userConfirm?.error === "error-mail") {
      useJourneyStore.setState({ userConfirm: null });
      closeModalWithoutInternet();
    } else if (userConfirm?.title && currentStepIndex.currentStepIndex !== 7) {
      closeModalWithoutInternet();
      useJourneyStore.setState({ userConfirm: null });
    }
    config.action();
  };

  return (
    <MotiView
      from={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ type: "timing", duration: 800 }}
      style={styles.absoluteContainer}
    >
      <Pressable style={styles.overlay} onPress={handleCloseModal} />
      <Pressable
        style={[styles.content, windowWidth < 600 ? styles.contentMobile : {}]}
      >
        {/* --------------------------- Close icon */}

        <Pressable
          onPress={handleCloseModal}
          style={[
            styles.closeIcon,
            windowWidth < 600 && styles.closeIconMobile,
          ]}
          accessibilityLabel="close"
        >
          <SvgComponent type={config.icon} />
        </Pressable>

        <View style={styles.container}>
          <ReloadCard
            card={config.cards[0]}
            reloadExperience={reloadExperience}
            closeWithoutInternet={closeModalWithoutInternet}
            handleCloseModal={handleCloseModal}
            userConfirm={userConfirm}
            longPress={longPress}
          />
        </View>
      </Pressable>
    </MotiView>
  );
};
