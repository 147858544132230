import IStep, { OperationType } from "../../data/dataInterfaces";
import { useJourneyStore } from "../../store/JourneyStore";
import { Identifiers } from "../../utils/Indentifiers";

export const moodSelectorData: IStep = {
  title: "",
  id: Identifiers.MOOD_SELECTOR,
  operations: {
    type: OperationType.mood_selection,
    config: {
      step: 1,
      title: "$wonderSelectionPage.title",
      joy: {
        title: "$wonderSelectionPage.wonders.joy.title",
        description: "$wonderSelectionPage.wonders.joy.description",
      },
      love: {
        title: "$wonderSelectionPage.wonders.love.title",
        description: "$wonderSelectionPage.wonders.love.description",
      },
      care: {
        title: `$wonderSelectionPage.wonders.care.title`,
        description: "$wonderSelectionPage.wonders.care.description",
      },
      action: {
        id: "mood_selector",
        title: "$wonderSelectionPage.confirm",
        btnType: "filled",
        size: "default",
        action: () => {
          useJourneyStore.getState().setDoExitAnimation("mood_selector");
        },
      },
    },
  },
};
