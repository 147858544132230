import { View, StyleSheet, ScrollView } from "react-native";
import { Checkbox } from "../../Checkbox/Checkbox";
import InputElement from "../inputElement/InputElement";
import { getRandomUid } from "../../../utils/utils";
import BvlgariText from "../../TextElement/BvlgariText";
import SelectElement from "../../Select/SelectElement";

interface Props {
    form: any;
    onChange: (id: string, value: any) => void;
    isRegister: boolean;
}

export const FormGenerator = ({ form, onChange, isRegister }: Props) => {
    const renderItem = (item: any) => {
        switch (item.type) {
            case "input":
                return (
                    <InputElement
                        key={getRandomUid()}
                        {...item}
                        onChange={onChange}
                        isRequired={item.isRequired}
                    />
                );
            case "checkbox":
                return (
                    <Checkbox
                        key={getRandomUid()}
                        {...item}
                        onChange={onChange}
                    />
                );

            case "text":
                return (
                    <BvlgariText
                        key={getRandomUid()}
                        {...item}
                        onChange={onChange}
                    />
                );
            case "select":
                return (
                    <SelectElement
                        {...item}
                        key={getRandomUid()}
                        onChange={onChange}
                    />
                );
            case "layout":
                return (
                    <View key={getRandomUid()} style={styles.rowContainer}>
                        {item.children.map((child: any) => {
                            return renderItem(child);
                        })}
                    </View>
                );
        }
        return null;
    };

    const renderForm = (isRegister: boolean) => {
        if (!isRegister)
            return (
                <>
                    {form?.map((item: any) => {
                        return renderItem(item);
                    })}
                </>
            );

        return (
            <View key={getRandomUid()} style={styles.rowContainer}>
                {form?.map((item: any) => {
                    return renderItem(item);
                })}
            </View>
        );
    };

    return renderForm(isRegister);
};

const styles = StyleSheet.create({
    rowContainer: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        maxWidth: "100%",
        flexWrap: "wrap",
        columnGap: 20,
        rowGap: 20,
        paddingLeft: 0,
        paddingRight: 0,
    },
});
