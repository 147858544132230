import IStep, { OperationType } from "../../data/dataInterfaces";
import { useStepManagerStore } from "../../store/ExperienceStore";
import { useJourneyStore } from "../../store/JourneyStore";
import { Identifiers } from "../../utils/Indentifiers";
import { isIosApp, shareUrl } from "../../utils/utils";
import { ImageDataset } from "../ImageDataset";
import { countrySelectDataset } from "../selectsDataset";

export const finalVideoData: IStep = {
	id: "step-final-video",
	operations: {
		type: OperationType.final_video,
		config: {
			title: "$finalVideoPage.title",
			image: ImageDataset.frameFinalVideo,
			imageQuad: ImageDataset.frameFinalVideoQuad,
		},
	},
	actions: [
		{
			id: "",
			title: "$finalVideoPage.buttonSendArtwork",
			// icon: ImageDataset.icon_magic,
			btnType: "sharedWhite",
			size: "smallBtn",
			action: () => {
				useJourneyStore.getState().setHideTitle(true);

				useJourneyStore.getState().onChangeFalseModal("send");
			},
		},
		{
			id: "",
			title: "$finalVideoPage.buttonDownloadArtwork",
			// icon: ImageDataset.icon_magic,
			btnType: "downloadBrown",
			size: "smallBtn",
			action: () => {
				useJourneyStore.getState().onChangeFalseModal("qr");
			},
		},
		{
			id: "",
			title: "$finalVideoPage.buttonInviteFriend",
			// icon: ImageDataset.icon_magic,
			btnType: "sharedWhite",
			size: "smallBtn",
			action: () => {
				useJourneyStore.getState().onChangeFalseModal("invite");
			},
		},
	],
	subOperation: {
		send: {
			config: {
				title: "$sendMultiEmailPage.title",
				firstInfo: {
					title: "$sendMultiEmailPage.from",
					input: [
						{
							type: "input",
							id: Identifiers.FIELD_USER_FIRST_NAME,
							label: "$loginPage.inputName.label",
							error: "errore nome",
							placeholder: "$loginPage.inputName.placeholder",
							defaultValue: "",
							keyboardType: "default",
							isRequired: true,
							isValid: true,
						},
						{
							type: "input",
							id: Identifiers.FIELD_USER_LAST_NAME,
							label: "$loginPage.inputSurname.label",
							error: "errore",
							placeholder: "$loginPage.inputSurname.placeholder",
							defaultValue: "",
							keyboardType: "default",
							isRequired: true,
							isValid: false,
						},
						{
							type: "input",
							id: Identifiers.FIELD_USER_PERSON_EMAIL,
							label: "$loginPage.inputEmail.label",
							error: "Invalid login or Password. please try again",
							placeholder: "$loginPage.inputEmail.placeholder",
							defaultValue: "",
							keyboardType: "email-address",
							isRequired: true,
							isValid: true,
						},
					],
					country: {
						type: "select",
						id: Identifiers.FIELD_USER_COUNTRY_REGION,
						select: {
							id: Identifiers.FIELD_USER_COUNTRY_REGION,
							title: "$loginPage.selectCountry.title",
							options: countrySelectDataset.sort((a, b) =>
								a.title > b.title ? 1 : -1,
							),
							hideSelector: false,
							isRequired: true,
							placeholder:
								"$sendMultiEmailPage.countryRegionPlaceholder",
						},
					},
					checkbox: {
						id: "sendToMySelf",
						text: "$finalVideoPage.sendMultiEmailCard.checkbox",
						isRequired: false,
					},
				},

				secondInfo: {
					title: "$sendMultiEmailPage.to",
					input: [
						{
							type: "input",
							id: Identifiers.FIELD_USER_PERSON_SEND_EMAIL,
							label: "$loginPage.inputEmail.label",
							error: "Invalid login or Password. please try again",
							placeholder:
								"$loginPage.inputEmailToSend.placeholder",
							defaultValue: "",
							keyboardType: "email-address",
							isRequired: true,
							isValid: true,
							customWidth: "100%",
						},
					],
				},

				privacyPolicyConditions: {
					id: Identifiers.FIELD_USER_PRIVACY_AUTHORIZATION,
					text: "$loginPage.privacyPolicy.text",
					isRequired: true,
				},

				lastCheckbox: {
					title: "$loginPage.privacyInformation.description",
					dataProcessing: {
						id: Identifiers.FIELD_USER_PROFILE_AUTHORIZATION,
						text: "$loginPage.privacyInformation.profileAuth",
						//text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
					},
					marketing: {
						id: Identifiers.FIELD_USER_MARKETING_AUTHORIZATION,
						text: "$loginPage.privacyInformation.marketingAuth",
						//text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
					},
				},
			},
			actions: [
				{
					id: "",
					title: "$send",
					btnType: "filled",
					size: "default",
					action: () => {
						useJourneyStore.getState().authenticate();
					},
				},
			],
		},
		download: {
			config: {
				title: "$sendMultiEmailPage.downloadTitle",
				qrTitle: "$sendMultiEmailPage.qrAvaillable",
				downloadTitle: "$sendMultiEmailPage.downloadAvaillable",
				firstInfo: {
					title: "Personal information",
					input: [
						{
							type: "input",
							id: Identifiers.FIELD_USER_FIRST_NAME,
							label: "$loginPage.inputName.label",
							error: "errore nome",
							placeholder: "$loginPage.inputName.placeholder",
							defaultValue: "",
							keyboardType: "default",
							isRequired: true,
							isValid: true,
						},
						{
							type: "input",
							id: Identifiers.FIELD_USER_LAST_NAME,
							label: "$loginPage.inputSurname.label",
							error: "errore",
							placeholder: "$loginPage.inputSurname.placeholder",
							defaultValue: "",
							keyboardType: "default",
							isRequired: true,
							isValid: false,
						},
						{
							type: "input",
							id: Identifiers.FIELD_USER_PERSON_EMAIL,
							label: "$loginPage.inputEmail.label",
							error: "Invalid login or Password. please try again",
							placeholder: "$loginPage.inputEmail.placeholder",
							defaultValue: "",
							keyboardType: "email-address",
							isRequired: true,
							isValid: true,
						},
					],
					country: {
						type: "select",
						id: Identifiers.FIELD_USER_COUNTRY_REGION,
						select: {
							id: Identifiers.FIELD_USER_COUNTRY_REGION,
							title: "$loginPage.selectCountry.title",
							options: countrySelectDataset.sort((a, b) =>
								a.title > b.title ? 1 : -1,
							),
							hideSelector: false,
							isRequired: true,
							placeholder:
								"$sendMultiEmailPage.countryRegionPlaceholder",
							customMaxWidth: "48%",
						},
					},
				},

				privacyPolicyConditions: {
					id: Identifiers.FIELD_USER_PRIVACY_AUTHORIZATION,
					text: "$loginPage.privacyPolicy.text",
					isRequired: true,
				},

				lastCheckbox: {
					title: "$loginPage.privacyInformation.description",
					dataProcessing: {
						id: Identifiers.FIELD_USER_PROFILE_AUTHORIZATION,
						text: "$loginPage.privacyInformation.profileAuth",
						//text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
					},
					marketing: {
						id: Identifiers.FIELD_USER_MARKETING_AUTHORIZATION,
						text: "$loginPage.privacyInformation.marketingAuth",
						//text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
					},
				},
			},
			actions: [
				{
					id: "",
					title: "$download",
					btnType: "filled",
					size: "default",
					action: () => {
						useJourneyStore.getState().authenticate();
					},
				},
			],
		},
		invite: {
			config: {
				title: "$sendMultiEmailPage.shareTitle",
				firstInfo: {
					title: "$sendMultiEmailPage.shareFrom",
					input: [
						{
							type: "input",
							id: Identifiers.FIELD_USER_FIRST_NAME,
							label: "$loginPage.inputName.label",
							error: "errore nome",
							placeholder: "$loginPage.inputName.placeholder",
							defaultValue: "",
							keyboardType: "default",
							isRequired: true,
							isValid: true,
						},
						{
							type: "input",
							id: Identifiers.FIELD_USER_LAST_NAME,
							label: "$loginPage.inputSurname.label",
							error: "errore",
							placeholder: "$loginPage.inputSurname.placeholder",
							defaultValue: "",
							keyboardType: "default",
							isRequired: true,
							isValid: false,
						},
						{
							type: "input",
							id: Identifiers.FIELD_USER_PERSON_EMAIL,
							label: "$loginPage.inputEmail.label",
							error: "Invalid login or Password. please try again",
							placeholder: "$loginPage.inputEmail.placeholder",
							defaultValue: "",
							keyboardType: "email-address",
							isRequired: true,
							isValid: true,
						},
					],
					country: {
						type: "select",
						id: Identifiers.FIELD_USER_COUNTRY_REGION,
						select: {
							id: Identifiers.FIELD_USER_COUNTRY_REGION,
							title: "$loginPage.selectCountry.title",
							options: countrySelectDataset.sort((a, b) =>
								a.title > b.title ? 1 : -1,
							),
							hideSelector: false,
							isRequired: true,
							placeholder:
								"$sendMultiEmailPage.countryRegionPlaceholder",
						},
					},
				},

				secondInfo: {
					title: "$sendMultiEmailPage.shareTo",
					input: [
						{
							type: "input",
							id: Identifiers.FIELD_USER_PERSON_SEND_EMAIL,
							label: "$loginPage.inputEmail.label",
							error: "Invalid login or Password. please try again",
							placeholder:
								"$loginPage.inputEmailToSend.placeholder",
							defaultValue: "",
							keyboardType: "email-address",
							isRequired: true,
							isValid: true,
							customWidth: "100%",
						},
					],
				},

				privacyPolicyConditions: {
					id: Identifiers.FIELD_USER_PRIVACY_AUTHORIZATION,
					text: "$loginPage.privacyPolicy.text",
					isRequired: true,
				},

				lastCheckbox: {
					title: "$loginPage.privacyInformation.description",
					dataProcessing: {
						id: Identifiers.FIELD_USER_PROFILE_AUTHORIZATION,
						text: "$loginPage.privacyInformation.profileAuth",
						//text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
					},
					marketing: {
						id: Identifiers.FIELD_USER_MARKETING_AUTHORIZATION,
						text: "$loginPage.privacyInformation.marketingAuth",
						//text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
					},
				},
			},
			actions: [
				{
					id: "",
					title: "$next",
					btnType: "filled",
					size: "default",
					action: () => {
						useJourneyStore.getState().authenticate();
					},
				},
			],
		},
	},
};
