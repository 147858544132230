import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
    container: {
        backgroundColor: "rgba(0, 0, 0, 0.35)",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 4,
        cursor: "pointer",
        borderRadius: 10,
        alignSelf: "center",
    },

    mobileSizes: {
        width: 40,
        height: 30,
        paddingInline: 4,
        paddingVertical: 4,
    },

    originalSizes: {
        width: 77,
        height: 30,
        paddingInline: 12,
        paddingHorizontal: 12,
        paddingVertical: 4,
    },
});
