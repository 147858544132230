import shop from "./assets/shops.json";
import languages from "./assets/languages.json";
import countries from "./assets/countries.json";
import titles from "./assets/titles.json";
import prefix from "./assets/prefix.json";
import interests from "./assets/interests.json";
import shopsOnline from "./assets/shopsOnline.json";
/// ----------- -----------  ------------- ///
/// ----------- LANGUAGE SELECT ------------- ///
export const localeSelectDataset = languages;

/// ----------- -----------  ------------- ///
/// ----------- SHOP SELECT ------------- ///

export const shopSelectDataset = shop;

/// ----------- -----------  ------------- ///
/// ----------- COUNTRY SELECT ------------- ///

export const countrySelectDataset = countries;

/// ----------- -----------  ------------- ///
/// ----------- SHOP ONLINE CODE  ------------- ///

export const shopOnlineData = shopsOnline;

/// ----------- -----------  ------------- ///
/// ----------- TITLES SELECT ------------- ///

export const titlesDataset = titles;

/// ----------- -----------  ------------- ///
/// ----------- PREFIX SELECT ------------- ///

export const prefixDataset = prefix;

/// ----------- -----------  ------------- ///
/// ----------- INTERESTS SELECT ------------- ///

export const intereststDataset = interests;

export { shopsOnline };
/*
//utils @sal
const EL = [
]

export const transformedData = EL.map((item, index) => {
  const parts = item.split('_')
  console.log(item)
  return {
    id: parts[0].trim(),
    title: parts[1].trim(),
    //id: index,
    // title: item,
  }
})
*/
