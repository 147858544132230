import { View, Pressable, Dimensions } from "react-native";
import React, { useEffect, useState } from "react";
import { AnimatePresence, Image, MotiView } from "moti";
import { useStepManagerStore } from "../../store/ExperienceStore";
import BvlgariText from "../TextElement/BvlgariText";
import StepBar from "../StepBar/StepBar";
import { IStepAction } from "../../data/dataInterfaces";
import SvgComponent from "../Svg/SvgComponent";
import { styles } from "./MailTemplate.styles";
import { PanGestureHandler, State } from "react-native-gesture-handler";
import CarouselPoint from "../CarouselPoints/CarouselPoint";
import { ImageDataset } from "../../configuration/ImageDataset";
import { ActionButton } from "../ActionButton/ActionButton";
import { ITemplateMessage } from "../../configuration/steps/mailTemplate";
import { useJourneyStore } from "../../store/JourneyStore";
import SoundPlayer from "../../utils/SoundManager";

export interface IMailTemplateConfig {
	step: number;
	title: string;
	message: ITemplateMessage[];
	action: IStepAction;
}
interface MailTemplateProps {
	config: IMailTemplateConfig;
}
export default function MailTemplate({ config }: MailTemplateProps) {
	const [currentStep, setCurrentStep] = useState<number>(
		useStepManagerStore.getState().currentStepIndex,
	);
	const [selectedMessage, setSelectedMessage] = useState<
		ITemplateMessage | undefined
	>(useJourneyStore.getState().selectedMessageTemplate ?? config.message[0]);
	const [actionExecuted, setActionExecuted] = useState<boolean>(false);
	const [windowWidth, setWindowWidth] = useState<number>(
		Dimensions.get("window").width,
	);
	const [windowHeight, setWindowHeight] = useState<number>(
		Dimensions.get("window").height,
	);

	/* --------------- SUZTAND --------------- */
	const isIos = useJourneyStore.getState().isIos;

	/* --------------- UNSUB SUZTAND --------------- */
	const unsubCurrentStep = useStepManagerStore.subscribe((state) => {
		state.currentStepIndex !== currentStep &&
			setCurrentStep(state.currentStepIndex);
	});

	const unsubSelectedMessage = useJourneyStore.subscribe((state) => {
		state.selectedMessageTemplate !== selectedMessage &&
			setSelectedMessage(state.selectedMessageTemplate);
	});

	/* --------------- METHODS  --------------- */

	const onAction = () => {
		setActionExecuted(true);

		setTimeout(() => {
			setActionExecuted(false);
		}, 500);
	};

	const onSwipe = (event: any) => {
		if (!actionExecuted && event.nativeEvent.state === State.ACTIVE) {
			const { translationX } = event.nativeEvent;
			if (translationX < 0) {
				// Swipe verso destra
				const index = config.message.indexOf(
					selectedMessage ?? config.message[0],
				);
				index === config.message.length - 1
					? useJourneyStore.setState({
							selectedMessageTemplate: config.message[0],
					  })
					: useJourneyStore.setState({
							selectedMessageTemplate: config.message[index + 1],
					  });
				onAction();
				SoundPlayer.playSound("swipe");
			} else if (translationX > 0) {
				// Swipe verso sinistra
				const index = config.message.indexOf(
					selectedMessage ?? config.message[0],
				);
				index === 0
					? useJourneyStore.setState({
							selectedMessageTemplate:
								config.message[config.message.length - 1],
					  })
					: useJourneyStore.setState({
							selectedMessageTemplate: config.message[index - 1],
					  });
				onAction();
				SoundPlayer.playSound("swipe");
			}
		}
	};

	const onRightPress = () => {
		SoundPlayer.playSound("click");
		const index = config.message.indexOf(
			selectedMessage ?? config.message[0],
		);
		index === config.message.length - 1
			? useJourneyStore.setState({
					selectedMessageTemplate: config.message[0],
			  })
			: useJourneyStore.setState({
					selectedMessageTemplate: config.message[index + 1],
			  });
	};
	const onLeftPress = () => {
		SoundPlayer.playSound("click");
		const index = config.message.indexOf(
			selectedMessage ?? config.message[0],
		);
		index === 0
			? useJourneyStore.setState({
					selectedMessageTemplate:
						config.message[config.message.length - 1],
			  })
			: useJourneyStore.setState({
					selectedMessageTemplate: config.message[index - 1],
			  });
	};

	useEffect(() => {
		useJourneyStore.setState({ selectedMessageTemplate: selectedMessage });
		return () => {
			unsubCurrentStep();
			unsubSelectedMessage();
		};
	}, []);

	return (
		<AnimatePresence exitBeforeEnter>
			<MotiView
				from={{ opacity: 0 }}
				key={`${currentStep}_messageTemplate`}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				exitTransition={{ type: "timing", duration: 1500 }}
				transition={{ type: "timing", duration: 1500 }}
				style={[
					styles.container,
					windowWidth < 390
						? styles.containerSmallMobile
						: windowWidth < 520
						? styles.containerMobile
						: isIos && windowWidth > 520 && windowWidth < 1280
						? styles.containerTabletWeb
						: windowWidth > 1280
						? styles.containerDesktop
						: {},
				]}
			>
				{/* ------------------ HEADER ------------------ */}

				{/* --------- STEP INFO --------- */}
				<View
					style={
						windowWidth > 1280
							? {
									position: "absolute",
									top: 0,
							  }
							: {}
					}
				>
					<BvlgariText
						text={`$step${config.step}`}
						textType='xlLight'
						style={{
							color: "#473213",
							fontSize: 20,
							fontWeight: "300",
							marginBottom: 4,
							letterSpacing: 3,
						}}
					/>
					<StepBar totalSteps={3} activeStep={config.step} />
				</View>

				{/* --------- TITLE --------- */}
				<BvlgariText
					text={config.title}
					textType='xlLight'
					style={{
						fontSize: windowWidth < 525 ? 30 : 36,
						marginBottom:
							windowWidth < 525
								? 130
								: windowWidth > 520 && windowWidth < 1280
								? "auto"
								: windowWidth > 1280
								? 0
								: 100,
						marginTop:
							windowWidth < 390
								? 0
								: windowWidth < 520
								? 0
								: !isIos &&
								  windowWidth > 520 &&
								  windowWidth < 1280
								? -6
								: windowWidth > 1280
								? 0
								: 18,
						color: "#473213",
						...(windowWidth > 1280
							? {
									position: "absolute",
									top: 50,
							  }
							: {}),
					}}
				/>

				{/* ------------------ CAROUSEL ------------------ */}
				<PanGestureHandler onGestureEvent={onSwipe}>
					{/* ------------ LEFT ARROW ------------*/}
					<View style={[styles.carousel]}>
						<Pressable
							accessibilityLabel='Go to Previous Message'
							style={[
								styles.arrow,
								{ alignItems: "flex-start" },
								windowWidth < 390
									? styles.arrowSmallMobile
									: windowWidth < 520
									? styles.arrowMobile
									: {},
							]}
							onPress={onLeftPress}
						>
							<SvgComponent type='left-arrow' />
						</Pressable>

						{/* ------------ Image ------------*/}
						<View style={styles.boxContainer}>
							<Image
								source={ImageDataset.messageCard}
								style={styles.boxImage}
							/>
							<View
								style={{
									position: "absolute",
									justifyContent: "center",
									alignItems: "center",
									width: "100%",
									height: "100%",
								}}
							>
								<AnimatePresence exitBeforeEnter>
									<MotiView
										from={{ opacity: 0 }}
										key={`${selectedMessage?.id}_messageTemplate`}
										animate={{ opacity: 1 }}
										exit={{ opacity: 0 }}
										exitTransition={{
											type: "timing",
											duration: 800,
										}}
										transition={{
											type: "timing",
											duration: 800,
										}}
										style={styles.textContainer}
									>
										<View style={styles.textAbsolute}>
											<BvlgariText
												text={
													selectedMessage?.text ?? ""
												}
												textType='xlLight'
												style={{
													color: "rgba(175, 132, 69, 1)",
													fontSize: 22,
													textAlign: "center",
													fontStyle: "italic",
												}}
											/>
										</View>
									</MotiView>
								</AnimatePresence>
							</View>
						</View>

						{/* ------------ RIGHT ARROW ------------*/}
						<Pressable
							accessibilityLabel='Go to Next Message'
							style={[
								styles.arrow,
								{ alignItems: "flex-end" },
								windowWidth < 390
									? styles.arrowSmallMobile
									: windowWidth < 520
									? styles.arrowMobile
									: {},
							]}
							onPress={onRightPress}
						>
							<SvgComponent type='right-arrow' />
						</Pressable>
					</View>
				</PanGestureHandler>

				{/* ------------------ POINTS ------------------ */}
				<View style={{ marginBottom: 34 }}>
					<CarouselPoint
						totalSteps={config.message.length}
						indefOfActiveStep={config.message.indexOf(
							selectedMessage ?? config.message[0],
						)}
					/>
				</View>

				{/* ------------------ ACTION ------------------ */}
				<View
					style={[
						styles.actionContainer,
						windowWidth > 520 && windowWidth < 1280
							? { marginTop: "auto" }
							: windowWidth > 1280
							? { position: "absolute", bottom: 0 }
							: {},
					]}
				>
					<View style={styles.actionButton}>
						<ActionButton action={config.action} />
					</View>
				</View>
			</MotiView>
		</AnimatePresence>
	);
}
