import * as React from "react";
import Svg, { Circle, Rect, Path, G, Defs } from "react-native-svg";

export type TypeSvg =
    | "arrow"
    | "reload"
    | "go-back"
    | "info"
    | "flag-ita"
    | "flag-uk"
    | "flag-chi-sem"
    | "flag-chi-trad"
    | "flag-jap"
    | "flag-kor"
    | "flag-arab"
    | "flag-esp"
    | "close-modal"
    | "close-icon"
    | "x-icon"
    | "plus-icon"
    | "left-arrow"
    | "right-arrow";

interface Props {
    type: TypeSvg;
}

export default function SvgComponent({ type }: Props) {
    switch (type) {
        case "arrow":
            return (
                <Svg width='18' height='18' viewBox='0 0 18 18' fill='none'>
                    <Path
                        d='M3 6.99996L9 11L15 6.99996'
                        stroke='#473213'
                        stroke-width='1.2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                </Svg>
            );

        case "reload":
            return (
                <Svg width='22' height='22' viewBox='0 0 22 22' fill='none'>
                    <Path
                        d='M8.35107 4.65668C9.14857 4.41835 10.0286 4.26251 11.0002 4.26251C15.3911 4.26251 18.9477 7.81918 18.9477 12.21C18.9477 16.6008 15.3911 20.1575 11.0002 20.1575C6.6094 20.1575 3.05273 16.6008 3.05273 12.21C3.05273 10.5783 3.54773 9.05668 4.39107 7.79168'
                        stroke='#FFFAEC'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                    <Path
                        d='M7.21484 4.87665L9.86401 1.83331'
                        stroke='#FFFAEC'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                    <Path
                        d='M7.21484 4.87665L10.304 7.13165'
                        stroke='#FFFAEC'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                </Svg>
            );

        case "go-back":
            return (
                <Svg width='22' height='22' viewBox='0 0 22 22' fill='none'>
                    <Path
                        d='M19 10H6.83L12.42 4.41L11 3L3 11L11 19L12.41 17.59L6.83 12H19V10Z'
                        fill='#FFFAEC'
                    />
                </Svg>
            );

        case "info":
            return (
                <Svg width='22' height='23' viewBox='0 0 22 23' fill='none'>
                    <G clip-path='url(#clip0_3005_4666)'>
                        <Path
                            d='M11 19.75C15.5563 19.75 19.25 16.0563 19.25 11.5C19.25 6.94365 15.5563 3.25 11 3.25C6.44365 3.25 2.75 6.94365 2.75 11.5C2.75 16.0563 6.44365 19.75 11 19.75Z'
                            stroke='white'
                            stroke-width='1.5'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                        />
                        <Path
                            d='M11 7.8335H11.0092'
                            stroke='white'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                        />
                        <Path
                            d='M10.083 11.5H10.9997V15.1667H11.9163'
                            stroke='white'
                            stroke-width='1.5'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                        />
                    </G>
                    <Defs>
                        <clipPath id='clip0_3005_4666'>
                            <Rect
                                width='22'
                                height='22'
                                fill='white'
                                transform='translate(0 0.5)'
                            />
                        </clipPath>
                    </Defs>
                </Svg>
            );

        case "flag-ita":
            return (
                <Svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
                    <G clip-path='url(#clip0_2747_22540)'>
                        <Path
                            d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z'
                            fill='#F0F0F0'
                        />
                        <Path
                            d='M20.0003 10C20.0003 5.70037 17.2865 2.03494 13.4785 0.622009V19.3781C17.2865 17.9651 20.0003 14.2997 20.0003 10V10Z'
                            fill='#D80027'
                        />
                        <Path
                            d='M0 10C0 14.2997 2.71375 17.9651 6.52176 19.378V0.622009C2.71375 2.03494 0 5.70037 0 10Z'
                            fill='#6DA544'
                        />
                    </G>
                    <Defs>
                        <clipPath id='clip0_2747_22540'>
                            <Rect width='20' height='20' fill='white' />
                        </clipPath>
                    </Defs>
                </Svg>
            );
        case "flag-uk":
            return (
                <Svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
                    <G clip-path='url(#clip0_3955_40488)'>
                        <Path
                            d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z'
                            fill='#F0F0F0'
                        />
                        <Path
                            d='M2.0684 3.9118C1.28289 4.9338 0.690586 6.11173 0.345703 7.39137H5.54797L2.0684 3.9118Z'
                            fill='#0052B4'
                        />
                        <Path
                            d='M19.6573 7.39134C19.3124 6.11173 18.72 4.9338 17.9346 3.9118L14.4551 7.39134H19.6573Z'
                            fill='#0052B4'
                        />
                        <Path
                            d='M0.345703 12.6088C0.690625 13.8884 1.28293 15.0663 2.0684 16.0883L5.54785 12.6088H0.345703Z'
                            fill='#0052B4'
                        />
                        <Path
                            d='M16.0889 2.06722C15.0669 1.28171 13.889 0.689404 12.6094 0.344482V5.54671L16.0889 2.06722Z'
                            fill='#0052B4'
                        />
                        <Path
                            d='M3.91211 17.9328C4.9341 18.7183 6.11203 19.3106 7.39164 19.6555V14.4533L3.91211 17.9328Z'
                            fill='#0052B4'
                        />
                        <Path
                            d='M7.3916 0.344482C6.11199 0.689404 4.93406 1.28171 3.91211 2.06718L7.3916 5.54667V0.344482Z'
                            fill='#0052B4'
                        />
                        <Path
                            d='M12.6094 19.6555C13.889 19.3106 15.0669 18.7183 16.0889 17.9328L12.6094 14.4533V19.6555Z'
                            fill='#0052B4'
                        />
                        <Path
                            d='M14.4551 12.6088L17.9346 16.0883C18.72 15.0663 19.3124 13.8884 19.6573 12.6088H14.4551Z'
                            fill='#0052B4'
                        />
                        <Path
                            d='M19.9154 8.69566H11.3044H11.3044V0.0846484C10.8774 0.0290625 10.4421 0 10 0C9.55785 0 9.12262 0.0290625 8.69566 0.0846484V8.69559V8.69563H0.0846484C0.0290625 9.12262 0 9.55793 0 10C0 10.4421 0.0290625 10.8774 0.0846484 11.3043H8.69559H8.69563V19.9154C9.12262 19.9709 9.55785 20 10 20C10.4421 20 10.8774 19.971 11.3043 19.9154V11.3044V11.3044H19.9154C19.9709 10.8774 20 10.4421 20 10C20 9.55793 19.9709 9.12262 19.9154 8.69566V8.69566Z'
                            fill='#D80027'
                        />
                        <Path
                            d='M12.6094 12.6087L17.0717 17.0711C17.277 16.8659 17.4727 16.6514 17.6595 16.4291L13.8391 12.6087H12.6094V12.6087Z'
                            fill='#D80027'
                        />
                        <Path
                            d='M7.39207 12.6088H7.39199L2.92969 17.0711C3.13484 17.2763 3.34934 17.4721 3.57168 17.6589L7.39207 13.8384V12.6088Z'
                            fill='#D80027'
                        />
                        <Path
                            d='M7.39195 7.39135V7.39128L2.92961 2.92889C2.72438 3.13405 2.52859 3.34854 2.3418 3.57089L6.16223 7.39132H7.39195V7.39135Z'
                            fill='#D80027'
                        />
                        <Path
                            d='M12.6094 7.39138L17.0718 2.92896C16.8666 2.72373 16.6521 2.52794 16.4298 2.34119L12.6094 6.16162V7.39138Z'
                            fill='#D80027'
                        />
                    </G>
                    <Defs>
                        <clipPath id='clip0_3955_40488'>
                            <Rect width='20' height='20' fill='white' />
                        </clipPath>
                    </Defs>
                </Svg>
            );
        case "flag-chi-sem":
            return (
                <Svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
                    <G clip-path='url(#clip0_2747_22552)'>
                        <Path
                            d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z'
                            fill='#D80027'
                        />
                        <Path
                            d='M5.47266 6.08594L6.33594 8.74219H9.12891L6.87109 10.3867L7.73438 13.043L5.47266 11.4023L3.21094 13.043L4.07813 10.3867L1.81641 8.74219H4.60938L5.47266 6.08594Z'
                            fill='#FFDA44'
                        />
                        <Path
                            d='M11.8555 15.4883L11.1953 14.6758L10.2188 15.0547L10.7852 14.1758L10.125 13.3594L11.1367 13.6289L11.707 12.75L11.7617 13.7969L12.7773 14.0664L11.7969 14.4414L11.8555 15.4883Z'
                            fill='#FFDA44'
                        />
                        <Path
                            d='M13.168 13.1055L13.4805 12.1055L12.625 11.5L13.6719 11.4844L13.9805 10.4844L14.3203 11.4766L15.3672 11.4648L14.5273 12.0898L14.8633 13.082L14.0078 12.4766L13.168 13.1055Z'
                            fill='#FFDA44'
                        />
                        <Path
                            d='M14.9375 7.33984L14.4766 8.28125L15.2266 9.01172L14.1914 8.86328L13.7305 9.80078L13.5508 8.76953L12.5117 8.62109L13.4414 8.13281L13.2617 7.09766L14.0117 7.82812L14.9375 7.33984Z'
                            fill='#FFDA44'
                        />
                        <Path
                            d='M11.8828 4.48828L11.8047 5.53125L12.7773 5.92578L11.7578 6.17578L11.6836 7.22266L11.1328 6.33203L10.1133 6.58203L10.7891 5.78125L10.2344 4.89453L11.207 5.28906L11.8828 4.48828Z'
                            fill='#FFDA44'
                        />
                    </G>
                    <Defs>
                        <clipPath id='clip0_2747_22552'>
                            <Rect width='20' height='20' fill='white' />
                        </clipPath>
                    </Defs>
                </Svg>
            );
        case "flag-chi-trad":
            return (
                <Svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
                    <G clip-path='url(#clip0_2747_22552)'>
                        <Path
                            d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z'
                            fill='#D80027'
                        />
                        <Path
                            d='M5.47266 6.08594L6.33594 8.74219H9.12891L6.87109 10.3867L7.73438 13.043L5.47266 11.4023L3.21094 13.043L4.07813 10.3867L1.81641 8.74219H4.60938L5.47266 6.08594Z'
                            fill='#FFDA44'
                        />
                        <Path
                            d='M11.8555 15.4883L11.1953 14.6758L10.2188 15.0547L10.7852 14.1758L10.125 13.3594L11.1367 13.6289L11.707 12.75L11.7617 13.7969L12.7773 14.0664L11.7969 14.4414L11.8555 15.4883Z'
                            fill='#FFDA44'
                        />
                        <Path
                            d='M13.168 13.1055L13.4805 12.1055L12.625 11.5L13.6719 11.4844L13.9805 10.4844L14.3203 11.4766L15.3672 11.4648L14.5273 12.0898L14.8633 13.082L14.0078 12.4766L13.168 13.1055Z'
                            fill='#FFDA44'
                        />
                        <Path
                            d='M14.9375 7.33984L14.4766 8.28125L15.2266 9.01172L14.1914 8.86328L13.7305 9.80078L13.5508 8.76953L12.5117 8.62109L13.4414 8.13281L13.2617 7.09766L14.0117 7.82812L14.9375 7.33984Z'
                            fill='#FFDA44'
                        />
                        <Path
                            d='M11.8828 4.48828L11.8047 5.53125L12.7773 5.92578L11.7578 6.17578L11.6836 7.22266L11.1328 6.33203L10.1133 6.58203L10.7891 5.78125L10.2344 4.89453L11.207 5.28906L11.8828 4.48828Z'
                            fill='#FFDA44'
                        />
                    </G>
                    <Defs>
                        <clipPath id='clip0_2747_22552'>
                            <Rect width='20' height='20' fill='white' />
                        </clipPath>
                    </Defs>
                </Svg>
            );
        case "flag-jap":
            return (
                <Svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
                    <G clip-path='url(#clip0_2747_22560)'>
                        <Path
                            d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z'
                            fill='#F0F0F0'
                        />
                        <Path
                            d='M10.0002 14.3478C12.4014 14.3478 14.348 12.4012 14.348 9.99997C14.348 7.59874 12.4014 5.65216 10.0002 5.65216C7.59893 5.65216 5.65234 7.59874 5.65234 9.99997C5.65234 12.4012 7.59893 14.3478 10.0002 14.3478Z'
                            fill='#D80027'
                        />
                    </G>
                    <Defs>
                        <clipPath id='clip0_2747_22560'>
                            <Rect width='20' height='20' fill='white' />
                        </clipPath>
                    </Defs>
                </Svg>
            );
        case "flag-kor":
            return (
                <Svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
                    <G clip-path='url(#clip0_2747_22563)'>
                        <Path
                            d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z'
                            fill='#F0F0F0'
                        />
                        <Path
                            d='M13.478 9.99997C13.478 10.8695 11.9207 13.0434 9.99973 13.0434C8.07875 13.0434 6.52148 10.8695 6.52148 9.99997C6.52148 8.07895 8.07875 6.52173 9.99973 6.52173C11.9207 6.52173 13.478 8.07895 13.478 9.99997Z'
                            fill='#D80027'
                        />
                        <Path
                            d='M13.478 10C13.478 11.921 11.9207 13.4782 9.99973 13.4782C8.07875 13.4782 6.52148 11.921 6.52148 10'
                            fill='#0052B4'
                        />
                        <Path
                            d='M13.6855 13.0745L14.6077 12.1524L15.2225 12.7671L14.3003 13.6893L13.6855 13.0745Z'
                            fill='black'
                        />
                        <Path
                            d='M12.1484 14.6115L13.0706 13.6893L13.6854 14.3041L12.7632 15.2263L12.1484 14.6115Z'
                            fill='black'
                        />
                        <Path
                            d='M15.5312 14.9188L16.4534 13.9966L17.0682 14.6114L16.146 15.5335L15.5312 14.9188Z'
                            fill='black'
                        />
                        <Path
                            d='M13.9941 16.4561L14.9163 15.5339L15.5311 16.1487L14.6089 17.0708L13.9941 16.4561Z'
                            fill='black'
                        />
                        <Path
                            d='M14.6094 13.9967L15.5315 13.0745L16.1463 13.6893L15.2242 14.6115L14.6094 13.9967Z'
                            fill='black'
                        />
                        <Path
                            d='M13.0723 15.5337L13.9944 14.6115L14.6092 15.2263L13.6871 16.1485L13.0723 15.5337Z'
                            fill='black'
                        />
                        <Path
                            d='M15.5352 6.92719L13.076 4.46807L13.6908 3.85328L16.1499 6.3124L15.5352 6.92719Z'
                            fill='black'
                        />
                        <Path
                            d='M13.0762 6.31262L12.154 5.39046L12.7688 4.7757L13.6909 5.69786L13.0762 6.31262Z'
                            fill='black'
                        />
                        <Path
                            d='M14.6133 7.84998L13.6911 6.92781L14.3059 6.31305L15.228 7.23522L14.6133 7.84998Z'
                            fill='black'
                        />
                        <Path
                            d='M14.9199 4.46796L13.9978 3.54579L14.6125 2.931L15.5347 3.85317L14.9199 4.46796Z'
                            fill='black'
                        />
                        <Path
                            d='M16.457 6.00549L15.5349 5.08333L16.1497 4.46854L17.0718 5.39071L16.457 6.00549Z'
                            fill='black'
                        />
                        <Path
                            d='M3.54492 13.995L6.00404 16.4541L5.38925 17.0689L2.93014 14.6098L3.54492 13.995Z'
                            fill='black'
                        />
                        <Path
                            d='M6.00391 14.6093L6.92607 15.5315L6.31131 16.1462L5.38915 15.2241L6.00391 14.6093Z'
                            fill='black'
                        />
                        <Path
                            d='M4.4668 13.0726L5.38896 13.9948L4.77418 14.6096L3.85201 13.6874L4.4668 13.0726Z'
                            fill='black'
                        />
                        <Path
                            d='M5.38867 12.1502L7.84779 14.6093L7.233 15.2241L4.77389 12.765L5.38867 12.1502Z'
                            fill='black'
                        />
                        <Path
                            d='M6.00391 3.54388L3.54479 6.003L2.93 5.38822L5.38912 2.9291L6.00391 3.54388Z'
                            fill='black'
                        />
                        <Path
                            d='M6.92578 4.46594L4.46666 6.92506L3.85188 6.31027L6.31099 3.85116L6.92578 4.46594Z'
                            fill='black'
                        />
                        <Path
                            d='M7.84961 5.38867L5.39049 7.84779L4.77573 7.23303L7.23485 4.77391L7.84961 5.38867Z'
                            fill='black'
                        />
                    </G>
                    <Defs>
                        <clipPath id='clip0_2747_22563'>
                            <Rect width='20' height='20' fill='white' />
                        </clipPath>
                    </Defs>
                </Svg>
            );
        case "flag-arab":
            return (
                <Svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
                    <G clip-path='url(#clip0_2747_22602)'>
                        <Path
                            d='M0.992266 5.65216C0.356563 6.96685 0 8.44173 0 9.99998C0 11.5582 0.356563 13.0331 0.992266 14.3478L10 15.2174L19.0077 14.3478C19.6434 13.0331 20 11.5582 20 9.99998C20 8.44173 19.6434 6.96685 19.0077 5.65216L10 4.78259L0.992266 5.65216Z'
                            fill='#F0F0F0'
                        />
                        <Path
                            d='M0.992188 14.3478C2.60961 17.6928 6.035 20 9.99957 20C13.9641 20 17.3895 17.6928 19.007 14.3478H0.992188Z'
                            fill='#496E2D'
                        />
                        <Path
                            d='M0.992188 5.65219H19.007C17.3895 2.30723 13.9642 0 9.99957 0C6.03496 0 2.60961 2.30723 0.992188 5.65219Z'
                            fill='black'
                        />
                        <Path
                            d='M2.92893 2.92889C-0.976309 6.83413 -0.976309 13.1658 2.92893 17.0711C4.54271 15.4573 6.09478 13.9052 10 9.99999L2.92893 2.92889Z'
                            fill='#D80027'
                        />
                        <Path
                            d='M12.6091 9.99996C12.6091 8.54301 13.633 7.32586 15.0004 7.02726C14.7902 6.98136 14.5721 6.95648 14.3482 6.95648C12.6673 6.95648 11.3047 8.31906 11.3047 9.99996C11.3047 11.6809 12.6673 13.0434 14.3482 13.0434C14.5721 13.0434 14.7902 13.0186 15.0004 12.9727C13.633 12.6741 12.6091 11.4569 12.6091 9.99996Z'
                            fill='#D80027'
                        />
                        <Path
                            d='M15.1274 8.04346L15.5592 9.37213H16.9561L15.8259 10.1931L16.2577 11.5217L15.1274 10.7006L13.9973 11.5217L14.4291 10.1931L13.2988 9.37213H14.6957L15.1274 8.04346Z'
                            fill='#D80027'
                        />
                    </G>
                    <Defs>
                        <clipPath id='clip0_2747_22602'>
                            <rect width='20' height='20' fill='white' />
                        </clipPath>
                    </Defs>
                </Svg>
            );
        case "flag-esp":
            return (
                <Svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
                    <G clip-path='url(#clip0_2747_22610)'>
                        <Path
                            d='M0 9.99997C0 11.2232 0.220039 12.3949 0.621992 13.4782L10 14.3478L19.378 13.4782C19.78 12.3949 20 11.2232 20 9.99997C20 8.77677 19.78 7.60505 19.378 6.52173L10 5.65216L0.621992 6.52173C0.220039 7.60505 0 8.77677 0 9.99997H0Z'
                            fill='#FFDA44'
                        />
                        <Path
                            d='M19.3771 6.52176C17.9642 2.71379 14.2987 0 9.9991 0C5.69945 0 2.03402 2.71379 0.621094 6.52176H19.3771Z'
                            fill='#D80027'
                        />
                        <Path
                            d='M0.621094 13.4783C2.03402 17.2862 5.69945 20 9.9991 20C14.2987 20 17.9642 17.2862 19.3771 13.4783H0.621094Z'
                            fill='#D80027'
                        />
                    </G>
                    <Defs>
                        <clipPath id='clip0_2747_22610'>
                            <Rect width='20' height='20' fill='white' />
                        </clipPath>
                    </Defs>
                </Svg>
            );

        case "close-modal":
            return (
                <Svg width='40' height='40' viewBox='0 0 40 40' fill='none'>
                    <Rect
                        x='10.75'
                        y='10.75'
                        width='18.5'
                        height='18.5'
                        rx='9.25'
                        fill='white'
                        fillOpacity='0.02'
                    />
                    <Path
                        d='M22.8291 17.1714L17.1722 22.8282'
                        stroke='white'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                    />
                    <Path
                        d='M17.1719 17.1716L22.8287 22.8285'
                        stroke='white'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                    />
                    <Rect
                        x='10.75'
                        y='10.75'
                        width='18.5'
                        height='18.5'
                        rx='9.25'
                        stroke='white'
                        strokeWidth='1.5'
                    />
                </Svg>
            );

        case "close-icon":
            return (
                <Svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
                    <Rect
                        x='0.75'
                        y='0.75'
                        width='18.5'
                        height='18.5'
                        rx='9.25'
                        fill='white'
                        fill-opacity='0.02'
                    />
                    <Path
                        d='M12.8291 7.17139L7.17225 12.8282'
                        stroke='white'
                        stroke-width='1.5'
                        stroke-linecap='round'
                    />
                    <Path
                        d='M7.17188 7.17163L12.8287 12.8285'
                        stroke='white'
                        stroke-width='1.5'
                        stroke-linecap='round'
                    />
                    <Rect
                        x='0.75'
                        y='0.75'
                        width='18.5'
                        height='18.5'
                        rx='9.25'
                        stroke='white'
                        stroke-width='1.5'
                    />
                </Svg>
            );

        case "x-icon":
            return (
                <Svg width='48' height='48' viewBox='0 0 48 48' fill='none'>
                    <Path
                        d='M30 18L18 30'
                        stroke='#512A04'
                        stroke-width='3'
                        stroke-linecap='round'
                    />
                    <Path
                        d='M30 30L18 18'
                        stroke='#512A04'
                        stroke-width='3'
                        stroke-linecap='round'
                    />
                </Svg>
            );

        case "plus-icon":
            return (
                <Svg width='21' height='20' viewBox='0 0 21 20' fill='none'>
                    <Rect
                        x='1.25'
                        y='0.75'
                        width='18.5'
                        height='18.5'
                        rx='9.25'
                        fill='white'
                        fill-opacity='0.02'
                    />
                    <Path
                        d='M14.5 10L6.5 10'
                        stroke='white'
                        stroke-width='1.5'
                        stroke-linecap='round'
                    />
                    <Path
                        d='M10.5 5.99951L10.5 13.9995'
                        stroke='white'
                        stroke-width='1.5'
                        stroke-linecap='round'
                    />
                    <Rect
                        x='1.25'
                        y='0.75'
                        width='18.5'
                        height='18.5'
                        rx='9.25'
                        stroke='white'
                        stroke-width='1.5'
                    />
                </Svg>
            );

        case "left-arrow":
            return (
                <svg
                    width='64'
                    height='74'
                    viewBox='0 0 64 74'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <g filter='url(#filter0_d_222_40402)'>
                        <path d='M45 9L21 33L45 57' stroke='#473213' />
                    </g>
                    <defs>
                        <filter
                            id='filter0_d_222_40402'
                            x='-12'
                            y='-7'
                            width='88'
                            height='88'
                            filterUnits='userSpaceOnUse'
                            color-interpolation-filters='sRGB'
                        >
                            <feFlood
                                flood-opacity='0'
                                result='BackgroundImageFix'
                            />
                            <feColorMatrix
                                in='SourceAlpha'
                                type='matrix'
                                values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                                result='hardAlpha'
                            />
                            <feOffset dy='4' />
                            <feGaussianBlur stdDeviation='6' />
                            <feComposite in2='hardAlpha' operator='out' />
                            <feColorMatrix
                                type='matrix'
                                values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'
                            />
                            <feBlend
                                mode='normal'
                                in2='BackgroundImageFix'
                                result='effect1_dropShadow_222_40402'
                            />
                            <feBlend
                                mode='normal'
                                in='SourceGraphic'
                                in2='effect1_dropShadow_222_40402'
                                result='shape'
                            />
                        </filter>
                    </defs>
                </svg>
            );

        case "right-arrow":
            return (
                <svg
                    width='64'
                    height='74'
                    viewBox='0 0 64 74'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <g filter='url(#filter0_d_222_40406)'>
                        <path d='M20 57L44 33L20 8.99996' stroke='#473213' />
                    </g>
                    <defs>
                        <filter
                            id='filter0_d_222_40406'
                            x='-12'
                            y='-7'
                            width='88'
                            height='88'
                            filterUnits='userSpaceOnUse'
                            color-interpolation-filters='sRGB'
                        >
                            <feFlood
                                flood-opacity='0'
                                result='BackgroundImageFix'
                            />
                            <feColorMatrix
                                in='SourceAlpha'
                                type='matrix'
                                values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                                result='hardAlpha'
                            />
                            <feOffset dy='4' />
                            <feGaussianBlur stdDeviation='6' />
                            <feComposite in2='hardAlpha' operator='out' />
                            <feColorMatrix
                                type='matrix'
                                values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'
                            />
                            <feBlend
                                mode='normal'
                                in2='BackgroundImageFix'
                                result='effect1_dropShadow_222_40406'
                            />
                            <feBlend
                                mode='normal'
                                in='SourceGraphic'
                                in2='effect1_dropShadow_222_40406'
                                result='shape'
                            />
                        </filter>
                    </defs>
                </svg>
            );
        default:
            return null;
    }
}
