import Lottie from "lottie-react";
import { useState } from "react";
import { Dimensions } from "react-native";

interface Props {
    lottie: any;
}

export function LoadingWeb({ lottie }: Props) {
    const [windowWidth] = useState<number>(Dimensions.get("window").width);
    return (
        <Lottie
            style={{ transform: windowWidth < 500 ? "scale(3)" : "scale(2.5)" }}
            animationData={lottie}
            aria-aria-labelledby='use lottie animation'
        />
    );
}
