import { Audio } from "expo-av";
import { Dimensions } from "react-native";

class SoundPlayer {
    static soundObjects = {};

    static async loadSounds() {
        try {
            const sounds = {
                click: require("../../assets/audio/simple_click.mp3"),
                charmSelected: require("../../assets/audio/negative_charm.mp3"),
                charmUnselected: require("../../assets/audio/positive_charm.mp3"),
                swipe: require("../../assets/audio/swipe.mp3"),
                loopBg: require("../../assets/audio/loop_audio_bg.mp3"),
            };

            for (const [key, source] of Object.entries(sounds)) {
                const { sound } = await Audio.Sound.createAsync(source);
                this.soundObjects[key] = sound;
            }

            console.log("Sounds loaded");
        } catch (error) {
            console.log("Errore on sounds loading:", error);
        }
    }

    static async playSoundLoop() {
        // to enable when the sound will be ready
        return;
        try {
            const sound = this.soundObjects.loopBg;
            const { width } = Dimensions.get("window");

            if (sound) {
                sound.setStatusAsync({
                    volume: width < 1380 ? 0.5 : 1,
                    isLooping: true,
                });

                sound.playAsync();
            }
        } catch (error) {
            console.log("Error in sound", error);
        }
    }

    static async playSound(soundType) {
        // to enable when the sound will be ready
        return;
        try {
            const sound = this.soundObjects[soundType];
            if (sound) {
                sound.setVolumeAsync(0.7);
                sound.replayAsync();
            } else console.log("Sound not found");
        } catch (error) {
            console.log("Error in sound", error);
        }
    }

    static async stopSound(soundType) {
        // to enable when the sound will be ready
        return;
        const sound = this.soundObjects[soundType];
        if (sound) sound.stopAsync();
    }
}

export default SoundPlayer;
