import { Dimensions, StyleSheet } from "react-native";

export const styles = StyleSheet.create({
	container: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		alignContent: "center",
		padding: 16,
		flex: 1,
		width: Dimensions.get("window").width * 1.2,
		minWidth: Dimensions.get("window").width * 1.2,
		flexWrap: "wrap",
		minHeight: Dimensions.get("window").height * 1.02,
	},

	containerDesktop: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		alignContent: "center",
		gap: 70,
		padding: 16,
		flex: 1,
		flexWrap: "wrap",
		minHeight: Dimensions.get("window").height * 1.35,
	},

	containerTablet: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		alignContent: "center",
		gap: 70,
		padding: 16,
		flex: 1,
		flexWrap: "wrap",
		minHeight: Dimensions.get("window").height * 1.15,
	},

	containerTabletWeb: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		alignContent: "center",
		gap: 70,
		padding: 16,
		flex: 1,
		flexWrap: "wrap",
		minHeight: Dimensions.get("window").height * 1.16,
		width: Dimensions.get("window").width * 1.2,
		minWidth: Dimensions.get("window").width * 1.2,
	},

	containerMobile: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		alignContent: "center",
		gap: 70,
		padding: 16,
		flex: 1,
		flexWrap: "wrap",
		minHeight: Dimensions.get("window").height * 1.35,
	},
	containerSmallMobile: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		alignContent: "center",
		gap: 70,
		padding: 16,
		flex: 1,
		flexWrap: "wrap",
		minHeight: 950,
	},

	// --------------------- utils ---------------------
	flexCenter: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		gap: 16,
	},

	// --------------------- Arrows ---------------------
	arrow: {
		height: "100%",
		display: "flex",
		justifyContent: "center",
		position: "relative",
	},
	arrowTablet: {
		height: "100%",
		display: "flex",
		justifyContent: "center",
		position: "relative",
	},

	// --------------------- Info ---------------------

	moodContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: 604,
		height: 96,
		backgroundColor: "rgba(255, 255, 255, 0.20)",
		borderRadius: 8,
	},

	moodContainerTablet: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: 604,
		minWidth: 604,
		maxWidth: 604,
		height: 96,
		backgroundColor: "rgba(255, 255, 255, 0.20)",
		borderRadius: 8,
	},

	textContainer: {
		position: "absolute",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		height: "100%",
		marginTop: -72,
	},

	actionButton: {
		width: 430,
		height: 48,
		/*  marginTop: 117, */
		zIndex: 10,
	},

	// --------------------- Image ---------------------

	stepContainer: {},

	stepContainerTablet: {
		bottom: "-12px",
	},

	// --------------------- Title ---------------------

	titleContainerMobile: {
		fontSize: 30,
		fontWeight: "300",
		color: "#473213",
		marginBottom: 30,
		bottom: "-12px",
		textTransform: "uppercase",
	},

	titleContainerTablet: {
		fontSize: 36,
		fontWeight: "300",
		color: "#473213",
		marginBottom: 30,
		bottom: "-12px",
		textTransform: "uppercase",
	},

	titleContainerDesktop: {
		fontSize: 36,
		fontWeight: "300",
		color: "#473213",
		marginBottom: 30,
		textTransform: "uppercase",
	},

	// --------------------- Carousel ---------------------
	carousel: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		width: "100%",
		minWidth: "85%",
		/* maxWidth: 768, */
		marginHorizontal: "auto",
		position: "absolute",
		top: "50%",
		transform: [{ translateY: -50 }],
		paddingHorizontal: 48,
		zIndex: 10,
	},

	flatListCarousel: {
		height: Dimensions.get("window").height,
		minHeight: Dimensions.get("window").height,
		width: Dimensions.get("window").width,
		minWidth: Dimensions.get("window").width,
	},
});
