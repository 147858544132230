import React from "react";
import { Pressable } from "react-native";
import { styles } from "./InfoBtn.style";
import SvgComponent from "../../Svg/SvgComponent";
import { useJourneyStore } from "../../../store/JourneyStore";
import { useStepManagerStore } from "../../../store/ExperienceStore";
import SoundPlayer from "../../../utils/SoundManager";

const InfoBtn = ({ isMobile }: { isMobile?: any }) => {
    const { currentStepIndex } = useStepManagerStore((state) => {
        return { currentStepIndex: state.currentStepIndex };
    });

    const { falseModal } = useJourneyStore((state) => {
        return { falseModal: state.falseModal };
    });

    const openTutorial = () => {
        SoundPlayer.playSound("click");
        useJourneyStore.getState().setPrevInfo(falseModal);
        useJourneyStore.getState().onChangeFalseModal("tutorial");
    };

    return (
        <Pressable
            accessibilityLabel='Tutorial button'
            style={[
                styles.container,
                isMobile ? styles.mobileSizes : styles.originalSizes,
            ]}
            onPress={openTutorial}
        >
            <SvgComponent type='info' />
        </Pressable>
    );
};

export default InfoBtn;
