import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
    container: {
        height: 44,
        flexDirection: "row",
        justifyContent: "flex-end",
        alignContent: "center",
        alignItems: "center",
    },

    iconTextContainer: {
        backgroundColor: "rgba(0, 0, 0, 0.35)",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 4,
        paddingHorizontal: 12,
        cursor: "pointer",
        borderRadius: 10,
    },

    mobileSizes: {
        width: 40,
        height: 30,
        paddingInline: 4,
        paddingVertical: 4,
    },

    originalSizes: {
        width: 77,
        height: 30,
        paddingInline: 12,
        paddingVertical: 4,
    },

    open: {
        backgroundColor: "rgba(255, 255, 255, 0.4)",
    },

    dropdown: {
        position: "absolute",
        top: 42,
        right: 0,
        width: 77,
        maxHeight: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 6,
        overflow: "hidden",
        transition: "max-height 0.3s ease-in-out",
        borderRadius: 10,
    },

    dropdownOpen: {
        backgroundColor: "rgba(255, 255, 255, 0.4)",
        maxHeight: 300,
    },

    dropdownItem: {
        backgroundColor: "transparent",
        width: 77,
        height: 30,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 6,
        paddingInline: 12,
        paddingVertical: 6,
        cursor: "pointer",
    },

    dropdownItemHover: {
        backgroundColor: "rgba(255, 255, 255, 0.4)",
    },
    dropdownItemActive: {
        backgroundColor: "rgba(255, 255, 255, 0.4)",
    },
});
