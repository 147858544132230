import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
    charmPointerContainer: {
        position: "absolute",
        opacity: 0.5,
        zIndex: 4,
        justifyContent: "center",
        alignItems: "center",
    },
    charmPointer: {
        width: 50,
        height: 50,
        backgroundColor: "black",
        opacity: 0.3,
        borderRadius: 25,
        borderWidth: 10,
        borderColor: "white",
    },
});
