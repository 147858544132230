import { styles } from "./MoodSelector.styles";
import { IStepAction } from "../../data/dataInterfaces";

import { AnimatePresence, MotiView } from "moti";
import BvlgariText from "../TextElement/BvlgariText";
import StepBar from "../StepBar/StepBar";
import { Dimensions, Pressable, View } from "react-native";
import { useEffect, useState } from "react";
import { ActionButton } from "../ActionButton/ActionButton";
import CarouselPoint from "../CarouselPoints/CarouselPoint";
import { TMoodType, useJourneyStore } from "../../store/JourneyStore";
import { useStepManagerStore } from "../../store/ExperienceStore";

export interface IMoodSelectorConfig {
	step: number;
	title: string;
	joy: {
		title: string;
		description: string;
	};
	love: {
		title: string;
		description: string;
	};
	care: {
		title: string;
		description: string;
	};
	action: IStepAction;
}

export interface IMoodSelectorProps {
	config: IMoodSelectorConfig;
}

export function MoodSelector({ config }: IMoodSelectorProps) {
	/* ------------ ZUSTAND ------------ */

	const { moodSwipe, mood } = useJourneyStore((state) => ({
		moodSwipe: state.moodSwipe,
		mood: state.mood,
	}));

	const isIos = useJourneyStore.getState().isIos;

	/* ------------ STATE ------------ */
	const [selectedMood, setSelectedMood] = useState<TMoodType>(
		useJourneyStore.getState().mood,
	);

	const [windowWidth] = useState<number>(Dimensions.get("window").width);

	const [currentStep, setCurrentStep] = useState<number>(
		useStepManagerStore.getState().currentStepIndex,
	);

	/* ------------ ZUSTAND UNSUB ------------ */
	const unsubSelectedMood = useJourneyStore.subscribe((state) => {
		state.mood !== selectedMood && setSelectedMood(state.mood);
	});

	const unsubCurrentStep = useStepManagerStore.subscribe((state) => {
		state.currentStepIndex !== currentStep &&
			setCurrentStep(state.currentStepIndex);
	});
	useEffect(() => {
		return () => {
			unsubCurrentStep();
			unsubSelectedMood();
		};
	}, []);

	return (
		<AnimatePresence exitBeforeEnter>
			<MotiView
				key={`${currentStep}_selectMood`}
				style={[
					windowWidth < 390
						? styles.containerSmallMobile
						: windowWidth < 520
						? styles.containerMobile
						: isIos && windowWidth > 520 && windowWidth < 1280
						? styles.containerTabletWeb
						: windowWidth > 520 && windowWidth < 1280
						? styles.containerTablet
						: windowWidth > 1280
						? styles.containerDesktop
						: styles.container,
				]}
			>
				{/* ------------ HEADER  ( STEP BAR  + TITLE )  ------------ */}
				<View style={styles.flexCenter}>
					{/* ------------ STEP ------------ */}
					<View
						style={
							innerWidth > 512 && innerWidth < 900
								? styles.stepContainerTablet
								: styles.stepContainer
						}
					>
						{/* ------------ TITLE STEP BAR  ------------ */}

						<BvlgariText
							text={`$step${config.step}`}
							textType='xlLight'
							style={{
								color: "#473213",
								fontSize: 20,
								fontWeight: "300",
								marginBottom: 4,
								letterSpacing: 3,
							}}
						/>
						{/* ------------ STEP BAR  ------------ */}
						<StepBar totalSteps={3} activeStep={config.step} />
					</View>

					{/* ------------ TITLE  ------------ */}
					<BvlgariText
						text={config.title}
						textType='xlLight'
						style={
							innerWidth < 512
								? styles.titleContainerMobile
								: innerWidth > 512 && innerWidth < 900
								? styles.titleContainerTablet
								: styles.titleContainerDesktop
						}
					/>
				</View>

				{/* TESTO PER NON VEDENTI */}
				<Pressable
					accessible={false}
					accessibilityLabel={"Selected Ribbon " + mood}
					style={{
						opacity: 0,
						flex: 1,
						width: "100%",
					}}
				></Pressable>

				{/* ------------ POINTS + ACTION AND INFORMATION ------------ */}
				<View style={[styles.flexCenter, { gap: 8 }]}>
					{/* ------------ POINTS ------------ */}

					<CarouselPoint
						totalSteps={3}
						indefOfActiveStep={moodSwipe}
					/>

					{/* ------------ MOOD INFORMATION ------------ */}
					<View style={styles.moodContainerTablet}>
						<View style={styles.actionButton}>
							<ActionButton action={config.action} />
						</View>
					</View>
				</View>
			</MotiView>
		</AnimatePresence>
	);
}
