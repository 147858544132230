import { Dimensions, TouchableOpacity } from "react-native";
import { useEffect, useState } from "react";
import { styles } from "./CharmPointers.styles";
import { ICharmType } from "./CharmSelector";
import { useJourneyStore } from "../../store/JourneyStore";
import { useStepManagerStore } from "../../store/ExperienceStore";
import { askForUserConfirm, translate } from "../../utils/utils";
import { charmSelector } from "../../configuration/steps/charmSelector";
import { View } from "moti";
import BvlgariText from "../TextElement/BvlgariText";

export interface Point {
    x: number;
    y: number;
    width: number;
    height: number;
    id: string;
    title: string;
    zIndex?: number;
    rotate?: number;
}

export default function CharmPointers() {
    /* ------------ ZUSTAND ------------ */
    const { falseModal } = useJourneyStore((state: any) => ({
        falseModal: state.falseModal,
    }));

    /* ------------ STATE ------------ */
    const [windowWidth, setWindowWidth] = useState<number>(
        Dimensions.get("window").width,
    );
    const [windowHeight, setWindowHeight] = useState<number>(
        Dimensions.get("window").height,
    );
    const [currentStep, setCurrentStep] = useState<number>(
        useStepManagerStore.getState().currentStepIndex,
    );
    const [charmSelected, setCharmSelected] = useState<
        ICharmType[] | undefined
    >(useJourneyStore.getState().selectedCharms ?? []);

    /* --------------- ZUSTAND UNSUB --------------- */
    const unsubCurrentStep = useStepManagerStore.subscribe((state) => {
        state.currentStepIndex !== currentStep &&
            setCurrentStep(state.currentStepIndex);
    });

    const unsubSelectedCharm = useJourneyStore.subscribe((state) => {
        state.selectedCharms !== charmSelected &&
            setCharmSelected(state.selectedCharms);
    });

    /* --------------- METHODS --------------- */
    const handlePointerClick = (point: Point) => {
        //console.log(`Clicked pointer: ${point.id}`)
        onButtonPress(point);
    };

    const onButtonPress = async (charm: ICharmType) => {
        try {
            const charmAlreadySelected = charmSelected?.find(
                (c) => c.id === charm.id,
            );
            if (
                !charmSelected ||
                (charmSelected?.length >= 3 && !charmAlreadySelected)
            )
                await askForUserConfirm(
                    "",
                    translate("$charmSelectionPage.errorSelection") +
                        "\n" +
                        translate("$errors.selectThreeCharmsHint"),
                    "charms-error",
                );
            else useJourneyStore.getState().toggleCharm?.(charm);
        } catch (e) {
            //console.log(e)
        }
    };

    useEffect(() => {
        return () => {
            unsubCurrentStep();
            unsubSelectedCharm();
        };
    }, []);

    useEffect(() => {
        Dimensions.addEventListener("change", () =>
            setWindowWidth(Dimensions.get("window").width),
        );
        Dimensions.addEventListener("change", () =>
            setWindowHeight(Dimensions.get("window").height),
        );
    }, []);

    /* --------------- VARIABLES --------------- */
    const clickablePoints = charmSelector.operations?.config.charmsObjects;

    if (falseModal) return null;

    return clickablePoints.map((point: Point, index: number) => {
        const size = calulateImageSizeForNewSize(
            imgSizes,
            { width: windowWidth, height: windowHeight },
            point,
        );
        return (
            <TouchableOpacity
                onPress={() => handlePointerClick(point)}
                key={`${point.id}_${index}`}
                style={[
                    styles.charmPointerContainer,
                    {
                        top: size.y,
                        left: size.x,
                        width: size.width,
                        height: size.height,
                        transform: [{ rotate: `${point.rotate ?? 0}deg` }],
                        zIndex: point.zIndex ?? 10,
                    },
                ]}
            >
                <View
                    style={{
                        position: "relative",
                        minHeight: "100%",
                        minWidth: "100%",
                    }}
                >
                    {/* TESTO PER NON VEDENTI */}
                    <BvlgariText
                        text={point.title}
                        style={{
                            color: "transparent",
                            opacity: 0,
                            height: "100%",
                            width: "100%",
                        }}
                    />
                </View>
            </TouchableOpacity>
        );
    });
}

// ================ Utils ================ //

const calulateImageSizeForNewSize = (
    imgSizes: { width: number; height: number },
    newSizes: { width: number; height: number },
    point: Point,
) => {
    // calcolo ratio
    const aspectRatio = imgSizes.width / imgSizes.height;

    // calcolo nuove dim immagine contando essendo in modalità cover

    const isRatioGreater = newSizes.width / newSizes.height > aspectRatio;
    const resizedWidth = isRatioGreater
        ? newSizes.width
        : newSizes.height * aspectRatio;
    const resizedHeight = isRatioGreater
        ? newSizes.width / aspectRatio
        : newSizes.height;
    const offsetX = isRatioGreater ? 0 : (newSizes.width - resizedWidth) / 2;
    const offsetY = isRatioGreater ? (newSizes.height - resizedHeight) / 2 : 0;

    // nuove dim punto
    const newHeight = (point.height * resizedHeight) / imgSizes.height;
    const newWidth = (point.width * resizedWidth) / imgSizes.width;

    // nuove coordinate punto
    const newY = (point.y * resizedHeight) / imgSizes.height + offsetY;
    const newX = (point.x * resizedWidth) / imgSizes.width + offsetX;

    // nuove coordinate punto
    const finalX = newX - newWidth / 2;
    const finalY = newY - newHeight / 2;

    return { x: finalX, y: finalY, width: newWidth, height: newHeight };
};

//// ================ Configurations ================ ////

const imgSizes = {
    width: 1920,
    height: 1080,
};
