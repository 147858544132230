import { IInformativeConfig } from '../../components/Informative/Informative'
import IStep, { OperationType } from '../../data/dataInterfaces'
import { useStepManagerStore } from '../../store/ExperienceStore'
import { useJourneyStore } from '../../store/JourneyStore'
import { __ } from '../../translations/translations'

export const authInformativeData: IStep = {
  title: '',
  id: 'first_step',
  operations: {
    type: OperationType.informative,
  },
}

export const configInformativeRegisterSuccess: IInformativeConfig = {
  type: 'success',
  removeHeaderFooter: true,
  successText: '$successErrorPage.informativeData.configInformativeRegisterSuccess.successText',
  succMobileFirstLine: '$successErrorPage.informativeData.configInformativeRegisterSuccess.successMobileFirstLine',
  succMobileSecondLine: '$successErrorPage.informativeData.configInformativeRegisterSuccess.successMobileSecondLine',
  successTextType: 'xlLight',
}

export const configInformativeLoginSuccess: IInformativeConfig = {
  type: 'success',
  removeHeaderFooter: true,
  successText: '$successErrorPage.informativeData.configInformativeLoginSuccess.successText',
  succMobileFirstLine: '$successErrorPage.informativeData.configInformativeLoginSuccess.successMobileFirstLine',
  succMobileSecondLine: '$successErrorPage.informativeData.configInformativeLoginSuccess.successMobileSecondLine',
  successTextType: 'xlLight',
}

export const configInformativeSuccessMailSent: IInformativeConfig = {
  type: 'success',
  removeHeaderFooter: true,
  successText: '$successErrorPage.configInformativeSuccessMailSent.successText',
  succMobileFirstLine: '$successErrorPage.configInformativeSuccessMailSent.succMobileFirstLine',
  succMobileSecondLine: '$successErrorPage.configInformativeSuccessMailSent.succMobileSecondLine',
  successTextType: 'xlLight',
}

export const configInformativeSuccessExperienceSent: IInformativeConfig = {
  type: 'success',
  removeHeaderFooter: true,
  successText: '$successErrorPage.configInformativeSuccessExperienceSent.successText',
  succMobileFirstLine: '$successErrorPage.configInformativeSuccessExperienceSent.succMobileFirstLine',
  succMobileSecondLine: '$successErrorPage.configInformativeSuccessExperienceSent.succMobileSecondLine',
  successTextType: 'xlLight',
}

export const configInformativeError: IInformativeConfig = {
  type: 'error',
  errorTitle: '$successErrorPage.configInformativeError.title',
  errorTitleType: 'xlBoldUp',
  errorSubtitle: '$successErrorPage.configInformativeError.subtitle',
  errorSubtitleType: 'xlLight',
  errorMobile: '$successErrorPage.configInformativeError.errorMobile',
  errorMobileType: 'xlLight',
  action: {
    id: '',
    size: 'default',
    title: '$successErrorPage.configInformativeError.backButton',
    btnType: 'backButton',
    action: () => {
      useJourneyStore.getState().clearUserData()
      useStepManagerStore.getState().decreaseStep(undefined, 2)
    },
  },
}
