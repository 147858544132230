export const Lottie = {
    charmsTutorial: require("./charms-tutorial.json"),
    loaderPlaceholder: require("./loader_placeholder.json"),
    loopGioielli: require("./loop-gioielli.json"),
    charmB: require("./Hint-b.json"),
    charmBox: require("./Hint-box.json"),
    charmHeart: require("./Hint-heart.json"),
    charmInfinite: require("./Hint-infinite.json"),
    charmMoon: require("./Hint-moon.json"),
    charmStar: require("./Hint-star.json"),

    // shine: require('./shine.json'),
};
