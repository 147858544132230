import IStep, { OperationType } from '../../data/dataInterfaces'
import { useStepManagerStore } from '../../store/ExperienceStore'
import { useJourneyStore } from '../../store/JourneyStore'
import { Identifiers } from '../../utils/Indentifiers'

export const videoGenerationLoading: IStep = {
  title: '',
  id: Identifiers.VIDEO_GENERATION_LOADING,
  operations: {
    type: OperationType.loading,
    config: {
      title: 'Video Generation Loading',
    },
  },
  actions: [
    {
      id: '',
      title: '$next',
      btnType: 'filled',
      size: 'default',
      action: () => {
        useJourneyStore.getState().setDoExitAnimation()
      },
    },
  ],
}
