import React, { useEffect, useRef, useState } from "react";
import { Pressable, TextInput, View } from "react-native";
import { styles } from "./SelectElement.style";
import SvgComponent from "../Svg/SvgComponent";
import BvlgariText from "../TextElement/BvlgariText";
import { SelectOption, Select } from "../../data/dataInterfaces";
import { isIos, translate } from "../../utils/utils";
import { useJourneyStore } from "../../store/JourneyStore";
// import { useLanguageStore } from 'stores/LanguageStore'

interface Props {
	select?: Select;
	onChange: (id: string, value: string) => void;
	onReturnData?: (data: any) => void;
	styleCustom?: any;
}

const SelectElement = ({
	select,
	onChange,
	onReturnData,
	styleCustom,
}: Props) => {
	// ---------------- ZUSTAND ----------------
	// const { rtl } = useLanguageStore((state: any) => ({ rtl: state.rtl }))
	const rtl = false;
	const { falseModal, selectedStoreCountry, userData } =
		useJourneyStore.getState();

	// ---------------- STATE ----------------
	const [selected, setSelected] = useState<SelectOption>(
		userData?.CountryRegion
			? {
					image: undefined,
					title:
						select?.options.find(
							(opt) => opt.id === userData?.CountryRegion,
						)?.title ?? "",
					id:
						select?.options.find(
							(opt) => opt.id === userData?.CountryRegion,
						)?.id ?? "",
			  }
			: (falseModal === "send" ||
					falseModal === "qr" ||
					falseModal === "invite") &&
			  selectedStoreCountry
			? {
					image: undefined,
					title:
						select?.options.find(
							(opt) => opt.id === selectedStoreCountry,
						)?.title ?? "",
					id:
						select?.options.find(
							(opt) => opt.id === selectedStoreCountry,
						)?.id ?? "",
			  }
			: { image: undefined, title: "", id: "" },
	);
	const [open, setOpen] = useState<boolean>(false);
	const [hoverIndex, setHoverIndex] = useState<number>(-1);

	const inputRef = useRef<TextInput>(null);
	const selectContainerRef = useRef<any>(null);

	// ---------------- METHODS ----------------
	//  validazione e invio dati al genitore
	const validation = (optionSelected: SelectOption) => {
		const isValid = select?.options.some(
			(opt) => opt.title === optionSelected.title,
		);

		if (isValid) {
			onChange?.(select!.id, optionSelected.title!);
			return isValid;
		} else {
			onReturnData?.(undefined);
			return null;
		}
	};

	// apertura e chiusura dropdown
	const toggleOpen = (value: boolean) => {
		if (open && inputRef.current) inputRef.current.focus();
		setOpen(value);
	};

	const onChangeSelected = (opt: SelectOption) => {
		/*    console.log(opt); */
		setSelected(opt);
		validation(opt);
	};

	// ricerca opzioni da mostrare
	const onChangeSearch = (text: string) => {
		toggleOpen(true);

		const newText = text.includes("-")
			? text.toLowerCase().split(" - ")[1].trim()
			: text;
		setSelected((prev) => {
			validation({ id: newText !== "" ? prev.id : "", title: newText });
			return { id: newText !== "" ? prev.id : "", title: newText };
		});
	};

	const handleClickOutside = (event: any) => {
		if (
			selectContainerRef.current &&
			!selectContainerRef.current.contains(event.target)
		) {
			toggleOpen(false);
		}
	};

	// filtro opzioni per mostrare la ricerca o tutte le opzioni se non si trova niente
	const optionsFilterFN = () => {
		if (!selected.title) {
			return select?.options || [];
		}

		const searchTerm = selected.title.toLowerCase();

		// Filtra le opzioni che includono il termine di ricerca
		const optionsIncludingSearchTerm =
			select?.options.filter(
				(opt) =>
					opt.title?.toLowerCase().includes(searchTerm) ||
					opt.id.toLowerCase().includes(searchTerm),
			) || [];

		// Filtra le opzioni che iniziano con il termine di ricerca (lettera per lettera)
		const optionsStartingWithLetters =
			select?.options
				.filter((opt) => {
					const optTitle = opt.title?.toLowerCase() || "";
					return (
						!optTitle.includes(searchTerm) &&
						searchTerm
							.split("")
							.every((letter) => optTitle.includes(letter))
					);
				})
				.sort((a, b) => {
					const aEl = a.title;
					const bEl = b.title;
					if (aEl?.startsWith(searchTerm)) {
						return -1;
					}

					return -1;
				}) || [];

		// Filtra le opzioni che non corrispondono né per parola né per lettera
		const optionsNotMatching =
			select?.options.filter(
				(opt) =>
					!opt.title?.toLowerCase().includes(searchTerm) &&
					!searchTerm
						.split("")
						.every((letter) =>
							opt.title?.toLowerCase().includes(letter),
						),
			) || [];

		// Concatena tutti i risultati
		return optionsIncludingSearchTerm.concat(
			optionsStartingWithLetters,
			optionsNotMatching,
		);
	};

	const optionsFilter = optionsFilterFN();

	function compareIds(a: any, b: any) {
		// Converto le stringhe ID in numeri per il confronto
		const idA = parseInt(a.id);
		const idB = parseInt(b.id);

		// Se l'ID non può essere convertito in un numero, ritorna 0 per mantenere l'ordine originale
		if (isNaN(idA) || isNaN(idB)) {
			return 0;
		}

		// Se idA è minore di idB, restituisco un numero negativo
		if (idA < idB) {
			return -1;
		}
		// Se idA è maggiore di idB, restituisco un numero positivo
		if (idA > idB) {
			return 1;
		}
		// Se idA è uguale a idB, restituisco 0
		return 0;
	}

	const removeDuplicates = (arr: any) => {
		const uniqueArray = arr.filter((elem: any, index: any, self: any) => {
			return index === self.indexOf(elem);
		});
		return uniqueArray.sort((a: any, b: any) => {
			const searchTerm = selected.title?.toLowerCase() || "";
			const aIndexOfSearchTerm = a
				.title!.toLowerCase()
				.indexOf(searchTerm);
			const bIndexOfSearchTerm = b
				.title!.toLowerCase()
				.indexOf(searchTerm);

			// Se entrambi gli elementi contengono la lettera cercata, ordina in base alla posizione
			if (aIndexOfSearchTerm !== -1 && bIndexOfSearchTerm !== -1) {
				return aIndexOfSearchTerm - bIndexOfSearchTerm;
			}

			// Se solo il primo elemento contiene la lettera cercata, mettilo in primo piano
			if (aIndexOfSearchTerm !== -1) {
				return -1;
			}

			// Se solo il secondo elemento contiene la lettera cercata, mettilo in secondo piano
			if (bIndexOfSearchTerm !== -1) {
				return 1;
			}

			// Se nessuno dei due elementi contiene la lettera cercata, ordina alfabeticamente
			return a.title!.localeCompare(b.title!);
		});
	};
	// ---------------- USEEFFECT ----------------
	useEffect(() => {
		!isIos && document.addEventListener("mousedown", handleClickOutside);
		return () => {
			!isIos &&
				document.removeEventListener("mousedown", handleClickOutside);
		};
	});

	return (
		<View
			ref={selectContainerRef}
			style={[
				falseModal === "send" ||
				falseModal === "qr" ||
				falseModal === "invite"
					? styles.containerOnSend
					: styles.container,
				rtl && { alignItems: "flex-end" },
				open && styles.open,
				,
				select?.customMaxWidth
					? { maxWidth: select.customMaxWidth }
					: {},
				styleCustom ? styleCustom : {},
			]}
		>
			<Pressable
				style={[
					falseModal === "send" ||
					falseModal === "qr" ||
					falseModal === "invite"
						? styles.selectContainerOnSend
						: styles.selectContainer,
				]}
				onPress={() => toggleOpen(true)}
			>
				<View style={[styles.select]}>
					{/* --------------------- Input Ricerca + elemento Selezionato */}
					{/* --------- select con id e testo */}
					{select?.withIdText && (
						<TextInput
							accessibilityLabel='Insert ID Shop Selectable'
							placeholder='ID SHOP '
							placeholderTextColor={"rgba(71, 50, 19, 0.5)"}
							ref={inputRef}
							value={
								selected.id
									? `${selected.id} - ${selected.title}`
									: selected.title
							}
							style={[
								styles.label,
								rtl && { textAlign: "right" },
								open && styles.open,
							]}
							onChangeText={onChangeSearch}
							keyboardType='default'
						/>
					)}
					{/* ---------- select con solo testo */}
					{!select?.withIdText && (
						<TextInput
							accessibilityLabel={`Insert ${translate(
								select?.placeholder ?? "",
							)} Selectable ${
								select?.isRequired ? "is required" : ""
							}`}
							placeholder={
								select?.placeholder
									? `${translate(
											select?.placeholder ?? "",
									  ).toUpperCase()} ${
											select.isRequired ? "*" : ""
									  }`
									: "LOCALE"
							}
							placeholderTextColor={"rgba(71, 50, 19, 0.5)"}
							ref={inputRef}
							value={selected.title}
							style={[
								styles.label,
								rtl && { textAlign: "right" },
								open && styles.open,
							]}
							onChangeText={onChangeSearch}
							keyboardType='default'
						/>
					)}
					{/* --------------------- SVG Freccia */}
					{!select?.hideSelector && (
						<Pressable
							accessibilityLabel='Open selectable options'
							style={styles.arrow}
							onPress={() => setOpen((prev) => !prev)}
						>
							<SvgComponent type='arrow' />
						</Pressable>
					)}
				</View>
				{/* --------------------- Dropdown con opzioni*/}
				<Pressable
					style={[styles.dropdown, open && styles.dropdownOpen]}
				>
					{open &&
						optionsFilter &&
						removeDuplicates(optionsFilter).map(
							(option: any, i: number) => (
								<Pressable
									key={option.id + i}
									style={[
										styles.option,
										// hover style
										hoverIndex === i && styles.optHover,
										// selected style
										selected.title === option.title &&
											styles.optSelected,
										// rtl style
										rtl && {
											justifyContent: "flex-end",
											paddingRight: 40,
										},
									]}
									onPress={() => {
										onChangeSelected(option);
										toggleOpen(false);
									}}
									onHoverIn={() => setHoverIndex(i)}
									onHoverOut={() => setHoverIndex(-1)}
								>
									<BvlgariText
										text={
											select?.storeCode
												? `${
														option.id
												  } - ${option.title!}`
												: option.title!
										}
										textType='mBRegular'
										style={{
											textAlign: rtl ? "right" : "left",
										}}
									/>
								</Pressable>
							),
						)}
				</Pressable>
			</Pressable>
		</View>
	);
};

export default SelectElement;
