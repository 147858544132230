import React from "react";
import { View } from "react-native";
import SelectElement from "../Select/SelectElement";
import { Select } from "../../data/dataInterfaces";

interface Props {
    select: Select[];
    onReturnData: (i: number, data: string, id: string) => void;
}

const DoubleSelectContainer = ({ select, onReturnData }: Props) => {
    return (
        <View
            style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                width: "100%",
                maxWidth: 555,
            }}
        >
            {/* ---------------- SELECT ---------------- */}
            {select.map((s, i) => (
                <SelectElement
                    key={`ds-${i}`}
                    select={s}
                    onChange={(id, data) => onReturnData(i, data, id)}
                />
            ))}
        </View>
    );
};

export default DoubleSelectContainer;
