import { I18n } from 'i18n-js'
import en from './en.json'
import it from './it.json'
import zf from './zf.json'
import zh from './zh.json'
import ko from './ko.json'
import ja from './ja.json'
import ar from './ar.json'
import es from './es.json'

const i18n = new I18n({
  ...it,
  ...en,
  ...zf,
  ...zh,
  ...ko,
  ...ja,
  ...ar,
  ...es,
})

export const __ = (id: string) => i18n.t(id)
export const setLanguage = (language: string) => (i18n.locale = language)
// per usare una traduzione chiamare __('next')
