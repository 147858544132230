import { Platform } from "react-native";
import { LanguageFlags } from "../data/dataInterfaces";
import { __ } from "../translations/translations";
import { useJourneyStore } from "../store/JourneyStore";

export const isIos = Platform.OS === "ios";
export const isIosApp = new URLSearchParams(window.location.search).get(
	"device",
);

export const VIEWPORT_H_OFFSET = 0;
export const BASE_IOSAPP_SCALE = 0.8;

export const BIG_IPAD_FINAL_VIDEO_SCALE = 1.2;
export const IPAD_FINAL_VIDEO_SCALE = 1.1;
export const BIG_WEB_FINAL_VIDEO_SCALE = 1.2;
export const WEB_FINAL_VIDEO_SCALE = 1.05;

export const BASE_SCALE = 0.55;
export const IPAD_SCALE = isIosApp ? 0.67 : 0.67;

export const VIDEO_IDLE_DURATION = 60000;

// export const __import = (module: { iOSModule: string; webModule: string }) => {
//   const modulePath: string = isIos ? module.iOSModule : module.webModule
//   return import(modulePath)
// }

export const getIsMadrid = () => {
	return useJourneyStore.getState().selectedStoreId === "131";
};

export const getQueryParams = (params: string) => {
	const urlParams = new URLSearchParams(params);
	const entries = urlParams.entries();
	const queryParams: { [key: string]: string } = {};

	for (const entry of entries) {
		queryParams[entry[0]] = entry[1];
	}
	return queryParams;
};

export const Margin = isIos
	? { rem1: 16, rem3: 48, rem1_5: 24, rem10: 160 }
	: { rem1: "1rem", rem3: "3rem", rem1_5: "1.5rem", rem10: "10rem" };

export const getRandomUid = () => {
	return Math.random().toString(36).substring(7);
};

export const isScreenPage = () => {
	const { screen } = getQueryParams(window.location.search);
	return Platform.OS === "web" && screen;
};

export const askForUserConfirm = async (
	title: string,
	message: string,
	error:
		| "login"
		| "error"
		| "network-error"
		| "charms-error"
		| "check-email"
		| "error-mail"
		| undefined = undefined,
) => {
	useJourneyStore.getState().setUserConfirm(title, message, error);
	useJourneyStore.getState().onChangeFalseModal("reloadModal");
};

export const enumLanguage: LanguageFlags = {
	en: "flag-uk",
	it: "flag-ita",
	zh: "flag-chi-trad",
	zf: "flag-chi-sem",
	ja: "flag-jap",
	ko: "flag-kor",
	ar: "flag-arab",
	es: "flag-esp",
};

export const addZero = (str: string, num = 4) => {
	return str.padStart(num, "0");
};

export const translate = (value: string) => {
	if (!value) return "";
	return value?.startsWith("$") ? __(value.replace("$", "")) : value;
};

export const shareUrl = async (url: string, target: string) => {
	let blob;

	try {
		const nonce = Math.floor(Math.random() * 9999);
		const response = await fetch(url + `?nonce=${nonce}`, {
			headers: new Headers({
				"Content-Disposition":
					'attachment; filename="Your-Greeting-Card.mp4"',
			}),
		});
		blob = await response.blob();
		const urlVideo = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = urlVideo;
		a.download = "Your-Greeting-Card.mp4"; // Specifica il nome del file desiderato
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);

		setTimeout(() => {
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
		}, 0);
	} catch (error) {
		console.log("download error", error);
	}

	try {
		/* const isAndroid = navigator.userAgent.toLowerCase().includes("android");
		const nonce = Math.floor(Math.random() * 9999);
		try {
			const response = await fetch(
				url + `?nonce=${nonce}`,
				isAndroid
					? {}
					: {
							headers: new Headers({
								"Content-Disposition":
									'attachment; filename="Your-Greeting-Card.mp4"',
							}),
					  },
			);
			blob = await response.blob();
		} catch (error) {
			console.log("Sharing Error", error);
			const _a = document.createElement("a");
			document.body.appendChild(_a);
			_a.href = url;
			_a.target = target;
			_a.download = `Your-Greeting-Card.mp4`;
			_a.click();
			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(_a);
			}, 0);
			return;
		} */
		/* 		const fileName = `Your-Greeting-Card.mp4`;
		const file = new File([blob], fileName, { type: "video/mp4" });

		const shareData = {
			title: fileName,
			files: [file],
		};

		try {
			if (!navigator.share) throw new Error("Not supported");
			navigator.share(shareData);
		} catch (error) {
			console.log("Sharing Error", error);
				const a = document.createElement("a");
			document.body.appendChild(a);
			a.href = window.URL.createObjectURL(blob);
			a.download = fileName;
			a.target = target;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			}, 0);
		} */
	} catch (error) {
		/* 		alert(`Catch in shareUrl: ${JSON.stringify(error)}, ${error}`);
		 */
	}
};

export const toggleAppReload = (currentIndex: number) => {
	const RefreshDisabledSteps = [0, 1];
	const toggle: boolean = RefreshDisabledSteps.includes(currentIndex); // true: disable | false: enable
	const url = new URL(window.location.href);
	toggle
		? url.searchParams.set("refresh", "false")
		: url.searchParams.delete("refresh");
	window.history.replaceState({}, "", url.toString());
};
