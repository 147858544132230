import { StyleSheet, Dimensions } from "react-native";

export const styles = StyleSheet.create({
	absoluteContainer: {
		position: "absolute",
		height: Dimensions.get("window").height * 1.2 + 100,
		minHeight: Dimensions.get("window").height * 1.2 + 100,
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "center",
		zIndex: 99998,
		cursor: "pointer",
	},

	content: {
		paddingTop: 50,
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "center",
		gap: 60,
		position: "relative",
	},
	contentDesktop: {
		paddingTop: 0,
		gap: 30,
	},

	contentTabletWeb: {
		paddingTop: 0,
		gap: 38,
	},

	contentMobile: {
		paddingTop: 14,
		gap: 35,
	},

	contentSmallMobile: {
		paddingTop: 8,
		gap: 24,
	},

	actionsContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		flexWrap: "wrap",
		gap: 15,
		width: "100%",
		maxWidth: 632,
		paddingHorizontal: 16,
		marginHorizontal: "auto",
	},
	allSmallBtn: {
		flexWrap: "nowrap",
		justifyContent: "center",
	},

	// --------------- form
	scrollContainer: {
		width: "100%",
		maxWidth: 635,
		minHeight: 790,
		height: "100%",
		/* maxHeight: 718, */
		display: "flex",
		flexDirection: "column",
	},

	scrollMobile: {
		maxHeight: 550,
		minHeight: 550,
		height: 550,
	},

	scrollDesktop: {},
	scrollDesktopSmall: { maxHeight: 660, minHeight: 660, height: 660 },

	infoContainer: {
		width: "100%",
		maxWidth: 635,
		height: "100%",
		maxHeight: 300,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-start",
		gap: 75,
		backgroundColor: "rgba(255, 255, 255, 0.3)",
		paddingVertical: 60,
		paddingHorizontal: 40,
		border: "1.4px solid #FFFFFF",
		borderRadius: 16,
		marginBottom: 48,
		zIndex: 1,
	},

	infoContainerMobile: {
		gap: 24,
		maxHeight: 336,
		marginBottom: 24,
		paddingHorizontal: 30,
	},
	infoContainerSmallMobile: {
		gap: 20,
		maxHeight: 336,
		marginBottom: 20,
		paddingHorizontal: 20,
	},

	firstInfo: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		gap: 24,
	},

	firstInfoMobile: {
		gap: 2,
	},

	inputForm: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		flexWrap: "wrap",
		paddingHorizontal: 14,
		maxHeight: 116,
		gap: 24,
	},

	inputFromContainer: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		maxWidth: "100%",
		flexWrap: "wrap",
		columnGap: 20,
		rowGap: 20,
		paddingLeft: 0,
		paddingRight: 0,
	},

	checkboxContainer: {
		minWidth: 635,
		maxWidth: 635,
		height: "auto",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "flex-start",
	},

	privacyPolicyConditions: {
		width: "100%",
		padding: 14,
		backgroundColor: "rgba(255, 255, 255, 0.5)",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		borderRadius: 4,
	},

	lastCheckboxContainer: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		textAlign: "left",
		padding: 14,
		gap: 8,
	},

	lastCheckbox: {
		width: "100%",
		minHeight: 68,
		display: "flex",
		gap: 8,
	},

	bottomContainer: {
		marginTop: 10,
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		alignSelf: "center",
		gap: 8,
	},

	// --------------- download button

	downloadBtn: {
		flex: 1,
		paddingVertical: 4,
		alignSelf: "stretch",
		backgroundColor: "rgba(216, 193, 174, 0.2)",
		display: "flex",
		height: 48,
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		minWidth: 290,
		gap: 15,
		borderRadius: 6,
		borderColor: "rgba(255,255,255, 0.3)",
		borderWidth: 2,
		boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.15)",
		backdropFilter: "blur(18px)",
	},

	download: {
		minWidth: "100%",
		backgroundColor: "#ffffff",
		borderColor: "#FFFAEC",
		boxShadow: "0px 0px 20px 0px rgba(255, 255, 255, 0.5)",
		borderRadius: 8,
	},
});
