import React, { useEffect, useState } from "react";
import { Dimensions, Platform, Text, TextStyle, View } from "react-native";
import { styles } from "./BvlgariTitle.style";
import { translate } from "../../utils/utils";

type PropsTextType =
    | "xlLight"
    | "xlLightUp"
    | "xlBold"
    | "xlBoldUp"
    | "l"
    | "lBold"
    | "mRegular"
    | "mBold"
    | "mBoldUnderline"
    | "mBoldUp"
    | "sRegular"
    | "sItalic"
    | "sBold";

interface Props {
    title: string;
    titleType?: PropsTextType;
    subtitle?: string;
    subtitleType?: PropsTextType;
    styleTitle?: React.CSSProperties;
    styleSubtitle?: React.CSSProperties;
}

const BvlgariTitle = ({
    title,
    titleType = "xlBoldUp",
    subtitle,
    subtitleType = "mRegular",
    styleTitle,
    styleSubtitle,
}: Props) => {
    const [mobileView, setMobileView] = useState<boolean>(
        Platform.OS === "android" || Platform.OS === "ios",
    );
    const [windowWidth, setWindowWidth] = useState<number>(
        Dimensions.get("window").width,
    );
    const titleStyle = styleTitle && (styleTitle as TextStyle);
    const subtitleStyle = styleSubtitle && (styleSubtitle as TextStyle);

    // controllo viewport width se non siamo nella mobile mode
    const controlViewport = () => {
        const { width } = Dimensions.get("window");
        setMobileView(width < 525); // 600 per dire che è mobile
        setWindowWidth(width);
    };

    // ----------------------- UseEffect -----------------------
    useEffect(() => {
        controlViewport();
        if (!mobileView) {
            Dimensions.addEventListener("change", controlViewport);
        }

        return () => {
            removeEventListener?.("change", controlViewport);
        };
    }, []);

    return (
        <View style={styles.titleContainer}>
            <Text
                style={[
                    styles[titleType],
                    titleStyle,
                    { fontSize: mobileView || windowWidth < 525 ? 26 : 34 },
                ]}
            >
                {translate(title ?? "")}
            </Text>
            {subtitle && (
                <Text
                    style={[
                        styles[subtitleType],
                        subtitleStyle,
                        { fontSize: mobileView || windowWidth < 525 ? 16 : 18 },
                    ]}
                >
                    {translate(subtitle ?? "")}
                </Text>
            )}
        </View>
    );
};

export default BvlgariTitle;
