import React, { useState, useEffect } from "react";
import { Dimensions, Pressable, Text, View } from "react-native";
import { styles } from "./Checkbox.styles";
import BvlgariText from "../TextElement/BvlgariText";
import { CheckboxData } from "../../data/dataInterfaces";
import SvgComponent from "../Svg/SvgComponent";
import { translate } from "../../utils/utils";

export interface ICheckboxProps {
    checkOpt: CheckboxData;
    id: string;
    onClick?: (isSelected: boolean) => void;
    onChange?: (id: string, value: any) => void;
    rtl: boolean;
    value?: boolean;
    highligted?: boolean;
    style?: any;
    isAccordion?: boolean;
}

export const Checkbox = ({
    checkOpt,
    onClick,
    rtl,
    value,
    onChange,
    id,
    highligted,
    style,
    isAccordion,
}: ICheckboxProps) => {
    const [windowWidth, setWindowWidth] = useState<number>(
        Dimensions.get("window").width,
    );
    const [selected, setSelected] = useState(value ?? false);
    const [isHovered, setIsHovered] = useState(false);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    const handlePressIn = () => setIsHovered(true);
    const handlePressOut = () => setIsHovered(false);

    const onPressCheckbox = () => {
        onClick?.(!selected);
        onChange && onChange(id, !selected);
        setSelected(!selected);
    };

    const checkStyle = [
        styles.check,
        selected
            ? styles.checkSelected
            : isHovered
            ? styles.checkHover
            : styles.unchecked,
    ];
    const highlightWrapper = (child: JSX.Element) => (
        <View style={styles.highlighted}>{child}</View>
    );

    useEffect(() => {
        setSelected(value ?? false);
    }, [value]);

    const checkComponent = () => (
        <View
            style={{ maxWidth: "100%" }}
            accessible={true}
            accessibilityLabel={
                "checkbox" + (checkOpt.isRequired ? "required" : "")
            }
        >
            <Pressable
                style={[
                    styles.container,
                    rtl && styles.containerReverse,
                    isAccordionOpen ? { maxHeight: 100 } : {},
                    style,
                    windowWidth < 600 ? { maxHeight: "100%" } : {},
                ]}
                onPress={onPressCheckbox}
                onHoverIn={handlePressIn}
                onHoverOut={handlePressOut}
                onPressIn={handlePressIn}
                onPressOut={handlePressOut}
            >
                <View
                    style={[
                        checkStyle,
                        isAccordionOpen
                            ? { alignSelf: "flex-start", marginTop: 6 }
                            : {},
                    ]}
                />
                <BvlgariText
                    text={checkOpt.text}
                    textType='sRegular'
                    style={{
                        ...(rtl ? styles.textRevert : styles.text,
                        { color: "#473213" }),
                        ...(windowWidth < 600 ? { textAlign: "start" } : {}),
                        ...(isAccordion ? { paddingRight: 16 } : {}),
                    }}
                    numOfLine={
                        isAccordion
                            ? isAccordionOpen
                                ? undefined
                                : 1
                            : undefined
                    }
                />
            </Pressable>
            {isAccordion && (
                <Pressable
                    accessibilityLabel='expand text'
                    style={{
                        width: 20,
                        height: 20,

                        position: "absolute",
                        right: 15,
                    }}
                    onPress={() => setIsAccordionOpen((prev) => !prev)}
                >
                    <SvgComponent type='arrow' />
                </Pressable>
            )}
        </View>
    );

    return highligted ? highlightWrapper(checkComponent()) : checkComponent();
};
