import { View, Text, Image, Platform, Dimensions } from "react-native";
import { styles } from "./StepFooter.styles";
import { IStepAction } from "../../data/dataInterfaces";
import { ActionButton } from "../ActionButton/ActionButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import BvlgariText from "../TextElement/BvlgariText";
import { useJourneyStore } from "../../store/JourneyStore";
import { AnimatePresence, MotiView } from "moti";
import { useStepManagerStore } from "../../store/ExperienceStore";
import { isIosApp, toggleAppReload } from "../../utils/utils";
import { ImageDataset } from "../../configuration/ImageDataset";

export interface StepFooterProps {
  actions?: IStepAction[];

  footerTextVisible: boolean;
}

export default function StepFooter(props: StepFooterProps) {
  const [mobileView, setMobileView] = useState<boolean>(
    Platform.OS === "android" || Platform.OS === "ios"
  );
  const [windowWidth, setWindowWidth] = useState<number>(
    Dimensions.get("window").width
  );
  const [windowHeight, setwindowHeight] = useState<number>(
    Dimensions.get("window").height
  );
  const [hideFooter, setHideFooter] = useState<any>(
    useJourneyStore.getState().hideFooter
  );
  const [hideActionButton, setHideActionButton] = useState<boolean>(
    useJourneyStore.getState().hideActionButton
  );
  const [exitAnimation, setExitAnimation] = useState(
    useJourneyStore.getState().doExitAnimation
  );

  const unsubExit = useJourneyStore.subscribe((state) => {
    state.doExitAnimation !== exitAnimation &&
      setExitAnimation(state.doExitAnimation);
  });

  useEffect(() => {
    return () => {
      unsubExit();
    };
  }, []);

  const { falseModal } = useJourneyStore((state: any) => ({
    falseModal: state.falseModal,
  }));

  const { currentStepIndex } = useStepManagerStore((state: any) => ({
    currentStepIndex: state.currentStepIndex,
  }));

  const unsub = useJourneyStore.subscribe((state) => {
    state.hideFooter !== hideFooter && setHideFooter(state.hideFooter);
  });

  const unsubHideActionButton = useJourneyStore.subscribe((state) => {
    // console.log('state.hideActionButton', state.hideActionButton)
    state.hideActionButton !== hideActionButton &&
      setHideActionButton(state.hideActionButton);
  });

  const { actions, footerTextVisible } = props;
  const footerTitleText = "Celebrating";
  const footerText = "Magnificent wonders";
  // ------ Render Bottom actions ------
  // let _actions: any[] = __DEV__ ? [...(actions ?? [])] : actions ?? []
  let _actions: any[] = useMemo(() => [...(actions ?? [])], [actions]);

  // controllo viewport width se non siamo nella mobile mode
  const controlViewport = () => {
    const { width } = Dimensions.get("window");
    setMobileView(width < 525); // 600 per dire che è mobile
    setWindowWidth(width);
  };

  const actionFooterScaleSmallBtn = (allIsSmallBtn: boolean) => {
    if (!isIosApp && allIsSmallBtn) {
      if (windowHeight > 500 && windowHeight < 800 && windowWidth > 1200) {
        return 0.6; // RISOLUZIONE ALICIA ACTION btn
      }
    } else return 1;
  };

  const actionFooterMarginNegative = (allIsSmallBtn: boolean) => {
    if (!isIosApp && allIsSmallBtn) {
      if (windowHeight > 500 && windowHeight < 800 && windowWidth > 1200) {
        return -20; // RISOLUZIONE ALICIA ACTION btn
      }
    } else return 0;
  };
  // larghezza footer
  const footerWidth = mobileView
    ? styles.mobile
    : windowWidth < 800
    ? styles.table
    : styles.desktop;

  const Actions = useCallback(() => {
    const allActionsIsSmall = _actions.every(
      (action) => action.size === "smallBtn"
    );
    return (
      <>
        <MotiView
          from={{ opacity: 0, zIndex: 0 }}
          key={`${hideActionButton.toString()}_actions`}
          animate={{
            opacity: 1,
            zIndex: 1,
            scale: actionFooterScaleSmallBtn(allActionsIsSmall),
            marginBottom: actionFooterMarginNegative(allActionsIsSmall),
          }}
          exit={(custom) => {
            "worklet";
            return {
              opacity: 0,
              zIndex: 0,
            };
          }}
          exitTransition={{ type: "timing", duration: 1500 }}
          transition={{ type: "timing", duration: 1500 }}
          style={[
            styles.actionsContainer,
            footerWidth,
            allActionsIsSmall && windowWidth < 760
              ? styles.allSmallBtnWrap
              : styles.allSmallBtnNoWrap,
            currentStepIndex === 6 && {
              width: windowWidth < 600 ? 528 : "auto",
              padding: windowWidth < 525 ? 14 : 24,
            },
            windowHeight < 1000 && { gap: 8 },
            windowWidth < 390 && { maxHeight: 100, gap: 8 },
          ]}
        >
          {_actions.map((action, index) => {
            return (
              <ActionButton
                key={index}
                action={action}
                currentStepIndex={currentStepIndex}
                is3Btn={_actions.length === 3}
              />
            );
          })}
        </MotiView>
      </>
    );
  }, [_actions]);

  useEffect(() => {
    controlViewport();
    if (!mobileView) {
      Dimensions.addEventListener("change", controlViewport);
    }

    return () => {
      removeEventListener?.("change", controlViewport);
      unsub();
      unsubHideActionButton();
    };
  }, []);

  // ----------- RELOAD APP MANAGEMENT -----------
  useEffect(() => {
    if (
      new URLSearchParams(window.location.search).get("device")?.slice(0, 3) ===
      "ios"
    )
      toggleAppReload(currentStepIndex);
  }, [currentStepIndex]);

  return (
    <>
      {!hideFooter ? (
        <MotiView
          from={{ opacity: 0 }}
          animate={{ opacity: exitAnimation ? 0 : 1 }}
          exit={() => {
            "worklet";
            return {
              opacity: 0,
            };
          }}
          exitTransition={{ type: "timing", duration: 1500 }}
          transition={{ type: "timing", duration: 1500 }}
          style={[
            styles.stepFooter,
            currentStepIndex === 11 && windowWidth > 400 && { gap: 6 },
          ]}
        >
          {(!falseModal || !hideActionButton) && <Actions />}
          {footerTextVisible && (
            <View style={styles.headerImageContainer}>
              <Image
                resizeMode="contain"
                source={ImageDataset.header}
                style={styles.headerLogo}
              />
            </View>
          )}
        </MotiView>
      ) : (
        <MotiView
          from={{ opacity: 1 }}
          animate={{ opacity: 0 }}
          transition={{ type: "timing", duration: 1500 }}
          style={styles.stepFooter}
        >
          {(!falseModal || !hideActionButton) && <Actions />}
          {footerTextVisible && (
            <View style={styles.headerImageContainer}>
              <Image
                resizeMode="contain"
                source={ImageDataset.header}
                style={styles.headerLogo}
              />
            </View>
          )}
        </MotiView>
      )}
    </>
  );
}
