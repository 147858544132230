import { View, Image, Button, Platform, Dimensions, Text } from "react-native";
import { styles } from "./Header.styles";
import { useEffect, useState } from "react";
import ReloadButton from "./ReloadButton/ReloadButton";
import LanguageSelect from "./LanguageSelect/LanguageSelect";
import { useStepManagerStore } from "../../store/ExperienceStore";
import { useJourneyStore } from "../../store/JourneyStore";
import { MotiView } from "moti";
import InfoBtn from "./infoBtn/InfoBtn";

export interface HeaderProps {}

const MIN_WIDTH_RESIZE = 540;
export default function Header(props: HeaderProps) {
  const [isMobile, setIsMobile] = useState<boolean>(
    Platform.OS === "android" || Platform.OS === "ios"
  );
  const [windowWidth, setWindowWidth] = useState<number>(
    Dimensions.get("window").width
  );
  const [hideHeader, setHideHeader] = useState<boolean>(
    useJourneyStore.getState().hideHeader
  );

  const { hideTitle, falseModal } = useJourneyStore((state) => {
    return { hideTitle: state.hideTitle, falseModal: state.falseModal };
  });

  const { currentStep, headerLeftItem, headerRightItem } = useStepManagerStore(
    (state) => {
      return {
        headerLeftItem: state.headerLeftItem,
        headerRightItem: state.headerRightItem,
        currentStep: state.currentStepIndex,
      };
    }
  );

  const showBackButton =
    currentStep === 6
      ? falseModal !== null &&
        falseModal !== "reloadModal" &&
        falseModal !== "reloadModalLongpress"
      : currentStep === 9
      ? falseModal !== null
      : currentStep > 1;

  const showReloadButton = currentStep >= 1;

  /*  const dontShowHeaderOnLoading = currentStep === 2 || currentStep === 10; */

  const unsub = useJourneyStore.subscribe((state) => {
    state.hideHeader !== hideHeader && setHideHeader(state.hideHeader);
  });

  // controllo viewport width se non siamo nella mobile mode
  const controlViewport = () => {
    const { width } = Dimensions.get("window");
    setIsMobile(width < 525); // 600 per dire che è mobile
    setWindowWidth(width);
  };

  // ----------------------- UseEffect -----------------------
  useEffect(() => {
    controlViewport();

    Dimensions.addEventListener("change", controlViewport);

    if (Platform.OS === "web") {
      //preload of video in https://bulgari-digital-craft.s3.eu-central-1.amazonaws.com/bulgari_videos/art-of-gifting-prod/video_pregen.mp4
      const video = document.createElement("video");
      video.src =
        "https://bulgari-digital-craft.s3.eu-central-1.amazonaws.com/bulgari_videos/pre-gen-app-d.mp4";
      video.preload = "auto";
      video.autoplay = true;
      video.muted = true;
      video.load();
      video.play();

      setTimeout(() => {
        //delete video
        video.remove();
      }, 30000);
    }

    return () => {
      removeEventListener?.("change", controlViewport);
      unsub();
    };
  }, []);

  return (
    <>
      {/******** se l'header non è nascosto - TBD */}
      {!hideHeader ? (
        <MotiView
          from={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ type: "timing", duration: 2000 }}
          style={[
            styles.headerContainer,
            isMobile || windowWidth < 525
              ? styles.mobile
              : windowWidth < 800 && styles.tablet,
          ]}
        >
          <>
            {/* ------------- reset and go back experience button ------------- */}
            {headerLeftItem && (
              <View
                style={[
                  windowWidth > MIN_WIDTH_RESIZE
                    ? styles.leftIcons
                    : styles.mobileLeftContainer,
                ]}
              >
                {showBackButton && (
                  <ReloadButton
                    type="go-back"
                    isMobile={windowWidth < MIN_WIDTH_RESIZE}
                  />
                )}
                {showReloadButton && (
                  <ReloadButton
                    type="reload"
                    isMobile={windowWidth < MIN_WIDTH_RESIZE}
                  />
                )}
              </View>
            )}

            {/* ----------------- language selection button ----------------- */}
            {headerRightItem && (
              <View
                style={[
                  windowWidth > MIN_WIDTH_RESIZE
                    ? styles.rightIcons
                    : styles.mobileRightContainer,
                ]}
              >
                <InfoBtn isMobile={windowWidth < MIN_WIDTH_RESIZE} />
                <LanguageSelect isMobile={windowWidth < MIN_WIDTH_RESIZE} />
              </View>
            )}
          </>
        </MotiView>
      ) : (
        <MotiView
          from={{ opacity: 1 }}
          animate={{ opacity: 0 }}
          transition={{ type: "timing", duration: 1500 }}
        >
          <View
            style={[
              styles.headerContainer,
              isMobile || windowWidth < 525
                ? styles.mobile
                : windowWidth < 800 && styles.tablet,
            ]}
          >
            {/* ----------------- reset experience button ----------------- */}
            {headerLeftItem && (
              <View
                id="1"
                style={[
                  windowWidth > MIN_WIDTH_RESIZE
                    ? styles.leftIcons
                    : styles.mobileLeftContainer,
                ]}
              >
                {showBackButton && <ReloadButton type="go-back" />}
                {showReloadButton && <ReloadButton type="reload" />}
              </View>
            )}
            {/* logo */}
            {/* {!hideTitle && (
              <View style={styles.headerImageContainer}>
                <Image
                  resizeMode="contain"
                  source={ImageDataset.header}
                  style={styles.headerLogo}
                />
              </View>
            )} */}
            {/* ----------------- language selection button ----------------- */}

            {headerRightItem && (
              <View
                style={[
                  windowWidth > MIN_WIDTH_RESIZE
                    ? styles.rightIcons
                    : styles.mobileRightContainer,
                ]}
              >
                <InfoBtn />
                <LanguageSelect />
              </View>
            )}
          </View>
        </MotiView>
      )}
    </>
  );
}
