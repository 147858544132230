import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingVertical: 4,
        alignSelf: "stretch",
        backgroundColor: "rgba(216, 193, 174, 0.2)",
        display: "flex",
        height: 48,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        minWidth: 290,
        gap: 15,
        borderRadius: 6,
        borderColor: "rgba(255,255,255, 0.3)",
        borderWidth: 2,
        boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(18px)",
    },
    default: {},
    outlined: {},
    backButton: {},
    filled: {
        backgroundColor: "#ffffff",
        borderColor: "#FFFAEC",
        boxShadow: "0px 0px 20px 0px rgba(255, 255, 255, 0.5)",
        borderRadius: 8,
    },
    gold: {
        width: "100%",
        minWidth: "100%",
        backgroundColor: "#DCA24A",
        boxShadow: "0px 0px 20px 0px #DCA24A",
        borderWidth: 0,
        borderColor: "transparent",
    },
    browned: {
        backgroundColor: "rgba(75, 50, 19, 0.45)",
    },
    downloadBrown: {
        backgroundColor: "#A4651E",
    },
    sharedWhite: {
        backgroundColor: "#FFFAEC",
    },
    bigBtn: { width: "100%", minWidth: "100%" },
    smallBtn: {
        width: 218,
        minWidth: 150,
        maxWidth: 218,
        height: 48,
    },

    icon: {
        width: 16,
        height: 16,
    },

    onlyText: {
        color: "#473213",
        textTransform: "uppercase",
        width: " 100%",
        textAlign: "center",
    },
});
