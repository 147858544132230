import { create } from "zustand";
import IStep, { BaseImage } from "../data/dataInterfaces";
import { useJourneyStore } from "./JourneyStore";
import { AvailableSteps } from "../configuration/configuration";
import { welcomePageData } from "../configuration/steps/welcomePageData";
import { setLanguage } from "../translations/translations";

export interface StepManager {
	selectedImages: BaseImage[];
	addSelectedImages: (image: BaseImage) => void;

	cannyImage?: string;
	setCannyImage: (image: string) => void;

	generatedImages: BaseImage[];
	populateGeneratedImages: (images: BaseImage[]) => void;
	currentStepIndex: number;
	setCurrentStepIndex: (index: number) => void;
	increaseStep: (params?: any) => void;
	decreaseStep: (params?: any, numStepBack?: number) => void;
	restart: (longPress: boolean, lang: string) => void;

	objectsSelected: string[];
	toggleObjectSelection: (
		object_id: string,
		allowMultipleSelection: boolean,
	) => void;
	moodSelected: string;
	selectMood: (mood: string) => void;

	finalVideoUrl?: string;
	finalVideoBase64?: string;
	finalVideoFilename?: string;
	setFinalVideoUrl: (url: string) => void;

	headerLeftItem: boolean;
	headerRightItem: boolean;

	setHeaderLeftItem: (value: boolean) => void;
	setHeaderRightItem: (value: boolean) => void;

	resetHeader: () => void;

	stepParams?: any;
	setStepParams: (params: any) => void;

	availableSteps: IStep[];
}

export const useStepManagerStore = create<StepManager>((set) => ({
	// Immagini selezionate per la creazione del video
	selectedImages: [],
	addSelectedImages: (image: BaseImage) =>
		set((state: any) => {
			if (state.selectedImages.includes(image)) {
				return {
					selectedImages: state.selectedImages.filter(
						(selectedImage: BaseImage) =>
							selectedImage.id !== image.id,
					),
				};
			} else {
				return {
					selectedImages: [...state.selectedImages, image],
				};
			}
		}),

	// Immagine canny
	cannyImage: undefined,
	setCannyImage: (image: string) => set(() => ({ cannyImage: image })),

	generatedImages: [], // immagini generate per la scelta della creazione del video
	populateGeneratedImages: (images: BaseImage[]) =>
		set(() => ({ generatedImages: images })),

	currentStepIndex: 0,
	setCurrentStepIndex: (index: number) =>
		set(() => ({ currentStepIndex: index })),

	increaseStep: (params = undefined) => {
		const { currentStepIndex } = useStepManagerStore.getState();

		if (currentStepIndex === 8) {
			const { setHideFooter, setHideHeader } = useJourneyStore.getState();
			setHideFooter(false);
			setHideHeader(false);
			return;
		}
		if (params === welcomePageData) {
			set((state: any) => ({ currentStepIndex: 1, stepParams: params }));
			return;
		}

		set((state: any) => ({
			currentStepIndex: state.currentStepIndex + 1,
			stepParams: params,
		}));
	},
	decreaseStep: (params = undefined, numStepBack = 1) =>
		set((state: any) => ({
			currentStepIndex: Math.max(0, state.currentStepIndex - numStepBack),
			stepParams: params,
		})),

	restart: (longPress: boolean = false, lang: string = "en") => {
		const { isIos } = useJourneyStore.getState();

		useJourneyStore.getState().clear();

		const currentStepIndex =
			useStepManagerStore.getState().currentStepIndex;
		if (longPress) {
			useJourneyStore.getState().setSelectedLocale("");
			useJourneyStore.getState().setSelectedStoreId("");
			useJourneyStore.getState().setSelectedStore("");
		}

		useJourneyStore.getState().setSelectedLanguage(lang);

		set((state) => ({
			currentStepIndex:
				isIos === null
					? 0
					: longPress || currentStepIndex === 1
					? 1
					: 0,
		}));
		/*  if (isIos === null)
            useJourneyStore.getState().hideHeaderFooter(true, true); */
	},
	objectsSelected: [],
	moodSelected: "",

	toggleObjectSelection: (
		object_id: string,
		allowMultipleSelection: boolean,
	) =>
		set((state: any) => {
			if (allowMultipleSelection) {
				if (state.objectsSelected.includes(object_id)) {
					return {
						objectsSelected: state.objectsSelected.filter(
							(object: string) => object !== object_id,
						),
					};
				} else {
					return {
						objectsSelected: [...state.objectsSelected, object_id],
					};
				}
			} else {
				return {
					objectsSelected: [object_id],
				};
			}
		}),
	selectMood: (mood: string) => set(() => ({ moodSelected: mood })),

	finalVideoUrl: undefined,
	setFinalVideoUrl: (url: string) => set(() => ({ finalVideoUrl: url })),

	setHeaderLeftItem: (value: boolean) =>
		set(() => ({ headerLeftItem: value })),
	setHeaderRightItem: (value: boolean) =>
		set(() => ({ headerRightItem: value })),

	headerLeftItem: true,
	headerRightItem: true,

	resetHeader: () =>
		set(() => ({ headerLeftItem: true, headerRightItem: true })),

	// Step params
	stepParams: undefined,
	setStepParams: (params: any) => set(() => ({ stepParams: params })),

	availableSteps: AvailableSteps(),
}));
