import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  successContainer: {
    maxWidth: 627,
    alignItems: 'center',
  },

  mobileSuccContainer: {
    maxWidth: 245,
    alignItems: 'center',
  },

  errorContainer: {
    maxWidth: 330,
    alignItems: 'center',
    justifyContent: 'center',
  },

  mobileErrContainer: {
    maxWidth: 330,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  buttonContainer: {
    width: 330,
    height: 48,
    marginTop: 36,
  },
})
