import { Dimensions, StyleSheet } from "react-native";

export const styles = StyleSheet.create({
	container: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		minHeight: Dimensions.get("window").height * 1.02,
	},

	containerDesktop: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		minHeight: Dimensions.get("window").height * 1.35,
	},

	containerTablet: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		minHeight: Dimensions.get("window").height * 1.15,
	},

	containerTabletWeb: {
		minHeight: Dimensions.get("window").height * 1.16,
	},

	containerMobile: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		minHeight: Dimensions.get("window").height * 1.35,
	},
	containerSmallMobile: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		minHeight: 950,
	},

	charmsInformation: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		maxWidth: 604,
		width: "100%",
		maxHeight: 190,
		height: "100%",
		backgroundColor: "rgba(255, 255, 255, 0.20)",
		borderRadius: 8,
		top: 40,
		zIndex: 10,
	},

	headerContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	},

	headerContainerTablet: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	},
	actionButton: {
		maxWidth: 430,
		width: "100%",
		height: 48,
		zIndex: 10,
	},

	textContainer: {
		position: "absolute",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		height: "100%",
	},
});
