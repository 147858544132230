import { useEffect, useRef, useState } from "react";
import { View } from "react-native";

import { styles } from "./VideoGenerationLoading.styles";
import { MotiView } from "moti";
import BvlgariTitle from "../TitleElement/BvlgariTitle";
import { Video, Audio } from "expo-av";
import { useJourneyStore } from "../../store/JourneyStore";
import { IMoodType } from "../../data/dataInterfaces";
import { requestGeneratedVideo } from "../../api/VideoGenerationServices";

export interface IVideoGenerationLoadingProps {
  config: any;
}

export function VideoGenerationLoading({
  config,
}: IVideoGenerationLoadingProps) {
  const videoRef = useRef<Video>(null);
  const [status, setStatus] = useState({});
  const [mood, setMood] = useState<string | undefined>(
    useJourneyStore.getState().mood
  );

  const unsub = useJourneyStore.subscribe((state) => {
    state.mood !== mood && setMood(state.mood);
  });

  const preLoadVideo = () => {
    // Assumi che il video si trovi nella cartella assets
    const soundObject = new Audio.Sound();
    const playAsync = async () => {
      try {
        /* console.log("Sto per iniziare preload"); */
        await soundObject.loadAsync(
          useJourneyStore.getState().urlVideoGenerated,
          {},
          false
        );
        /* console.log("Pre load"); */
      } catch (error) {
        console.error("Errore nel caricamento del video", error);
      }
    };

    const onPlaybackStatusUpdate = (status: any) => {
      if (status.isLoaded && status.didJustFinish) {
        // Il video ha finito di precaricare
        /*  console.log("Il video ha finito di precaricare"); */
      }
    };

    soundObject.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate);
    playAsync();

    return () => {
      // Rilascia le risorse audio quando il componente viene smontato
      soundObject.unloadAsync();
    };
  };

  useEffect(() => {
    requestGeneratedVideo().then(() => {
      preLoadVideo();
    });
  }, []);

  // console.log(status, status)
  return (
    <MotiView
      from={{ opacity: 0, scale: 1.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      exitTransition={{ type: "timing", duration: 1500 }}
      transition={{ type: "timing", duration: 1500 }}
    >
      <View style={styles.container}></View>
    </MotiView>
  );
}
