import axios from "axios";
import Constants from "expo-constants";

export interface IRegistrationData {
    Title: string;
    FirstName?: string;
    LastName: string;
    CountryRegion: string;
    PersonEmail: string;

    MarketingAuthorization: boolean;
    Shop: string;
    ShopName: string;
    Source: string;
    Subsource: string;
    DataAcquisitionType: string;
    Locale: string;
    FirstNamePronunciation: string;
    LastNamePronunciation: string;
    Prefix: string;
    PersonMobilePhone: string;
    Interest: string;
    ProfileAuthorization: boolean;
}

export interface ILoginData {
    email: string;
    firstName: string;
    lastName: string;
}

export interface ISendEmailData {
    urlArtwork: string;
    SendToMyself: boolean;
    LanguageCode: string;
    mailingList: IMailList[];
}

export interface ISendEmailDataOffline {
    trackId: string;
    anyPoint: {
        id: string;
        urlArtwork: string;
        SendToMyself: boolean;
        LanguageCode: string;
        definitionKey: string;
        DynamicText: string;
        imageLink: string;
        mailingList: IMailList[];
    };
}

export interface IMailList {
    email: string;
    DynamicText: string;
    ArtworkToMyFriends: boolean;
    JoinTheExperience: boolean;
}

export interface ICriptoTrack {
    readerId: string;
    salesForceId: string;
}

export interface UserJourneyData extends IRegistrationData {}

const appInProd = Constants?.manifest?.extra?.APP_PROD;

/* --- AUTH --- */
const baseUrlProd =
    "https://muleapi.bservices.tech/bdg/mirror/v1/api/artofgifting";
const clientIdProd = "1e11974549424c609ae653b74aa8623d";
const clientSecretProd = "b7D87cB206aa4647B830b0A09785aF13";

const baseUrlQuality =
    "https://muleapi-qta.bservices.tech/bdg-qta/mirror/v1/api/artofgifting";
const clientIdQuality = "fc20b0ea076a4922b3f7af8ce7b79955";
const clientSecretQuality = "B2d6f465065440628B9A5f70A28a05a2";

/* --- TRACK ID --- */
const baseUrlTrackIdQuality =
    "https://84831704-dc67-4d0e-a2a4-5548250ae8b8.b4next.it/api";
const baseUrlTrackIdProd =
    "https://7d4bdecc-3014-46a5-9b0f-e6d6d1d65633.b4next.it/api/";

/* --- SEND EMAIL OFFLINE --- */
const baseUrlSendEmailOfflineQuality =
    "https://84831704-dc67-4d0e-a2a4-5548250ae8b8.b4next.it/api";
const baseUrlSendEmailOfflineProd =
    "https://7d4bdecc-3014-46a5-9b0f-e6d6d1d65633.b4next.it/api/";

/* --- SEND EMAIL ONLINE --- */
const baseUrlSendEmailProd =
    "https://anypoint.mulesoft.com/mocking/api/v1/links/cf6d7aac-917e-4a1c-bdc3-09a66a6e0f79";
const clientIdSendEmailProd = "0af597ddf85b48f386509af50afa6966";
const clientSecretSendEmailProd = "4260afb9e073494b999650eA72f02930";

const baseUrlSendEmailQuality =
    "https://muleapi-qta.bservices.tech/bdg-qta/mirror/v1/api/artofgifting";
const clientIdSendEmailQuality = "0af597ddf85b48f386509af50afa6966";
const clientSecretSendEmailQuality = "4260afb9e073494b999650eA72f02930";

// ========= Authentications =========
const instance = axios.create({
    baseURL: appInProd ? baseUrlProd : baseUrlQuality, // da cambiare con url corretto
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client_id: appInProd ? clientIdProd : clientIdQuality,
        client_secret: appInProd ? clientSecretProd : clientSecretQuality,
    },
});

export const callLogin = async (data: UserJourneyData) => {
    return new Promise(async (resolve, reject) => {
        const queryParams = data.FirstName
            ? `/accountInfo?email=${data.PersonEmail}&LastName=${data.LastName}&FirstName=${data.FirstName}&DataAcquisitionType=AOG&SendBulgariId=true`
            : `/accountInfo?email=${data.PersonEmail}&LastName=${data.LastName}`;
        try {
            const response = await instance.get(queryParams, {});
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const callRegister = async (data: UserJourneyData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await instance.post("/accountInfo", { ...data });
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

// ========= GET TRACKID =========
const instanceTrackId = axios.create({
    // baseUrlProd:  "https://7d4bdecc-3014-46a5-9b0f-e6d6d1d65633.b4next.it/api",

    baseURL: appInProd ? baseUrlTrackIdProd : baseUrlTrackIdQuality, // da cambiare con url corretto
    headers: {
        "x-functions-key":
            "cnjgFIhTXeABRORd9LPmu4QqPlLfDTFZTHWi1cCCv_k1AzFuxUIj0A==",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
});

export const callTrackId = async (data: ICriptoTrack) => {
    return new Promise(async (resolve, reject) => {
        //console.log('data per trackId', data)
        try {
            const response = await instanceTrackId.get(
                `/CheckIn?Id=${data.salesForceId}&readerId=${data.readerId}`,
            );
            //console.log('response', response)
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

// ========= SEND MULTI EMAIL OFFLINE =========
const instanceSendEmailOffline = axios.create({
    baseURL: appInProd
        ? baseUrlSendEmailOfflineProd
        : baseUrlSendEmailOfflineQuality, // da cambiare con url corretto
    headers: {
        "x-functions-key":
            "cnjgFIhTXeABRORd9LPmu4QqPlLfDTFZTHWi1cCCv_k1AzFuxUIj0A==",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
});

export const callSendEmailOffline = async (data: ISendEmailDataOffline) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await instanceSendEmailOffline.post(`/CheckOut`, {
                ...data,
            });
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

// ========= SEND MULTI EMAIL =========
export const callSendEmail = async (jwt: string, data: ISendEmailData) => {
    /*  const mockJwt = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb3Ntb0lEIjoiMDAxN1kwMDAwMjJqemlPUUFRIiwibG9jYWxlIjoiaXRfSVQifQ.VXCdW7Gbcx6tu72mHhe1mkjguUUfHH0QeDnLGAAh1h8` */

    const instanceEmail = axios.create({
        baseURL: appInProd ? baseUrlSendEmailProd : baseUrlSendEmailQuality,
        headers: {
            jwt: jwt,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            client_id: appInProd
                ? clientIdSendEmailProd
                : clientIdSendEmailQuality,
            client_secret: appInProd
                ? clientSecretSendEmailProd
                : clientSecretSendEmailQuality,
        },
    });

    return new Promise(async (resolve, reject) => {
        try {
            const response = await instanceEmail.post(
                "/sendCommunicationOnline",
                { ...data },
            );

            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};
