import React from 'react'
import { View } from 'react-native'
import { styles } from './Tutorial.styles'
import BvlgariText from '../TextElement/BvlgariText'

interface Props {
  step: string
}
const TutorialChip = ({ step }: Props) => {
  return (
    <View style={styles.chip}>
      <BvlgariText text={step} textType="xlLight" style={{ color: '#B0882F', fontSize: 18 }} />
    </View>
  )
}

export default TutorialChip
