import { MotiView, View as MView } from "moti";
import React, { useEffect, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling";
import { styles } from "./Download.style";

import { IStepAction } from "../../data/dataInterfaces";
import { ActionButton } from "../ActionButton/ActionButton";
import BvlgariText from "../TextElement/BvlgariText";
import {
	Dimensions,
	Platform,
	Pressable,
	ScrollView,
	View,
} from "react-native";
import { useJourneyStore } from "../../store/JourneyStore";
import { Checkbox } from "../Checkbox/Checkbox";
import InputElement from "../Form/inputElement/InputElement";
import SelectElement from "../Select/SelectElement";
import { Identifiers } from "../../utils/Indentifiers";
import { ImageDataset } from "../../configuration/ImageDataset";
import ReCAPTCHA from "react-google-recaptcha";
import { TouchableOpacity } from "react-native-gesture-handler";
import { shareUrl } from "../../utils/utils";

interface IDownloadProps {
	config: any;
}
const Download = ({ config }: IDownloadProps) => {
	const appInLocal = window?.location?.href.includes("localhost");

	/* ---------------- PROPS ---------------- */
	const { config: configuration, actions } = config;
	const { firstInfo, privacyPolicyConditions, lastCheckbox } = configuration;
	const _actions = actions as IStepAction[];

	/* ---------------- ZUSTAND ---------------- */
	const isIos = useJourneyStore.getState().isIos;
	const userCanScanQR = useJourneyStore((state) => state.userCanScanQR);
	const urlVideoGenerated = useJourneyStore(
		(state) => state.urlVideoGenerated,
	);

	/* ---------------- STATE ---------------- */
	const [windowHeight] = useState<number>(Dimensions.get("window").height);
	const [mobileView] = useState<boolean>(
		Platform.OS === "android" || Platform.OS === "ios",
	);
	const [windowWidth] = useState<number>(Dimensions.get("window").width);

	/* ------------ FOR RECAPCHA ------------- */
	const recaptchaRef = useRef<ReCAPTCHA>(null);
	const [sendEnabled, setSendEnabled] = useState<boolean>(false);
	function onChange(value: any) {
		if (value) {
			setSendEnabled(true);
		}
	}

	/* ---------------- QR CODE  ---------------- */
	const qrCodeContainerRef = useRef<any>(null);

	const getCorrectUrl = (myUrl: string) => {
		const fileName = new URL(myUrl).pathname.split("/").pop();

		return (
			window.location.href.split("?")[0] +
			"?download=" +
			fileName?.split(".")[0]
		);
	};

	const qrCode = new QRCodeStyling({
		width: 287,
		height: 287,
		margin: 20,
		type: "svg",
		data: getCorrectUrl(urlVideoGenerated!),
		/*  data: urlVideoGenerated, */
		/* data: 'https://bvlgari/' + config.batch, */
		image: ImageDataset.logo_b,
		dotsOptions: {
			color: "#fff",
			type: "rounded",
		},
		backgroundOptions: {
			color: "#000",
		},
		imageOptions: {
			margin: 10,
		},
	});

	/* ---------------- METHODS ---------------- */
	const downloadVideo = () => {
		shareUrl(urlVideoGenerated!, "_self");
		/* 		// URL del video da scaricare
		const videoUrl = urlVideoGenerated!;

		// Crea un elemento <a> con l'URL del video e l'attributo download
		const link = document.createElement("a");
		link.href = videoUrl;
		link.download = "Art_of_Gifting_bulvari.mp4";

		// Simula il click sull'elemento <a> per avviare il download
		link.click(); */
	};

	// ----------------------- Invio dati allo store ( ZUSTAND )
	const onFormChange = (id: string, value: any /* isValid?: boolean */) => {
		/*    console.log("id", id, "value", value); */

		// ------- update userSendSelfEmail checkbox
		if (id === "sendToMySelf") {
			useJourneyStore.getState().toggleUserSendSelfEmail(value);
			return;
		}

		// ------- send email to user
		if (id === Identifiers.FIELD_USER_PERSON_SEND_EMAIL) {
			// console.log('authorization privacy', value)
			useJourneyStore.getState().setEmailToSend(value);
			return;
		}

		// ------- update per il l'autorizzazione della privacy
		if (id === Identifiers.FIELD_USER_PRIVACY_AUTHORIZATION) {
			// console.log('authorization privacy', value)
			useJourneyStore.getState().setUserPrivacyAuthorization(value);
			return;
		}

		// ------- update country region
		if (id === Identifiers.FIELD_USER_COUNTRY_REGION) {
			const options = configuration.firstInfo.country.select.options;

			const option = options.find((c: any) => c.title === value);

			useJourneyStore.getState().updateUserDataField(id, option!.id);
			return;
		}

		useJourneyStore.getState().updateUserDataField(id, value);
	};

	/* ---------------- ACTIONS ---------------- */
	const Actions = () => {
		const allActionsIsSmall = _actions.every(
			(action) => action.size === "smallBtn",
		);
		return (
			<View
				style={[
					styles.actionsContainer,
					allActionsIsSmall && styles.allSmallBtn,
				]}
			>
				{_actions.map((action, index) => {
					return (
						<ActionButton
							key={index}
							action={action}
							isDisabled={!sendEnabled}
						/>
					);
				})}
			</View>
		);
	};

	useEffect(() => {
		if (qrCodeContainerRef.current && !isIos) {
			qrCode.append(qrCodeContainerRef.current);
		}
	}, []);

	useEffect(() => {
		if (!sendEnabled) {
			recaptchaRef?.current?.execute();
		}
	});

	useEffect(() => {
		if (userCanScanQR) setTimeout(() => downloadVideo(), 1500);
	}, [userCanScanQR]);

	/* 	qrCode.download({ name: "qr", extension: "svg" }); */

	return userCanScanQR ? (
		<MotiView
			from={{
				opacity: 0,
			}}
			animate={{
				opacity: 1,
			}}
			transition={{ type: "timing", duration: 800 }}
			style={[styles.absoluteContainer, { paddingTop: 130 }]}
		>
			<MView
				style={styles.content}
				from={{
					opacity: 0,
					scale: 0.8,
				}}
				animate={{
					opacity: 1,
					scale: 0.8,
				}}
			>
				{/* ------------ TITLE ------------ */}
				<BvlgariText
					text={
						!isIos
							? configuration.qrTitle
							: configuration.downloadTitle
					}
					textType='xlLightUp'
					style={{
						color: "#473213",
						fontSize: 34,
						fontWeight: "300",
						lineHeight: 48,
						letterSpacing: 5,
						...(windowWidth < 600
							? {
									fontSize: 12,
							  }
							: {}),
					}}
				/>
				{/* ------------ QR CODE or download ------------ */}
				{!isIos ? (
					<View ref={qrCodeContainerRef} />
				) : (
					<View>
						<TouchableOpacity
							accessibilityLabel={"download button"}
							style={[
								styles.downloadBtn,
								styles["download"],

								{
									opacity: 1,
									width: 360,
									minHeight: 56,
								},
							]}
							onPress={downloadVideo}
						>
							<BvlgariText
								text={"download"}
								textType='mBRegular'
								style={{
									color: "#A4651E",
									textTransform: "uppercase",
								}}
							/>
						</TouchableOpacity>
					</View>
				)}
			</MView>
		</MotiView>
	) : (
		<>
			<MotiView
				from={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={() => {
					"worklet";
					return {
						opacity: 0,
					};
				}}
				exitTransition={{ type: "timing", duration: 1500 }}
				transition={{ type: "timing", duration: 1500 }}
				style={styles.absoluteContainer}
			>
				<MView
					from={{
						opacity: 0,
						scale: 0.8,
					}}
					animate={{
						opacity: 1,
						scale: 0.8,
					}}
					transition={{
						type: "timing",
						duration: 800,
					}}
					style={[
						styles.content,
						windowWidth < 400
							? styles.contentSmallMobile
							: windowWidth < 600
							? styles.contentMobile
							: isIos && windowWidth > 520 && windowWidth < 1280
							? styles.contentTabletWeb
							: windowWidth > 1280
							? styles.contentDesktop
							: {},
					]}
				>
					{/* ------------ TITLE ------------ */}
					<BvlgariText
						text={configuration.title}
						textType='xlLightUp'
						style={{
							color: "#473213",
							fontSize:
								windowWidth < 390
									? 22
									: windowWidth < 600
									? 26
									: 34,
							fontWeight: "300",
							lineHeight: 48,
							letterSpacing: 5,
						}}
					/>

					{/* ------------ SCROLL FORM CONTAINER ------------ */}
					<ScrollView
						style={[
							styles.scrollContainer,
							mobileView &&
								windowWidth < 600 &&
								styles.scrollMobile,
							windowHeight < 900
								? windowWidth > 1600
									? styles.scrollDesktop
									: windowWidth > 600 && windowWidth < 1600
									? styles.scrollDesktopSmall
									: styles.scrollMobile
								: {},
							,
						]}
						showsVerticalScrollIndicator={false}
					>
						{/* ----------- INFO CONTAINER (FORM) ----------- */}
						<View
							style={[
								styles.infoContainer,
								windowWidth < 390
									? styles.infoContainerSmallMobile
									: windowWidth < 600
									? styles.infoContainerMobile
									: {},
							]}
						>
							<View
								style={[
									styles.firstInfo,
									windowWidth < 600
										? styles.firstInfoMobile
										: {},
								]}
							>
								{/* ----------- TITLE ----------- */}
								<BvlgariText
									text={configuration.firstInfo.title}
									textType='xlBoldUp'
									style={{
										fontWeight: "700",
										fontSize: 28,
										color: "#473213",
										textAlign: "start",
									}}
								/>
								{/* ----------- INPUT & SELECT CONTAINER ----------- */}
								<View
									style={[
										styles.inputForm,
										windowWidth < 600
											? { maxHeight: 350 }
											: {},
									]}
								>
									<View
										style={[
											styles.inputFromContainer,
											windowWidth < 600
												? { rowGap: 16, columnGap: 16 }
												: {},
										]}
									>
										<InputElement
											{...firstInfo.input[0]}
											onChange={onFormChange}
											isRequired={
												firstInfo.input[0].isRequired
											}
											styleCustom={
												windowWidth < 600
													? {
															minWidth: "100%",
															maxWidth: "100%",
															maxHeight: 42,
													  }
													: undefined
											}
										/>
										<InputElement
											{...firstInfo.input[1]}
											onChange={onFormChange}
											isRequired={
												firstInfo.input[1].isRequired
											}
											styleCustom={
												windowWidth < 600
													? {
															minWidth: "100%",
															maxWidth: "100%",
															maxHeight: 42,
													  }
													: undefined
											}
										/>
										<InputElement
											{...firstInfo.input[2]}
											onChange={onFormChange}
											isRequired={
												firstInfo.input[2].isRequired
											}
											styleCustom={
												windowWidth < 600
													? {
															minWidth: "100%",
															maxWidth: "100%",
															maxHeight: 42,
													  }
													: undefined
											}
										/>
										<SelectElement
											{...firstInfo.country}
											onChange={onFormChange}
											styleCustom={
												windowWidth < 600
													? {
															minWidth: "100%",
															maxWidth: "100%",
															maxHeight: 42,
													  }
													: undefined
											}
										/>
									</View>
								</View>
							</View>
						</View>

						{/* -----------  CHECKBOX ----------- */}
						<View
							style={[
								styles.checkboxContainer,
								windowWidth < 400
									? {
											minWidth: 0,
											maxWidth: 635,
											paddingHorizontal: 6,
									  }
									: windowWidth < 600
									? {
											minWidth: 0,
											maxWidth: 635,
											paddingHorizontal: 10,
									  }
									: {},
								,
							]}
						>
							{/* ----------- CHECKBOX PRIVACY POLICY CONDITIONS ----------- */}
							<View
								style={[
									styles.privacyPolicyConditions,
									styles.privacyPolicyConditions,
									windowWidth < 600
										? {
												paddingVertical: 10,
												paddingHorizontal: 10,
										  }
										: {},
								]}
							>
								<Checkbox
									checkOpt={privacyPolicyConditions}
									id={privacyPolicyConditions.id}
									rtl={false}
									onChange={onFormChange}
								/>
							</View>

							{/* ----------- SECOND CHECKBOX ----------- */}
							<View
								style={[
									styles.lastCheckboxContainer,
									windowWidth < 600
										? {
												paddingHorizontal: 10,
												paddingVertical: 14,
										  }
										: {},
								]}
							>
								<BvlgariText
									text={lastCheckbox.title}
									textType='sItalic'
									style={{
										color: "#473213",
										fontSize: 14,
										fontWeight: "700",
										lineHeight: 19.46,
										textAlign: "justify",
										paddingLeft: 8,
										paddingRight: 8,
									}}
								/>
								<View style={styles.lastCheckbox}>
									<Checkbox
										checkOpt={lastCheckbox.dataProcessing}
										id={lastCheckbox.dataProcessing.id}
										rtl={false}
										onChange={onFormChange}
										isAccordion={true}
									/>
									<Checkbox
										checkOpt={lastCheckbox.marketing}
										id={lastCheckbox.marketing.id}
										rtl={false}
										onChange={onFormChange}
										isAccordion={true}
									/>
								</View>
							</View>
						</View>

						{/* ---------------------------- Action container */}
						<View style={[styles.bottomContainer]}>
							{/* ---------------------------- Action button */}
							<Pressable
								style={[{ minWidth: "100%" }]}
								onPress={() => recaptchaRef?.current?.execute()}
							>
								{Actions()}
							</Pressable>
						</View>
					</ScrollView>
				</MView>
			</MotiView>
			{/* ------------- RECATPCHA ---------- */}
			<View
				style={{
					alignContent: "center",
					width: "100%",
					alignItems: "center",
					zIndex: 9999999999,
				}}
			>
				<ReCAPTCHA
					ref={recaptchaRef}
					sitekey='6LcTq4EpAAAAAD-TQqUko3dvHhiIbKZ-ioePOM9o'
					size='invisible' //invisible, normal, compact
					onChange={onChange}
					badge='none'
				/>
			</View>
		</>
	);
};

export default Download;
