import { LoadingWeb } from './Loading.web'
import { useJourneyStore } from '../../store/JourneyStore'
import { LoadingNative } from './Loading.ios'

interface Props {
  lottie: any
}
const loader = ({ lottie }: Props) => {
  return <LoadingWeb lottie={lottie} />
}

export default loader
