import { View, Platform, Dimensions } from "react-native";
import React, { useEffect, useState } from "react";
import { AnimatePresence, MotiView } from "moti";
import BvlgariText from "../TextElement/BvlgariText";
import StepBar from "../StepBar/StepBar";
import { useStepManagerStore } from "../../store/ExperienceStore";
import { ActionButton } from "../ActionButton/ActionButton";
import { styles } from "./CharmSelector.styles";
import { IStepAction } from "../../data/dataInterfaces";
import { useJourneyStore } from "../../store/JourneyStore";
import { translate } from "../../utils/utils";

export interface ICharmType {
    x: number;
    y: number;
    width: number;
    height: number;
    id: string;
    title: string;
    zIndex?: number;
    rotate?: number;
    glowImage?: any;
    outlineImage?: any;
}
export interface ICharmSelectorConfig {
    step: number;
    title: string;
    charmsToBeSelected: number;
    charmSelectedText: string;
    charmsObject: ICharmType[];
    action: IStepAction;
}
interface CharmSelectorProps {
    config: ICharmSelectorConfig;
}

export default function CharmSelector({ config }: CharmSelectorProps) {
    const [windowWidth] = useState<number>(Dimensions.get("window").width);

    const [currentStep, setCurrentStep] = useState<number>(
        useStepManagerStore.getState().currentStepIndex,
    );
    const [charmSelected, setCharmSelected] = useState<
        ICharmType[] | undefined
    >(useJourneyStore.getState().selectedCharms ?? []);
    const [action, setAction] = useState<IStepAction>(config.action);

    /* --------------- ZUSTAND --------------- */
    const isIos = useJourneyStore.getState().isIos;

    /* --------------- ZUSTAND UNSUB --------------- */
    const unsubCurrentStep = useStepManagerStore.subscribe((state) => {
        state.currentStepIndex !== currentStep &&
            setCurrentStep(state.currentStepIndex);
    });

    const unsubSelectedCharm = useJourneyStore.subscribe((state) => {
        state.selectedCharms !== charmSelected &&
            setCharmSelected(state.selectedCharms);
    });

    useEffect(() => {
        setAction({ ...action, disabled: true });

        return () => {
            unsubCurrentStep();
            unsubSelectedCharm();
        };
    }, []);

    useEffect(() => {
        charmSelected?.length === config.charmsToBeSelected &&
            setAction({ ...action, disabled: false });
    }, [charmSelected]);

    return (
        <AnimatePresence exitBeforeEnter>
            <MotiView
                from={{ opacity: 0 }}
                key={`${currentStep}_charmSelector`}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                exitTransition={{ type: "timing", duration: 1500 }}
                style={
                    windowWidth < 390
                        ? styles.containerSmallMobile
                        : windowWidth < 520
                        ? styles.containerMobile
                        : isIos && windowWidth > 520 && windowWidth < 1280
                        ? styles.containerTabletWeb
                        : windowWidth > 520 && windowWidth < 1280
                        ? styles.containerTablet
                        : windowWidth > 1280
                        ? styles.containerDesktop
                        : styles.container
                }
                transition={{ type: "timing", duration: 1500 }}
            >
                {/* ------------ HEADER ------------ */}
                <View
                    style={
                        innerWidth > 512 && innerWidth < 900
                            ? styles.headerContainerTablet
                            : styles.headerContainer
                    }
                >
                    <View>
                        <BvlgariText
                            text={`$step${config.step}`}
                            textType='xlLight'
                            style={{
                                color: "#473213",
                                fontSize: 20,
                                fontWeight: "300",
                                marginBottom: 4,
                                letterSpacing: 3,
                            }}
                        />
                        <StepBar totalSteps={3} activeStep={config.step} />
                    </View>
                    <BvlgariText
                        text={config.title}
                        textType='xlLight'
                        style={{
                            color: "#473213",
                            fontSize: windowWidth < 525 ? 30 : 36,
                            marginTop: 18,
                        }}
                    />
                </View>

                {/* ------------ CHARMS BUTTON ------------ */}
                <View
                    style={{
                        display: "flex",
                        width: 512,
                        gap: 24,
                        flexDirection: "row",
                        flexWrap: "wrap",
                        flex: 1,
                    }}
                />

                {/* ------------ CHARMS INFORMATION & ACTION ------------ */}
                <View
                    style={[
                        styles.charmsInformation,
                        windowWidth < 600 ? { maxHeight: 161 } : {},
                    ]}
                >
                    {/* ------------ INFO TAP TO SELECT ------------ */}
                    <BvlgariText
                        text={`$charmSelectionPage.tapToSelect`}
                        textType='mRegular'
                        style={{
                            textShadow: "0px 0px 16px #fffff",
                            fontWeight: "100 !important",
                            marginBottom: 40,
                            fontSize: 18,
                            letterSpacing: "3px",
                            color: "rgba(71, 50, 19, 7)",
                            textTransform: "uppercase",
                            marginTop: -80,
                        }}
                    />
                    {/* ------------ INFO CHARMS SELECTED NUMBER ------------ */}
                    <AnimatePresence>
                        <MotiView
                            from={{ opacity: 0, zIndex: 0 }}
                            animate={{ opacity: 1, zIndex: 1 }}
                            exit={(custom) => {
                                "worklet";
                                return {
                                    opacity: 0,
                                    zIndex: 0,
                                };
                            }}
                            style={styles.textContainer}
                            exitTransition={{ type: "timing", duration: 300 }}
                            transition={{ type: "timing", duration: 300 }}
                            key={`${charmSelected?.length}_info`}
                        >
                            {/* ------------ INFO NUMBER------------ */}
                            <View
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: 2,
                                }}
                            >
                                {/* ------------ INFO SELECTED CHARM NUMBER ------------ */}
                                <BvlgariText
                                    text={`${charmSelected?.length ?? 0}`}
                                    textType='mRegular'
                                    style={{
                                        marginBottom: 38,
                                        textShadow: "#fffff 0px 0px 16px",
                                        fontWeight: "100 !important",
                                        fontSize: 16,
                                        color: "#473213",
                                        textTransform: "uppercase",
                                    }}
                                />
                                {/* ------------ INFO MAX CHARM NUMBER ------------ */}
                                <BvlgariText
                                    text={`/${
                                        config.charmsToBeSelected
                                    } ${translate(config.charmSelectedText)}`}
                                    textType='mRegularNoShadow'
                                    style={{
                                        textShadow: "#fffff 0px 0px 16px",
                                        fontWeight: "100 !important",
                                        marginBottom: 38,
                                        letterSpacing: "3px",
                                        fontSize: 16,
                                        color: "#473213",
                                        textTransform: "uppercase",
                                    }}
                                />
                            </View>
                        </MotiView>
                    </AnimatePresence>
                    {/* ------------ ACTION ------------ */}
                    <View
                        style={[
                            styles.actionButton,
                            { marginBottom: -60 },
                            windowWidth < 600 ? { marginBottom: -75 } : {},
                        ]}
                    >
                        <ActionButton action={config.action} />
                    </View>
                </View>
            </MotiView>
        </AnimatePresence>
    );
}
