import { View, Image } from 'react-native'
import { styles } from './StepHeader.styles'
import { Title } from '../Title/Title'

export interface StepHeaderProps {
  title?: string
  titleImage?: any
}

export default function StepHeader(props: StepHeaderProps) {
  const { title, titleImage } = props

  const renderTopElements = () => {
    return title ? <Title title={title ?? ''} /> : <Image source={titleImage} style={styles.stepHeaderImage} />
  }

  return <View style={[styles.stepHeader, !title && { maxHeight: 0 }]}>{renderTopElements()}</View>
}
