import { useEffect, useMemo, useRef, useState } from "react";
import { useStepManagerStore } from "../store/ExperienceStore";
import { ResizeMode, Video } from "expo-av";
import { ImageDataset } from "../configuration/ImageDataset";
import { OperationType } from "../data/dataInterfaces";
import { AnimatePresence, MotiImage, MotiView } from "moti";
import {
  Dimensions,
  Platform,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { styles } from "./MainScreen.style";
import Step from "../components/Step/Step";
import Header from "../components/Header/Header";
import { useJourneyStore } from "../store/JourneyStore";
import { LinearGradient } from "expo-linear-gradient";
import { Identifiers } from "../utils/Indentifiers";
import CharmPointers from "../components/CharmSelector/CharmPointers";
import ScreenSaver from "../components/ScreenSaver/ScreenSaver";
import LottieComponent from "../components/LottieComponent/LottieComponent";
import { Lottie } from "../../assets/lottie";
import { ICharmType } from "../components/CharmSelector/CharmSelector";
import { charms } from "../../assets/singlecharms";
import SoundPlayer from "../utils/SoundManager";
import MoodCarousel from "../components/MoodSelector/MoodCarousel";
import { PanGestureHandler, State } from "react-native-gesture-handler";

export default function MainScreen() {
  // ------------------- STATES -------------------
  const { currentStep } = useStepManagerStore((state) => {
    return { currentStep: state.availableSteps[state.currentStepIndex] };
  });
  const [windowWidth, setWindowWidth] = useState<number>(
    Dimensions.get("window").width
  );
  const [windowHeight, setWindowHeight] = useState<number>(
    Dimensions.get("window").height
  );

  const videoRef = useRef<Video>(null);
  const [status, setStatus] = useState<any>({});
  const [touchCounter, setTouchCounter] = useState<number>(0);
  const [playVideo, setPlayVideo] = useState<boolean>(false);
  const [background, setBackground] = useState<any>(ImageDataset.background);
  const [backgroundCharm, setBackgroundCharm] = useState<any>(
    ImageDataset.backgroundCharms
  );
  const [backgroundMessage, setBackgroundMessage] = useState<any>(
    windowWidth < 1280
      ? ImageDataset.backgroundMessage
      : ImageDataset.backgroundMessageDesktop
  );

  const [visibleBg, setVisibleBg] = useState<"default" | "charm" | "message">(
    "default"
  );
  const [removeOverlay, setRemoveOverlay] = useState<boolean>(true);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(
    useStepManagerStore.getState().currentStepIndex
  );
  const [addCharms, setAddCharms] = useState<boolean>(false);
  const [scaleBackground, setScaleBackground] = useState<number>(1);
  const [mobileView, setMobileView] = useState<boolean>(
    Platform.OS === "android" || Platform.OS === "ios"
  );

  const [animateLottie, setAnimateLottie] = useState<boolean>(
    useJourneyStore.getState().animateLottie
  );
  const [charmsSelected, setCharmsSelected] = useState<Array<ICharmType>>(
    useJourneyStore.getState().selectedCharms
  );
  const [exitAnimation, setExitAnimation] = useState<boolean>(
    useJourneyStore.getState().doExitAnimation ?? false
  );

  //const bg1 = ImageDataset.background;
  //const bg2White = ImageDataset.backgroundFinal;
  const motiBackgroundRef = useRef<any>(null);
  const backgroundCharms = ImageDataset.backgroundCharms;

  // ------------------- ZUSTAND -------------------
  const { setHideHeader, setHideFooter, setSwipeMood, setCanSwipeMood } =
    useJourneyStore.getState();

  const { canSwipe, moodSwipe } = useJourneyStore((state) => ({
    canSwipe: state.canSwipe,
    moodSwipe: state.moodSwipe,
  }));

  // ------------------- SUBSCRIPTIONS -------------------
  const unsub = useStepManagerStore.subscribe((state) => {
    state.currentStepIndex !== currentStepIndex &&
      setCurrentStepIndex(state.currentStepIndex);
  });
  const unsubAnimateLottie = useJourneyStore.subscribe((state) => {
    state.animateLottie !== animateLottie &&
      setAnimateLottie(state.animateLottie);
  });

  const undubCharmsSelected = useJourneyStore.subscribe((state) => {
    state.selectedCharms !== charmsSelected &&
      setCharmsSelected(state.selectedCharms);
  });

  const unsubExitAnimation = useJourneyStore.subscribe((state) => {
    state.doExitAnimation !== exitAnimation &&
      setExitAnimation(state.doExitAnimation);
  });

  // ------------------- EFFECTS -------------------
  useEffect(() => {
    SoundPlayer.loadSounds();

    Dimensions.addEventListener("change", () => {
      setWindowWidth(Dimensions.get("window").width);
      setWindowHeight(Dimensions.get("window").height);
    });

    return () => {
      unsub();
      /*  unsubRemoveOverlay(); */

      unsubAnimateLottie();
      undubCharmsSelected();
      unsubExitAnimation();
    };
  }, []);

  useEffect(() => {
    switch (currentStep.operations?.type) {
      case OperationType.store_setup:
        setHideHeader(true);
        setAddCharms(false);
        setVisibleBg("default");
        break;

      case OperationType.welcome_page:
        /* setScaleBackground(1); */

        setHideHeader(false);
        setHideFooter(false);
        setAddCharms(false);
        setVisibleBg("default");

        // TEST send email
        /*  onChangeFalseModal("send"); */
        /* setBackground(bg1); */
        break;
      case OperationType.mood_selection:
        /* setScaleBackground(1); */
        setAnimateLottie(false);
        setHideHeader(false);
        setHideFooter(false);
        setAddCharms(false);
        setVisibleBg("default");

        break;
      case OperationType.final_video:
        setVisibleBg("default");
        setHideHeader(false);
        setHideFooter(false);

        /* setBackground(bg2White); */
        break;
      case OperationType.loading:
        setVisibleBg("default");
        setPlayVideo(true);
        videoRef.current?.playAsync();

        break;
      case OperationType.charmSelection:
        setAddCharms(true);
        setHideHeader(false);
        setHideFooter(false);
        setVisibleBg("charm");
        setTimeout(() => setAnimateLottie(true), 400);
        /* setScaleBackground(1); */

        break;
      case OperationType.mailTemplate:
        setAddCharms(false);
        setVisibleBg("message");

        break;
      case OperationType.loader:
        setHideHeader(true);
        setHideFooter(true);
        setVisibleBg("default");

        break;
      case OperationType.loading:
        setHideHeader(true);
        setHideFooter(true);

        break;
      case OperationType.informative:
        const { currentStepIndex } = useStepManagerStore.getState();

        if (currentStepIndex === 1 || currentStepIndex === 8) {
          setHideHeader(false);
          setHideFooter(false);
          /*  useJourneyStore.setState({ removeOverlay: true });
                    setRemoveOverlay(true); */
        }
        break;

      default:
        /* setAddCharms(false); */
        /* setBackground(bg1); */
        break;
    }
  }, [currentStep]);

  useEffect(() => {
    if (currentStep.operations?.type === OperationType.final_video) return;

    if (status?.didJustFinish) {
      setPlayVideo(false);
      useStepManagerStore.getState().increaseStep();
    } else if (status?.isPlaying) {
    }
  }, [status]);

  // ------------------- USEMEMO  COMPONENT FOR CHARM SELECTION -------------------
  // ------------------- Charms images -------------------
  const Charms = useMemo(() => {
    return (
      <AnimatePresence exitBeforeEnter>
        {addCharms && (
          <>
            {charms.map((charm, index) => {
              const randomDelay = 600 + Math.random() * 600;
              return (
                <MotiImage
                  from={{ opacity: 0 }}
                  animate={{
                    opacity: exitAnimation ? 0 : 1,
                  }}
                  transition={{
                    type: "timing",
                    delay: exitAnimation ? 0 : randomDelay,
                  }}
                  key={`${currentStepIndex}_charms_${index}`}
                  source={charm}
                  style={styles.charmsLayer}
                />
              );
            })}
            {/** --------- Lottie --------- **/}
            {/* <LottieComponent lottie={Lottie.shine} loop={true} /> */}
            {/* <LottieComponent lottie={Lottie.charmsTutorial} loop={true} /> */}
          </>
        )}
      </AnimatePresence>
    );
  }, [addCharms, exitAnimation]);

  // ------------------- Charms Glows -------------------
  const SelectedCharms = useMemo(() => {
    return (
      <AnimatePresence exitBeforeEnter>
        {addCharms && (
          <>
            {charmsSelected?.map((charm) => {
              return (
                <>
                  <MotiImage
                    key={`${charm.id}_glow`}
                    from={{ opacity: 0 }}
                    animate={{
                      opacity: exitAnimation ? 0 : 1,
                    }}
                    exit={{ opacity: 0 }}
                    source={charm.glowImage}
                    style={{
                      flex: 1,
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      top: 0,
                      left: 0,
                      zIndex: 2,
                    }}
                  />
                  <MotiImage
                    key={`${charm.id}_Outline`}
                    from={{ opacity: 0 }}
                    animate={{
                      opacity: exitAnimation ? 0 : 1,
                    }}
                    exit={{ opacity: 0 }}
                    source={charm.outlineImage}
                    style={{
                      flex: 1,
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      top: 0,
                      left: 0,
                      zIndex: 4,
                    }}
                  />
                </>
              );
            })}
          </>
        )}
      </AnimatePresence>
    );
  }, [addCharms, charmsSelected, exitAnimation]);

  // ------------------- Charms Pointers -------------------
  const Pointers = useMemo(() => {
    return (
      <>
        {currentStep.id ===
          Identifiers.CHARM_SELECTOR /* && !showScreenSaver */ && (
          <CharmPointers />
        )}
      </>
    );
  }, [currentStep]);

  // ------------------- Lottie tutorial -------------------
  const LottieAnimation = useMemo(() => {
    return (
      <>
        {/** --------- Lottie --------- **/}
        {/* <LottieComponent lottie={Lottie.shine} loop={true} /> */}
        {animateLottie && charmsSelected.length !== 3 && (
          <MotiView
            from={{ opacity: 0 }}
            animate={{ opacity: exitAnimation ? 0 : 1 }}
            exit={{ opacity: 0 }}
            transition={{ type: "timing", duration: 1500 }}
            style={{
              flex: 1,
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              zIndex: 5,
            }}
          >
            {[
              { lottie: Lottie.charmB, name: "b" },
              { lottie: Lottie.charmBox, name: "box" },
              { lottie: Lottie.charmHeart, name: "heart" },
              { lottie: Lottie.charmInfinite, name: "infinito" },
              { lottie: Lottie.charmMoon, name: "moon" },
              { lottie: Lottie.charmStar, name: "star" },
            ].map((el) => {
              const elFind = charmsSelected?.find(
                (charm) => charm.title.split(" ")[1].toLowerCase() === el.name
              );

              if (elFind) return <></>;
              return <LottieComponent lottie={el.lottie} loop={true} />;
            })}
            {/*  <LottieComponent
                            lottie={Lottie.charmsTutorial}
                            loop={true}
                        /> */}
          </MotiView>
        )}
      </>
    );
  }, [animateLottie, exitAnimation, charmsSelected]);

  // ------------------- RENDER -------------------
  const [translation, setTranslation] = useState(0);
  return (
    <PanGestureHandler
      onGestureEvent={(e) => {
        if (currentStepIndex !== 2) return;

        if (!canSwipe && e.nativeEvent.state === State.ACTIVE) return;

        const { translationX } = e.nativeEvent;

        if (
          canSwipe &&
          (translationX > (windowWidth < 600 ? 20 : 50) ||
            translationX < (windowWidth < 600 ? -20 : -50))
        ) {
          setTranslation(translationX);
          // Verifica se lo swipe è da destra verso sinistra
          if (translationX < 0) {
            setSwipeMood(1);
            SoundPlayer.playSound("swipe");
            setCanSwipeMood(false);
          }
          if (
            // Verifica se lo swipe è da sinistra verso destra
            translationX > 0
          ) {
            setSwipeMood(-1);
            SoundPlayer.playSound("swipe");
            setCanSwipeMood(false);
          }

          return setTimeout(() => {
            setCanSwipeMood(true);
          }, 500);
        }
      }}
    >
      <TouchableWithoutFeedback
        onPress={() => {} /* setTouchCounter((prev) => prev + 1) */}
        style={{
          flex: 1,
          width: Dimensions.get("window").width,
          height: Dimensions.get("window").height,
        }}
      >
        <View style={styles.container}>
          <LinearGradient
            colors={["rgba(71, 50, 19, 0.60)", "rgba(71, 50, 19, 0.00)"]}
            style={{
              width: "100%",
              position: "absolute",
              top: 0,
              height: 280,
              zIndex: 4,
            }}
          />

          <AnimatePresence exitBeforeEnter>
            <MotiImage
              from={{ opacity: 0 }}
              animate={{
                opacity: 1,
                scale: mobileView || windowWidth < 524 ? 1 : scaleBackground,
                translateY:
                  scaleBackground !== 1
                    ? mobileView || windowWidth < 524
                      ? 0
                      : 40
                    : 0,
              }}
              exit={{ opacity: 0 }}
              style={styles.background}
              key={background?.toString() ?? "-"}
              source={background}
              transition={{
                type: "timing",
                duration: 1500,
              }}
            />
            {/* ------------------- SCREENSAVER */}
            {/*  {mobileView && (
                            <ScreenSaver touchCounter={touchCounter} />
                        )} */}
            {/* ------------------- VIDEO BACKGROUND BEFORE SEND EMAIL */}
            {playVideo && (
              <Video
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  minWidth: Dimensions.get("window").width,
                  minHeight: Dimensions.get("window").height,
                  maxWidth: Dimensions.get("window").width,
                  maxHeight: Dimensions.get("window").height,
                  zIndex: 999999999,
                }}
                ref={videoRef}
                source={{
                  //uri: "https://bulgari-digital-craft.s3.eu-central-1.amazonaws.com/bulgari_videos/video-background.mp4",
                  uri: "https://bulgari-digital-craft.s3.eu-central-1.amazonaws.com/bulgari_videos/pre-gen-app-d.mp4",
                }}
                // source={{ uri: require('../../assets/videos/video-background.mp4') }}
                useNativeControls={false}
                isLooping={false}
                resizeMode={ResizeMode.COVER}
                onPlaybackStatusUpdate={(status) => setStatus(() => status)}
                shouldPlay={true}
                isMuted={true}
                videoStyle={{
                  minWidth: "100%",
                  maxWidth: "100%",
                  minHeight: "100%",
                  maxHeight: "100%",
                }}
                onLoadStart={() => {
                  videoRef.current?.playAsync();
                }}
              />
            )}
          </AnimatePresence>

          {/* ------------------- IMAGE BACKGROUND CHARM E MESSAGE */}
          <AnimatePresence exitBeforeEnter>
            {currentStepIndex !== 2 && visibleBg === "default" && (
              <MotiImage
                from={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  scale: mobileView || windowWidth < 524 ? 1 : scaleBackground,
                  translateY:
                    scaleBackground !== 1
                      ? mobileView || windowWidth < 524
                        ? 0
                        : 40
                      : 0,
                }}
                exit={{ opacity: 0 }}
                style={styles.background}
                key={"bg_default".toString() ?? "-"}
                source={background}
                transition={{
                  type: "timing",
                  duration: 500,
                }}
              />
            )}
            {currentStepIndex === 2 && visibleBg === "default" && (
              <MoodCarousel swipe={moodSwipe} />
            )}
            {visibleBg === "charm" && (
              <MotiImage
                from={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  scale: mobileView || windowWidth < 524 ? 1 : scaleBackground,
                  translateY:
                    scaleBackground !== 1
                      ? mobileView || windowWidth < 524
                        ? 0
                        : 40
                      : 0,
                }}
                exit={{ opacity: 0 }}
                style={styles.background}
                key={backgroundCharm?.toString() ?? "-"}
                source={backgroundCharm}
                transition={{
                  type: "timing",
                  duration: 800,
                }}
              />
            )}
            {visibleBg === "message" && (
              <MotiImage
                from={{ opacity: 0 }}
                animate={{
                  opacity: 0.35,
                  scale: mobileView || windowWidth < 524 ? 1 : scaleBackground,
                  translateY:
                    scaleBackground !== 1
                      ? mobileView || windowWidth < 524
                        ? 0
                        : 40
                      : 0,
                }}
                exit={{ opacity: 0 }}
                style={styles.background}
                key={backgroundMessage?.toString() ?? "-"}
                source={backgroundMessage}
                transition={{
                  type: "timing",
                  duration: 500,
                }}
              />
            )}
          </AnimatePresence>
          <MotiView
            animate={{ opacity: removeOverlay ? 0 : 1 }}
            transition={{ type: "timing", duration: 1500 }}
            style={[
              styles.backgroundOverlay,
              currentStep.operations?.type === OperationType.loader &&
                styles.backgroundOverlayLoading,
            ]}
          />

          {/** --------- Charms pointer --------- */}
          {Pointers}
          {/** --------- Charms image --------- **/}
          {Charms}
          {/** --------- Lottie --------- **/}
          {LottieAnimation}
          {/** --------- Charms Glows --------- **/}
          {SelectedCharms}
          {/** --------- Mood carousel --------- **/}

          <Step stepConfig={currentStep} />
          <Header />
          <LinearGradient
            colors={["rgba(71, 50, 19, 0.60)", "rgba(71, 50, 19, 0.00)"]}
            style={{
              width: "100%",
              position: "absolute",
              bottom: 0,
              height: 124,
              transform: [{ rotate: "180deg" }],
            }}
          />
        </View>
      </TouchableWithoutFeedback>
    </PanGestureHandler>
  );
}
