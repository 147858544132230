import { Dimensions, Pressable, View } from "react-native";
import { styles } from "./Step.styles";
import IStep, {
	IEnumFalseModal,
	OperationType,
	TFalseModal,
} from "../../data/dataInterfaces";
import StepHeader from "../StepHeader/StepHeader";
import StepCenter from "../StepCenter/StepCenter";
import StepFooter from "../StepFooter/StepFooter";
import SendMultiEmail from "../Form/SendMultiEmail/SendMultiEmail";
import { useJourneyStore } from "../../store/JourneyStore";
import { useEffect, useState } from "react";
import Download from "../Download/Download";
import { Tutorial } from "../Tutorial/Tutorial";
import { ReloadModal } from "../ReloadModal/ReloadModal";
import { AvailableSteps } from "../../configuration/configuration";

export interface StepProps {
	stepConfig: IStep;
}

export default function Step(props: StepProps) {
	/* ------------------- PROPS ------------------- */
	const stepConfig = props.stepConfig;
	const { title, subtitles, actions, title_image } = stepConfig;
	const footerTextVisible = true;

	/* ------------------- ZUSTAND ------------------- */

	const { onChangeFalseModal } = useJourneyStore.getState();
	const [falseModal, setFalseModal] = useState<any>(
		useJourneyStore.getState().falseModal,
	);

	/* ------------------- UNSUBZUSTAND ------------------- */

	const unsubFalseModal = useJourneyStore.subscribe((state) => {
		state.falseModal != falseModal && setFalseModal(state.falseModal);
	});

	/* ------------------- ENUM FOR MODALS ------------------- */

	const enumFalseModal: IEnumFalseModal = {
		send: (
			<SendMultiEmail
				config={stepConfig.subOperation?.send}
				isInvite={false}
			/>
		),
		qr: <Download config={stepConfig.subOperation?.download} />,
		invite: (
			<SendMultiEmail
				config={stepConfig.subOperation?.invite}
				isInvite={true}
			/>
		),
		tutorial: <Tutorial />,
		reloadModal: <ReloadModal />,
		reloadModalLongpress: <ReloadModal longPress={true} />,
		prev: <></>,
	};

	useEffect(() => {
		return () => {
			unsubFalseModal();
		};
	}, []);

	return (
		<View style={styles.stepContainer}>
			{/* -------------------  HEADER  ------------------- */}
			<StepHeader title={title} titleImage={title_image} />

			{/* -------------------  CENTER  ------------------- */}

			<StepCenter
				operations={stepConfig.operations}
				subtitles={subtitles}
			/>

			{/* SCOMMENTARE PER AVERE IL CLICK SULLA PAGINA CHE RIPORTA AL RELOAD NELLA SCHERMATA DI INFORMATIVA FINALE */}
			{/*  {!falseModal &&
                (stepConfig.operations as any).type ===
                    OperationType.informative && (
                    <Pressable
                        onPress={() => {
                            {
                                onChangeFalseModal("reloadModal");
                            }
                        }}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            minWidth: Dimensions.get("window").width,
                            minHeight: Dimensions.get("window").height,
                            zIndex: 99,
                        }}
                    />
                )} */}

			{/* -------------------  FOOTER  ------------------- */}
			<StepFooter
				actions={actions}
				footerTextVisible={footerTextVisible}
			/>

			{/* -------------------  MODALs  ------------------- */}
			{falseModal && enumFalseModal[falseModal as TFalseModal]}
		</View>
	);
}
