import { Dimensions, StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1.5rem',
    minHeight: '40vh',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
  },

  video: {
    positio: 'absolute',
    minWidth: Dimensions.get('window').width,
    minHeight: Dimensions.get('window').width,
  },
})
