import React, { useEffect, useState } from "react";
import {
    Dimensions,
    Platform,
    Pressable,
    ScrollView,
    View,
} from "react-native";
import { styles } from "./Tutorial.styles";
import { MotiView } from "moti";
import TutorialCard from "./TutorialCard";
import { tutorial } from "../../configuration/steps/tutorial";
import SvgComponent from "../Svg/SvgComponent";

export const Tutorial = () => {
    /* --------------- CONFIG --------------- */
    const configuration = tutorial;
    const config = configuration.operations?.config;

    /* --------------- STATE --------------- */
    const [mobileView, setMobileView] = useState<boolean>(
        Platform.OS === "android" || Platform.OS === "ios",
    );
    const [windowWidth, setWindowWidth] = useState<number>(
        Dimensions.get("window").width,
    );

    /* --------------- METHODS --------------- */

    const controlViewport = () => {
        const { width } = Dimensions.get("window");
        setMobileView(width < 525); // 600 per dire che è mobile
        setWindowWidth(width);
    };

    useEffect(() => {
        controlViewport();
        if (!mobileView) Dimensions.addEventListener("change", controlViewport);

        return () => {
            removeEventListener?.("change", controlViewport);
        };
    }, []);

    return (
        <MotiView
            from={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ type: "timing", duration: 800 }}
            style={[
                styles.absoluteContainer,
                windowWidth < 600 ? { paddingHorizontal: 20 } : {},
            ]}
        >
            {/* --------------------------- Overlay */}
            <Pressable style={styles.overlay} onPress={config.action} />

            {/* --------------------------- Content */}
            <Pressable
                style={[
                    styles.content,
                    windowWidth < 851 && styles.inColumn,
                    windowWidth < 600 && styles.contentMobile,
                ]}
            >
                {/* --------------------------- Close icon */}
                <Pressable
                    onPress={config.action}
                    style={[
                        styles.closeIcon,
                        windowWidth < 600 && styles.closeIconMobile,
                    ]}
                    accessibilityLabel='Close'
                >
                    <SvgComponent type={config.icon} />
                </Pressable>

                {/* --------------------------- Scroll content */}
                <ScrollView
                    style={styles.scroll}
                    showsVerticalScrollIndicator={false}
                >
                    <View style={styles.scrollContainer}>
                        {config.cards.map((card: any, index: number) => (
                            <TutorialCard key={index + 28397} card={card} />
                        ))}
                    </View>
                </ScrollView>
            </Pressable>
        </MotiView>
    );
};
