import IStep, { OperationType } from "../../data/dataInterfaces";
import { useStepManagerStore } from "../../store/ExperienceStore";
import { useJourneyStore } from "../../store/JourneyStore";
import { Identifiers } from "../../utils/Indentifiers";

export interface ITemplateMessage {
  id: string;
  text: string;
}

export const mailTemplate: IStep = {
  title: "",
  id: Identifiers.MAIL_TEMPLATE,
  operations: {
    type: OperationType.mailTemplate,
    config: {
      step: 3,
      title: "$messageSelectionPage.title",
      message: [
        {
          id: "1",
          text: "$messageSelectionPage.message1",
        },
        {
          id: "2",
          text: "$messageSelectionPage.message2",
        },
        {
          id: "3",
          text: "$messageSelectionPage.message3",
        },
        {
          id: "4",
          text: "$messageSelectionPage.message4",
        },
        {
          id: "5",
          text: "$messageSelectionPage.message5",
        },
        {
          id: "6",
          text: "$messageSelectionPage.message6",
        },
        {
          id: "7",
          text: "$messageSelectionPage.nomessage",
        },
      ],
      action: {
        id: "mood_selector",
        title: "$messageSelectionPage.generate",
        btnType: "filled",
        size: "default",
        action: () => {
          useJourneyStore.getState().setDoExitAnimation();
        },
      },
    },
  },
};
