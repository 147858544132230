import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
    container: {
        width: "100%",
        maxWidth: 635,
        minHeight: 243,
        display: "flex",
        color: "#ffffff",
        backgroundColor: "rgba(255, 255, 255, 0.30)",
        paddingVertical: 60,
        paddingHorizontal: 40,
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 24,
        flex: 1,
        borderWidth: 1.4,
        borderColor: "rgba(255, 255, 255, 1)",
        borderRadius: 16,
    },
});
