import { TypeSvg } from "../components/Svg/SvgComponent";

export enum OperationType {
    mood_selection = "mood_selection",
    loading = "loading",
    loader = "loader",
    final_video = "final_video",
    christmas_ball = "christmas_ball",
    store_setup = "store_setup",
    authentication = "authentication",
    informative = "informative",
    tutorial = "tutorial",
    welcome_page = "welcomepage",
    charmSelection = "charmSelection",
    mailTemplate = "mailTemplate",
    reloadModal = "reloadModal",
}

export type BtnType =
    | "default"
    | "outlined"
    | "filled"
    | "gold"
    | "backButton"
    | "browned"
    | "downloadBrown"
    | "sharedWhite";
export type BtnSize = "default" | "bigBtn" | "smallBtn";
export type TSendMailMode = "send" | "invite";
export type TFalseModal =
    | "send"
    | "qr"
    | "invite"
    | "tutorial"
    | "prev"
    | "reloadModal"
    | "reloadModalLongpress";
export type IMoodType = "serpenti" | "bzero" | "diva";

export type IEnumFalseModal = {
    [key in TFalseModal]: React.ReactNode;
};
export interface CheckboxData {
    id: string;
    text: string;
    isRequired?: boolean;
}
export interface IStepAction {
    id: string;
    title: string;
    disabled?: boolean;
    btnType?: BtnType;
    size?: BtnSize;
    icon?: any;
    action: () => void;
}

export default interface IStep {
    id: string;
    title?: string;
    title_image?: any;
    subtitles?: string[];
    isActionRequired?: boolean;
    actions?: IStepAction[];
    operations?: {
        type: OperationType;
        config?: any;
    };
    hideHeader?: boolean;
    hideFooter?: boolean;
    subOperation?: {
        send?: {
            config?: any;
            actions: IStepAction[];
        };
        download?: {
            config?: any;
            actions: IStepAction[];
        };
        invite?: {
            config?: any;
            actions: IStepAction[];
        };
    };
}

export interface IObjectSelector {
    id: string;
    title: string;
    image: any;
}

export interface BaseImage {
    id: string;
    imageUrl?: string;
    base64: string;
    locked?: boolean;
}

// ---------------- SELECT INTERFACE
export interface SelectOption {
    image?: any;
    title?: string;
    id: string;
    country?: string;
}

export interface Select {
    id: string;
    options: SelectOption[];
    hideSelector?: boolean;
    title: string;
    withIdText?: boolean;
    storeCode?: boolean;
    isRequired?: boolean;
    placeholder?: string;
    customMaxWidth?: string;
}

// ---------------- INPUT INTERFACE
export interface InputEl {
    label: string;
    error: string;
    placeholder: string;
    defaultValue: string;
    keyboardType: string;
    isRequired: boolean;
    isValid: boolean;
}

// ---------------- LANGUAGE ENUM INTERFACE
export interface LanguageFlags {
    [key: string]: TypeSvg;
}
