import React, { useState } from 'react'
import { Pressable, View } from 'react-native'
import { styles } from './PrefixSelect.style'
import BvlgariText from '../../../TextElement/BvlgariText'
import { prefixDataset } from '../../../../configuration/selectsDataset'

interface Props {
  prefix: string
  error: boolean
  onReturnPrefix: (data: { title: string, id: string }) => void
}

const PrefixSelect = ({ prefix, error, onReturnPrefix }: Props) => {
  const availablePrefixes = prefixDataset

  const [open, setOpen] = useState<boolean>(false)
  const [prefixValue, setPrefixValue] = useState<string | undefined>(prefix ?? undefined)
  const [hover, setHover] = useState<number>(-1)

  const onOpen = () => setOpen((prev) => !prev)

  const onChangePrefix = (newPrefix: { title: string, id: string }) => {
    setPrefixValue(newPrefix.title)
    setOpen(false)
    onReturnPrefix(newPrefix)
  }

  return (
    <View style={[styles.prefix, error && styles.errorInput, open && styles.borderForOpenDropdown]}>
      <Pressable onPress={onOpen}><BvlgariText text={prefixValue ?? "-"} textType="mRegular" /></Pressable>
      <View style={[styles.dropdown, open && styles.dropdownOpen]}>
        {availablePrefixes.map((p, i) => (
          <Pressable
            key={i + 1234}
            style={[styles.item, hover === i && styles.itemHover, prefixValue === p.title && styles.itemActive]}
            onHoverIn={() => setHover(i)}
            onHoverOut={() => setHover(-1)}
            onPress={() => onChangePrefix(p)}
          >
            <BvlgariText text={p.title} textType="mRegular" />
          </Pressable>
        ))}
      </View>
    </View>
  )
}

export default PrefixSelect
