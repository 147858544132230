import { Dimensions, Platform, View } from "react-native";
import { styles } from "./StepCenter.styles";
import { OperationType } from "../../data/dataInterfaces";
import { MoodSelector } from "../MoodSelector/MoodSelector";
import { VideoGenerationLoading } from "../VideoGenerationLoading/VideoGenerationLoading";
import { FinalVideoViewer } from "../FinalVideoViewer/FinalVideoViewer";
import { StoreSetup } from "../StoreSetup/StoreSetup";
import { Authentication } from "../Authentication/Authentication";
import { Informative } from "../Informative/Informative";
import WelcomePage from "../WelcomePage/WelcomePage";
import { AnimatePresence, MotiView, useAnimationState } from "moti";
import { useStepManagerStore } from "../../store/ExperienceStore";
import CharmSelector from "../CharmSelector/CharmSelector";
import MailTemplate from "../MailTemplate/MailTemplate";
import { useEffect, useMemo, useState } from "react";
import { useJourneyStore } from "../../store/JourneyStore";
import Loader from "../Loading/Loader";
import { BASE_SCALE, IPAD_SCALE, isIosApp } from "../../utils/utils";
import BvlgariText from "../TextElement/BvlgariText";

export interface StepCenterProps {
	subtitles?: string[];
	operations?: {
		type: OperationType;
		config?: any;
	};
}

export default function StepCenter(props: StepCenterProps) {
	/* ------------ ZUSTAND ------------ */
	const isIos = useJourneyStore.getState().isIos;
	const { mood } = useJourneyStore((state) => ({
		mood: state.mood,
	}));

	/* ------------ STATE ------------ */
	const [mobileView, setMobileView] = useState<boolean>(
		Platform.OS === "android" || Platform.OS === "ios",
	);
	const [windowWidth, setWindowWidth] = useState<number>(
		Dimensions.get("window").width,
	);
	const [windowHeight, setWindowHeight] = useState<number>(
		Dimensions.get("window").height,
	);

	const [addScale, setAddScale] = useState<boolean>(false);
	const { subtitles, operations } = props;
	const { stepParams, currentStepIndex } = useStepManagerStore((state) => {
		return {
			stepParams: state.stepParams,
			currentStepIndex: state.currentStepIndex,
		};
	});
	const [exitAnimation, setExitAnimation] = useState(
		useJourneyStore.getState().doExitAnimation,
	);

	const unsub = useJourneyStore.subscribe((state) => {
		state.doExitAnimation !== exitAnimation &&
			setExitAnimation(state.doExitAnimation);
	});

	useEffect(() => {
		Dimensions.addEventListener("change", () =>
			setWindowWidth(Dimensions.get("window").width),
		);
		return () => {
			unsub();
		};
	}, []);

	useEffect(() => {
		switch (operations?.type) {
			case OperationType.mood_selection:
				setAddScale(true);
				break;
			case OperationType.charmSelection:
				setAddScale(true);
				break;
			case OperationType.mailTemplate:
				setAddScale(true);
				break;
			case OperationType.authentication:
				setAddScale(false);
				break;
			case OperationType.welcome_page:
				setAddScale(true);
				break;

			default:
				break;
		}
	}, [operations]);

	const CurrentCenterItem = useMemo(() => {
		const _operation = operations;
		let item;

		switch (_operation?.type) {
			case OperationType.mood_selection:
				item = <MoodSelector config={_operation.config} />;
				break;

			case OperationType.loading:
				item = <VideoGenerationLoading config={_operation.config} />;
				break;
			case OperationType.final_video:
				item = <FinalVideoViewer config={_operation.config} />;
				break;

			case OperationType.store_setup:
				item = <StoreSetup config={_operation.config} />;
				break;
			case OperationType.authentication:
				item = <Authentication config={_operation.config} />;
				break;
			case OperationType.informative:
				item = (
					<Informative
						config={
							stepParams ? { ...stepParams } : _operation.config
						}
					/>
				);
				break;

			case OperationType.welcome_page:
				item = <WelcomePage config={_operation.config} />;
				break;
			case OperationType.charmSelection:
				item = <CharmSelector config={_operation.config} />;
				break;
			case OperationType.mailTemplate:
				item = <MailTemplate config={_operation.config} />;
				break;
			case OperationType.loader:
				item = <Loader lottie={_operation.config.lottie} />;
				break;

			default:
				item = <></>;
		}
		return item;
	}, [exitAnimation, operations, stepParams]);
	const MOBILE = 524;
	const TABLET = 769;
	// @scaleproblem
	// const scaleStepCenter =
	//   mobileView || windowWidth < 524 ?
	//     0.55 :
	//     windowWidth < 769 ?
	//       0.75
	//       : windowWidth > 825 ? 0.75 : 1

	const _scaleStep =
		mobileView || windowWidth <= MOBILE ? BASE_SCALE : IPAD_SCALE;

	const calculateScale = () => {
		if (currentStepIndex === 6 && isIosApp) {
			return 0.8;
		}
		if (
			windowHeight > 500 &&
			windowHeight < 800 &&
			windowWidth > 1200 &&
			currentStepIndex !== 4
		) {
			return 0.37; // RISOLUZIONE ALICIA no welcome
		} else if (
			windowHeight > 500 &&
			windowHeight < 800 &&
			windowWidth > 1200 &&
			currentStepIndex === 4
		) {
			return 0.6; // RISOLUZIONE ALICIA in welcome
		}
		//Monitor del MAC
		else if (windowWidth > 1100 && windowHeight < 1000) {
			if (windowHeight < 890) {
				return windowWidth < 1600 ? 0.6 : 0.48;
			}
			return 0.6;
		} else {
			return _scaleStep;
		}
	};

	// ------ Render Center elements ------
	const renderCenterElements = () => {
		return (
			<View style={styles.absolute}>
				{OperationType.mood_selection === operations?.type && (
					<View
						style={{
							height: "100%",
							width: "100%",
							position: "absolute",
						}}
					>
						{/* TESTO PER NON VEDENTI */}
						<BvlgariText
							text={mood}
							style={{
								color: "transparent",
								opacity: 0,
								height: "100%",
								width: "100%",
							}}
						/>
					</View>
				)}
				<AnimatePresence exitBeforeEnter>
					<MotiView
						from={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ type: "timing", duration: 1500 }}
						key={`${operations?.type.toString()}stepCenterInner`}
						style={[
							styles.stepCenterInner,
							{
								transform: [
									{
										scale: addScale ? calculateScale() : 1,
									},
								],
							},
						]}
					>
						<AnimatePresence exitBeforeEnter>
							<MotiView
								animate={{ opacity: exitAnimation ? 0 : 1 }}
								transition={{ type: "timing", duration: 1500 }}
							>
								{CurrentCenterItem}
							</MotiView>
						</AnimatePresence>
					</MotiView>
				</AnimatePresence>
			</View>
		);
	};

	return <View style={styles.stepCenter}>{renderCenterElements()}</View>;
}
