import { useStepManagerStore } from "../../../store/ExperienceStore";
import { Pressable, View } from "react-native";
import { styles } from "./ReloadButton.style";
import SvgComponent from "../../Svg/SvgComponent";
import { useJourneyStore } from "../../../store/JourneyStore";
import { TFalseModal } from "../../../data/dataInterfaces";
import { useEffect, useState } from "react";
import SoundPlayer from "../../../utils/SoundManager";

interface Props {
  type?: "go-back" | "reload";
  isMobile?: boolean;
}

const ReloadButton = ({ type = "go-back", isMobile }: Props) => {
  const [falseModal, setFalseModal] = useState<TFalseModal | null>(
    useJourneyStore.getState().falseModal
  );
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(
    useStepManagerStore.getState().currentStepIndex
  );
  const [canGoBack, setCanGoBack] = useState<boolean>(true);

  const unsubFalseModal = useJourneyStore.subscribe((state) => {
    state.falseModal !== falseModal && setFalseModal(state.falseModal);
  });
  const unsubCurrentStep = useStepManagerStore.subscribe((state) => {
    state.currentStepIndex !== currentStepIndex &&
      setCurrentStepIndex(state.currentStepIndex);
  });

  useEffect(() => {
    return () => {
      unsubFalseModal();
      unsubCurrentStep();
    };
  }, []);

  const onGoBack = () => {
    if (!canGoBack) return;
    setCanGoBack(false);
    setTimeout(() => setCanGoBack(true), 1600);

    if (falseModal) {
      useJourneyStore.getState().onChangeFalseModal(null);
      useJourneyStore.getState().setHideTitle(false);
      useJourneyStore.getState().hideHeaderFooter(false, false);
      return;
    }

    switch (currentStepIndex) {
      case 2: // moodSelector ( wonder )
        useJourneyStore.setState({ mood: undefined });
        break;
      case 3: // charms Selector
        useJourneyStore.setState({
          selectedCharms: [],
          animateLottie: false,
        });
        break;
      case 6: // final Video Data
        useStepManagerStore.getState().decreaseStep(undefined, 2);
        return;

      case 8:
        useJourneyStore.setState({
          hideTitle: false,
          hideHeader: false,
          hideFooter: false,
          removeOverlay: true,
        });
        setTimeout(() => {
          useStepManagerStore.getState().decreaseStep(undefined, 2);
          useJourneyStore.getState().onChangeFalseModal(null);
        }, 200);
        return;

      default:
        break;
    }

    useJourneyStore.getState().setDoExitAnimation(undefined, undefined, true);
  };

  const methodsForType = async (longPress?: boolean) => {
    SoundPlayer.playSound("click");
    const setFalseModal = useJourneyStore.getState().onChangeFalseModal;

    if (longPress) {
      useJourneyStore.getState().setUserConfirm("", null);
      setFalseModal("reloadModalLongpress");
      return;
    }
    if (type === "reload") {
      setFalseModal("reloadModal");
      return;
    }
    if (type === "go-back") {
      onGoBack();
    }
  };

  return (
    <Pressable
      accessibilityLabel={
        type === "reload" ? "Reload Button" : "Go Back Button"
      }
      style={[styles.container]}
      onPress={() => methodsForType(false)}
      onLongPress={() => methodsForType(true)}
    >
      <View
        style={[
          styles.iconContainer,
          type === "reload" && styles.iconReload,
          isMobile ? styles.mobileSizes : styles.originalSizes,
        ]}
      >
        <SvgComponent type={type} />
      </View>
    </Pressable>
  );
};

export default ReloadButton;
