import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  stepHeader: {
    marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepHeaderImage: {
    width: '100%',
    height: 100,
    maxWidth: '60%',
    resizeMode: 'contain',
  },
})
