import { Dimensions, StyleSheet } from "react-native";

export const styles = StyleSheet.create({
	container: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		minHeight: Dimensions.get("window").height * 1.15,
		gap: 24,
	},

	containerDesktop: {
		minHeight: Dimensions.get("window").height * 1.35,
	},

	containerTabletWeb: {
		minHeight: Dimensions.get("window").height * 1.16,
	},

	containerMobile: {
		minHeight: Dimensions.get("window").height * 1.35,
	},

	containerSmallMobile: {
		minHeight: 950,
	},

	// --------------------- Image ---------------------
	boxImage: {
		position: "absolute",
		resizeMode: "center",
		width: 460,
		height: 571,
		marginHorizontal: 35,
	},

	// --------------------- Carousel ---------------------
	carousel: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},

	boxContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		height: 396,
		width: 512,
	},

	textContainer: {
		width: 320,
	},

	textAbsolute: {
		position: "absolute",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		height: "100%",
	},

	// --------------------- Arrows ---------------------
	arrow: {
		width: 128,
		height: "100%",
		display: "flex",
		justifyContent: "center",
	},

	arrowMobile: { width: 90 },
	arrowSmallMobile: { width: 50 },

	// --------------------- Button ---------------------
	actionContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: 604,
		height: 96,
		backgroundColor: "rgba(255, 255, 255, 0.20)",
		borderRadius: 8,
		marginTop: 156,
	},

	actionButton: {
		width: 430,
		height: 48,
		zIndex: 10,
	},
});
