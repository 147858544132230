import { Dimensions, Image, View } from "react-native";
import { styles } from "./FinalVideoViewer.styles";
import { MotiView } from "moti";
import BvlgariText from "../TextElement/BvlgariText";
import { useJourneyStore } from "../../store/JourneyStore";
import { useEffect, useState } from "react";
import { Easing } from "react-native-reanimated";
import {
    BIG_IPAD_FINAL_VIDEO_SCALE,
    BIG_WEB_FINAL_VIDEO_SCALE,
    IPAD_FINAL_VIDEO_SCALE,
    WEB_FINAL_VIDEO_SCALE,
    getIsMadrid,
    isIosApp,
} from "../../utils/utils";
import { sendGeneratedVideo } from "../../api/VideoGenerationServices";
import CarouselFinalVideo from "./CarouselFinalVideo";
import { PanGestureHandler } from "react-native-gesture-handler";
import CarouselPoint from "../CarouselPoints/CarouselPoint";
import { ITemplateMessage } from "../../configuration/steps/mailTemplate";
import Lottie from "lottie-react";

export interface IFinalVideoViewerProps {
    config: any;
}

export function FinalVideoViewer({ config }: IFinalVideoViewerProps) {
    /* ----------------- ZUSTAND ----------------- */
    const { falseModal, urlVideoGenerated, lottieScroll } = useJourneyStore(
        (state: any) => ({
            falseModal: state.falseModal,
            urlVideoGenerated: state.urlVideoGenerated,
            lottieScroll: state.lottieScroll,
        }),
    );

    /* ----------------- STATES ----------------- */
    const [windowHeight] = useState<number>(Dimensions.get("window").height);
    const [windowWidth] = useState<number>(Dimensions.get("window").width);
    const [swipeData, setSwipeData] = useState<any[]>(["video"]);
    const [swipe, setSwipe] = useState<"right" | "left">("left");

    /* ----------------- CONSTANTS ----------------- */
    const ease = Easing.bezier(0.42, 0, 0.444, 1);
    const frameImage = getIsMadrid() ? config.imageQuad : config.image;

    /* ----------------- METHODS ----------------- */

    const setCorrectScale = () => {
        if (!isIosApp) {
            if (windowHeight < 1000) {
                return WEB_FINAL_VIDEO_SCALE;
            } else {
                return BIG_WEB_FINAL_VIDEO_SCALE;
            }
        } else {
            if (windowHeight < 1100) {
                return IPAD_FINAL_VIDEO_SCALE;
            } else {
                return BIG_IPAD_FINAL_VIDEO_SCALE;
            }
        }
    };

    const calculateMarginTop = () => {
        const isMobile = windowHeight < 1081;
        if (!isIosApp) return !isMobile ? -40 : 40;
        else return -40; // isMobile ? -40 : -40
    };

    const checkMessageAndDoubleCard = () => {
        const msgSelected: ITemplateMessage = (useJourneyStore.getState()
            .selectedMessageTemplate as ITemplateMessage) ?? {
            id: "7",
            text: "",
        };
        if (!msgSelected) {
            return;
        }
        if (swipeData.length < 2 && msgSelected.id !== "7") {
            setTimeout(
                () => setSwipeData((prev) => [...prev, msgSelected.text]),
                3000,
            );
        }
    };

    useEffect(() => {
        sendGeneratedVideo();
    }, []);

    useEffect(() => {
        if (falseModal !== null) {
            setSwipeData((prev) => [prev[0]]);
            setSwipe("left");
        }
        if (falseModal === null) checkMessageAndDoubleCard();
    }, [falseModal]);

    return (
        <PanGestureHandler
            onGestureEvent={(e) => {
                if (swipeData.length === 1 || swipeData[1] === "") return;

                const { translationX } = e.nativeEvent;

                // Verifica se lo swipe è da destra verso sinistra
                if (translationX < 0 && swipe === "left") {
                    setSwipe("right");
                }
                // Verifica se lo swipe è da sinistra verso destra
                if (translationX > 0 && swipe === "right") {
                    setSwipe("left");
                    // rimuovo il lottie fino a che non si cambia utente o si refresha la pagina
                    if (lottieScroll)
                        useJourneyStore.getState().setLottieScroll(false);
                }
            }}
        >
            <View
                style={{
                    minWidth: Dimensions.get("window").width,
                    minHeight: 1000,
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {!falseModal && urlVideoGenerated && (
                    <MotiView
                        from={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        delay={0}
                        exitTransition={{ type: "timing", duration: 500 }}
                        transition={{ type: "timing", duration: 500 }}
                    >
                        {/* -------------- HEADER --------------*/}
                        <View
                            style={[
                                isIosApp
                                    ? styles.container
                                    : styles.containerBrowserIpad,
                                {
                                    width: 528,
                                    gap:
                                        windowHeight > 1000
                                            ? 100
                                            : isIosApp
                                            ? 48
                                            : 30,
                                },
                            ]}
                        >
                            {/* -------------- TITLE --------------*/}
                            <BvlgariText
                                style={{
                                    fontSize: 24,
                                    fontWeight: "300",
                                    lineHeight: 33.36,
                                    color: "#473213",
                                    marginTop:
                                        windowWidth < 390
                                            ? 0
                                            : windowWidth < 525
                                            ? 10
                                            : calculateMarginTop(),
                                }}
                                text={config.title}
                                textType='xlLight'
                            />

                            {/* -------------- CARD IMAGE + CAROUSEL + LOTTIE + POINTER --------------*/}
                            <MotiView
                                from={{ scale: 5 }}
                                animate={{
                                    scale: setCorrectScale(),
                                }}
                                transition={{
                                    type: "timing",
                                    duration: 1500,
                                    easing: ease,
                                }}
                                delay={5000}
                                style={styles.videoImageContainer}
                            >
                                {/* -------------- CARD IMAGE --------------*/}
                                <Image
                                    source={frameImage}
                                    style={[styles.image]}
                                />

                                {/* -------------- CAROUSEL --------------*/}
                                <CarouselFinalVideo
                                    videoGenerated={urlVideoGenerated}
                                    swipe={swipe}
                                    swipeData={swipeData}
                                />

                                {/* -------------- LOTTIE  --------------*/}
                                {lottieScroll && swipeData.length > 1 && (
                                    /* SE LEFT ANIMAZIONE LOTTIE DA DESTRA VERSO SINISTRA ALTRIMENTI ANIMAZIONE DA SINISTRA VERSO DESTRA */
                                    <Lottie
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                        }}
                                        animationData={
                                            swipe === "left"
                                                ? require("/assets/swipe-v1a.json")
                                                : require("/assets/swipe-v1b.json")
                                        }
                                        aria-aria-labelledby='use lottie animation'
                                    />
                                )}

                                {/* -------------- CAROUSEL POINTER --------------*/}
                                {swipeData.length > 1 && (
                                    <View
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            zIndex: 1,
                                        }}
                                    >
                                        <View
                                            style={{
                                                marginHorizontal: "auto",
                                                marginVertical: "auto",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                                paddingBottom: 8,
                                                width: getIsMadrid()
                                                    ? "73%"
                                                    : "66%",
                                                aspectRatio: getIsMadrid()
                                                    ? 1 / 1
                                                    : 9 / 16,
                                            }}
                                        >
                                            <CarouselPoint
                                                totalSteps={swipeData.length}
                                                indefOfActiveStep={
                                                    swipe === "left" ? 0 : 1
                                                }
                                            />
                                        </View>
                                    </View>
                                )}
                            </MotiView>
                        </View>
                    </MotiView>
                )}
            </View>
        </PanGestureHandler>
    );
}
