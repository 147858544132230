import { styles } from "./StoreSetup.styles";
import DoubleSelectContainer from "../DoubleSelect/DoubleSelectContainer";
import BvlgariText from "../TextElement/BvlgariText";
import { SelectOption } from "../../data/dataInterfaces";
import { __ } from "../../translations/translations";
import { Identifiers } from "../../utils/Indentifiers";
import { useJourneyStore } from "../../store/JourneyStore";
import { MotiView } from "moti";
import Constants from "expo-constants";

export interface IStoreSetupProps {
    config: any;
}

export function StoreSetup(props: IStoreSetupProps) {
    const appProd = Constants?.manifest?.extra?.APP_PROD;
    /* ---------------- METHODS ---------------- */
    const selectReturnData = (index: number, value: string, id: string) => {
        if (value === undefined) return;

        if (id === Identifiers.SETUP_STORE_LOCALE) {
            const options = props.config.select[0].options as SelectOption[];
            const option = options.find((o) => o.title === value);
            // console.log('locale', option)

            useJourneyStore.getState().setSelectedLocale(option!.id);
        }
        if (id === Identifiers.SETUP_STORE_ID_STORE) {
            const options = props.config.select[1].options as SelectOption[];
            const option = options.find((o) => o.title === value);
            // console.log('store', option)

            useJourneyStore.getState().setSelectedStore(value);
            useJourneyStore.getState().setSelectedStoreId(option!.id);
            useJourneyStore
                .getState()
                .setSelectedStoreCountry(option!.country!);
            useJourneyStore
                .getState()
                .updateUserDataField("CountryRegion", option!.country!);
        }
    };

    return (
        <MotiView
            from={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={() => {
                "worklet";
                return {
                    opacity: 0,
                };
            }}
            transition={{ type: "timing", duration: 1500 }}
            style={[styles.container]}
        >
            {/* ---------------- TITLE ---------------- */}
            {!appProd && (
                <>
                    <BvlgariText
                        text={"WARNING! AMBIENTE DI QUALITY"}
                        textType='mBRegular'
                        style={{
                            fontSize: 14,
                            textTransform: "uppercase",
                            fontWeight: "700",
                            color: "#ff0000",
                            alignSelf: "center",
                        }}
                    />
                    <BvlgariText
                        text={"WORK IN PROGRESS - NON USARE PER BUGFIXING"}
                        textType='mBRegular'
                        style={{
                            fontSize: 14,
                            textTransform: "uppercase",
                            fontWeight: "700",
                            color: "#ff0000",
                            alignSelf: "center",
                        }}
                    />
                </>
            )}
            {/* ---------------- TITLE ---------------- */}
            <BvlgariText
                text={"$storeSetup.title"}
                textType='mBRegular'
                style={{
                    fontSize: 28,
                    textTransform: "uppercase",
                    fontWeight: "700",
                    color: "#473213",
                }}
            />
            {/* ---------------- SELECT ---------------- */}
            <DoubleSelectContainer
                select={props.config.select}
                onReturnData={selectReturnData}
            />
        </MotiView>
    );
}
