import { StyleSheet, Dimensions } from 'react-native'

const { width, height } = Dimensions.get('window')
const isMobile = width <= 525

export const styles = StyleSheet.create({
  headerLogo: { height: 44, width: 144 },
  headerImageContainer: { flex: 1, alignItems: 'center', justifyContent: 'center' },

  headerContainer: {
    height: 100,
    maxHeight: 100,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    textAlignVertical: 'center',
    paddingHorizontal: 50,
    zIndex: 99999,
  },

  mobile: {
    height: 110,
    maxHeight: 110,
    paddingInline: 16,
  },

  mobileLeftContainer: {
    flexDirection: 'row',
    display: 'flex',
    flex: 1,
    gap: 12,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  mobileRightContainer: {
    display: 'flex',
    flex: 1,
    gap: 12,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },

  tablet: {
    height: 159,
    maxHeight: 159,
  },

  leftIcons: {
    width: 166,
    display: 'flex',
    gap: 12,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  rightIcons: {
    width: 166,
    display: 'flex',
    gap: 12,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
})
