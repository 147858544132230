import React, { useEffect, useState } from "react";
import { Pressable, View } from "react-native";
import { useStepManagerStore } from "../../../store/ExperienceStore";
import { styles } from "./LanguageSelect.style";
import SvgComponent from "../../Svg/SvgComponent";
import BvlgariText from "../../TextElement/BvlgariText";
import {
	askForUserConfirm,
	enumLanguage,
	translate,
} from "../../../utils/utils";
import { useJourneyStore } from "../../../store/JourneyStore";
import SoundPlayer from "../../../utils/SoundManager";

const LanguageSelect = ({ isMobile }: { isMobile?: boolean }) => {
	// ----------------------- ZUSTAND -----------------------
	const { isIos, setSelectedLocale } = useJourneyStore.getState();
	const { selectedLanguage, setNewLanguage } = useJourneyStore((state) => {
		return {
			selectedLanguage: state.selectedLanguage,
			setNewLanguage: state.setNewLanguage,
		};
	});
	// console.log('lingua', selectedLanguage)
	const [lang, setLang] = useState<string>(selectedLanguage);
	const [open, setOpen] = useState<boolean>(false);
	const [hover, setHover] = useState<number>(-1);

	const onOpen = () => {
		SoundPlayer.playSound("click");
		setOpen((prev) => !prev);
	};

	const onChangeSelectedLang = async (lang: string) => {
		try {
			await askForUserConfirm(
				"Language",
				translate("$changeLanguage.title"),
			);

			setNewLanguage(lang);
			if (isIos) setSelectedLocale(lang);
			onOpen();
		} catch (e) {
			onOpen();
		}
	};

	useEffect(() => {
		if (selectedLanguage !== lang) setLang(selectedLanguage);
	}, [selectedLanguage]);

	return (
		<Pressable
			style={[styles.container]}
			onPress={onOpen}
			accessibilityLabel='Language button'
		>
			<View
				style={[
					styles.iconTextContainer,
					open && styles.open,
					isMobile ? styles.mobileSizes : styles.originalSizes,
				]}
			>
				{/* <SvgComponent type={enumLanguage[lang]} /> */}
				<BvlgariText
					text={lang}
					textType='sBold'
					style={{ textTransform: "uppercase" }}
				/>
			</View>

			{
				<View style={[styles.dropdown, open && styles.dropdownOpen]}>
					{open &&
						Object.keys(enumLanguage).map((l, i) => (
							<Pressable
								key={i + 1999}
								style={[
									styles.dropdownItem,
									// hover style
									hover === i && styles.dropdownItemHover,
									// active style
									lang === l && styles.dropdownItemActive,
								]}
								onPress={() => onChangeSelectedLang(l)}
								onHoverIn={() => setHover(i)}
								onHoverOut={() => setHover(-1)}
							>
								{/* <SvgComponent type={enumLanguage[l]} /> */}
								<BvlgariText
									text={l}
									textType='sBold'
									style={{ textTransform: "uppercase" }}
								/>
							</Pressable>
						))}
				</View>
			}
		</Pressable>
	);
};

export default LanguageSelect;
