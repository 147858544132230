import { create } from "zustand";
import { useJourneyStore } from "./JourneyStore";
import { useStepManagerStore } from "./ExperienceStore";

export interface IReloadStore {
    toggleReloadExperience: (lang: string) => void;
}

export const useReloadStore = create<IReloadStore>((set, get) => ({
    toggleReloadExperience: (lang: string) => {
        useJourneyStore.getState().setSelectedLanguage(lang);

        const currentStepIndex =
            useStepManagerStore.getState().currentStepIndex;
        const setCurrentStepIndex =
            useStepManagerStore.getState().setCurrentStepIndex;
        setCurrentStepIndex(9);

        setTimeout(() => {
            setCurrentStepIndex(currentStepIndex);
            if (currentStepIndex > 4)
                useJourneyStore.getState().setRemoveOverlay(true);
        }, 200);
    },
}));
