import { Platform, StyleSheet } from "react-native";

export const styles = StyleSheet.create({
    shadow: {
        textShadowColor: "rgba(0, 0, 0, 0.35)",
        textShadowOffset: { width: -1, height: -1 },
        textShadowRadius: 6,
    },
    // --------- EXTRA LARGE TEXTS --------- //
    xlLight: {
        color: "#ffffff",
        fontSize: 34,
        fontFamily: "BulgariLight",
        fontWeight: "300",
    },
    xlLightUp: {
        color: "#ffffff",
        fontSize: 34,
        fontFamily: "BulgariLight",
        fontWeight: "300",
        textTransform: "uppercase",
    },
    xlBold: {
        color: "#ffffff",
        fontSize: 34,
        fontFamily: "BulgariBold",
        fontWeight: "bold",
        letterSpacing: 0.64,
    },
    xlBoldUp: {
        color: "#ffffff",
        fontSize: 34,
        fontFamily: "BulgariBold",
        fontWeight: "bold",
        textTransform: "uppercase",
    },
    // --------- LARGE TEXTS --------- //
    l: {
        color: "#ffffff",
        fontSize: 26,
        fontFamily: "BulgariBold",
        fontWeight: "400",
    },
    lBold: {
        color: "#ffffff",
        fontSize: 26,
        fontFamily: "BulgariBold",
        fontWeight: "bold",
    },
    // --------- MEDIUM TEXTS --------- //
    mBRegular: {
        color: "#ffffff",
        fontSize: 18,
        lineHeight: 20,
        fontFamily: "BulgariRegular",
        fontWeight: "400",
        letterSpacing: 0.6,
        ...Platform.select({ ios: {}, default: { wordSpacing: 2.2 } }),
    },
    mBRegularBtn: {
        color: "#ffffff",
        fontSize: 18,
        lineHeight: 20,
        fontFamily: "BulgariRegular",
        fontWeight: "400",
        letterSpacing: 1.28,
    },
    mBBold: {
        color: "#ffffff",
        fontSize: 18,
        lineHeight: 20,
        fontFamily: "BulgariBold",
        fontWeight: "bold",
        letterSpacing: 0.64,
    },
    mBBoldUnderline: {
        color: "#ffffff",
        fontSize: 18,
        lineHeight: 20,
        fontFamily: "BulgariBold",
        fontWeight: "bold",
        letterSpacing: 0.64,
        textDecorationLine: "underline",
    },
    mBBoldUp: {
        color: "#ffffff",
        fontSize: 18,
        lineHeight: 20,
        fontFamily: "BulgariBold",
        fontWeight: "bold",
        textTransform: "uppercase",
        letterSpacing: 1.28,
    },
    mRegular: {
        color: "#ffffff",
        fontSize: 18,
        lineHeight: 20,
        fontFamily: "BulgariRegular",
        textShadowColor: "rgba(0, 0, 0, 0.35)",
        textShadowOffset: { width: -1, height: -1 },
        textShadowRadius: 6,
        fontWeight: "400",
        letterSpacing: 0.6,
        ...Platform.select({
            ios: {},
            default: { wordSpacing: 2.2 },
        }),
    },
    mRegularNoShadow: {
        color: "#ffffff",
        fontSize: 18,
        lineHeight: 20,
        fontFamily: "BulgariRegular",
        textShadowColor: "rgba(0, 0, 0, 0)",
        textShadowOffset: { width: 0, height: 0 },
        textShadowRadius: 0,
        fontWeight: "400",
        letterSpacing: 0.6,
        ...Platform.select({
            ios: {},
            default: { wordSpacing: 2.2 },
        }),
    },
    mRegularBtn: {
        color: "#ffffff",
        fontSize: 18,
        lineHeight: 20,
        fontFamily: "BulgariRegular",
        textShadowColor: "rgba(0, 0, 0, 0.35)",
        textShadowOffset: { width: -1, height: -1 },
        textShadowRadius: 6,
        fontWeight: "400",
        letterSpacing: 1.28,
    },
    mBold: {
        color: "#ffffff",
        fontSize: 18,
        lineHeight: 20,
        fontFamily: "BulgariRegular",
        textShadowColor: "rgba(0, 0, 0, 0.35)",
        textShadowOffset: { width: -1, height: -1 },
        textShadowRadius: 6,
        fontWeight: "bold",
        letterSpacing: 0.64,
    },
    mBoldUnderline: {
        color: "#ffffff",
        fontSize: 18,
        lineHeight: 20,
        fontFamily: "BulgariRegular",
        textShadowColor: "rgba(0, 0, 0, 0)",
        textShadowOffset: { width: 0, height: 0 },
        textShadowRadius: 6,
        fontWeight: "bold",
        letterSpacing: 0.64,
        textDecorationLine: "underline",
    },
    mBoldUp: {
        color: "#ffffff",
        fontSize: 18,
        lineHeight: 20,
        fontFamily: "BulgariRegular",
        textShadowColor: "rgba(0, 0, 0, 0.35)",
        textShadowOffset: { width: -1, height: -1 },
        textShadowRadius: 6,
        fontWeight: "bold",
        textTransform: "uppercase",
        letterSpacing: 1.28,
    },
    // --------- REGULAR TEXTS --------- //
    sRegular: {
        color: "#ffffff",
        fontSize: 16,
        fontFamily: "BulgariBold",
        fontWeight: "400",
        textDecorationLine: "none",
    },
    sItalic: {
        color: "#ffffff",
        fontSize: 16,
        fontFamily: "BulgariBoldItalic",
        fontWeight: "400",
        fontStyle: "italic",
    },
    sBold: {
        color: "#ffffff",
        fontSize: 16,
        fontFamily: "BulgariBold",
        fontWeight: "bold",
    },
    capitalis: {
        color: "#ffffff",
        fontSize: 22,
        fontFamily: "BulgariCapitalis",
        lineHeight: 27.8,
        fontWeight: "400",
    },
    step: {
        color: "#512A04",
        textAlign: "center",
        textShadow: "0px 0px 16px #7A4612",
        fontFamily: "BulgariRegular",
        fontSize: 20,
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: 20 /* 100% */,
        letterSpacing: 3,
        textTransform: "uppercase",
    },
    title: {
        color: "#512A04",
        textAlign: "center",
        textShadow: "0px 0px 24px #FFF",
        fontFamily: "BulgariRegular",
        fontSize: 40,
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: 48 /* 120% */,
        letterSpacing: 5,
    },
    message: {
        color: "#95703B",
        textAlign: "center",
        fontFamily: "BulgariLightItalic",
        fontSize: 21,
        fontStyle: "italic",
        fontWeight: "300",
        lineHeight: 25 /* 161.905% */,
        paddingHorizontal: 20,

        textTransform: "uppercase",
        letterSpacing: 1,
    },
});
