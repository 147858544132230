import React, { useEffect, useRef } from "react";
import { Linking, Pressable, Text, TextStyle } from "react-native";
import { styles } from "./BvlgariText.style";
import { translate } from "../../utils/utils";
import { View } from "moti";

export type PropsTextType =
    | "xlLight"
    | "xlLightUp"
    | "xlBold"
    | "xlBoldUp"
    | "l"
    | "lBold"
    | "mBRegular"
    | "mBBold"
    | "mBBoldUnderline"
    | "mBBoldUp"
    | "mRegular"
    | "mRegularNoShadow"
    | "mBold"
    | "mBoldUnderline"
    | "mBoldUp"
    | "sRegular"
    | "sItalic"
    | "sBold"
    | "capitalis"
    | "step"
    | "title"
    | "message";

interface Props {
    text: string;
    textType?: PropsTextType;
    style?: React.CSSProperties;
    isRequired?: boolean;
    numOfLine?: number | undefined;
}

const BvlgariText = ({
    text,
    textType = "mRegular",
    style,
    isRequired,
    numOfLine,
}: Props) => {
    const textRef = useRef<Text>(null);
    const customStyle = style && (style as TextStyle);

    const onOpenLink = async () => {
        await Linking.openURL(
            translate("$loginPage.privacyInformation.linkPrivacy"),
        );
    };

    if (translate(text).includes("__")) {
        const textString = translate(text);
        /*   console.log("Continene un LINK"); */

        if (textString.includes("__")) {
            const textArr = textString.split("__");
            const firstPartOfText = textArr[0];
            const link = textArr[1];
            const secondPartOfText = textArr[2];

            return (
                <View
                    style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        gap: 4,
                        flexWrap: "wrap",
                    }}
                >
                    {firstPartOfText.split(" ").map((word) => (
                        <Text
                            style={[
                                styles[textType],
                                customStyle,
                                { paddingLeft: 0, paddingRight: 0 },
                            ]}
                        >
                            {word}
                        </Text>
                    ))}

                    {link.split(" ").map((word) => (
                        <Pressable onPress={onOpenLink}>
                            <Text
                                style={[
                                    styles[textType],
                                    customStyle,
                                    {
                                        textDecorationLine: "underline",
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    },
                                ]}
                            >
                                {word}
                            </Text>
                        </Pressable>
                    ))}

                    {secondPartOfText.split(" ").map((word) => (
                        <Text
                            style={[
                                styles[textType],
                                customStyle,
                                { paddingLeft: 0, paddingRight: 0 },
                            ]}
                        >
                            {word}
                            {isRequired && " *"}
                        </Text>
                    ))}
                </View>
            );
        }
    }

    return (
        <Text
            accessibilityLabel={
                translate(text).toLowerCase().includes("step")
                    ? `${translate(text)} of 3 steps`
                    : translate(text)
            }
            ref={textRef}
            style={[
                styles[textType],
                customStyle,
                { ...(numOfLine ? { maxWidth: "88%" } : {}) },
            ]}
            {...(numOfLine ? { numberOfLines: numOfLine } : {})}
        >
            {translate(text)}
            {isRequired && " *"}
        </Text>
    );
};

export default BvlgariText;
