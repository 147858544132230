import { View, Text } from "react-native";
import React from "react";
import styles from "./StepBar.styles";

export interface StepBarProps {
    totalSteps: number;
    activeStep: number;
}

export default function StepBar({ totalSteps, activeStep }: StepBarProps) {
    return (
        <View style={[styles.container]}>
            {Array.from({ length: totalSteps }, (_, i) => (
                <View
                    key={i}
                    style={[
                        styles.step,
                        {
                            backgroundColor:
                                i < activeStep
                                    ? "#473213"
                                    : "rgba(71, 50, 19, 0.5)",
                        },
                    ]}
                ></View>
            ))}
        </View>
    );
}
