import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  defaultContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 15,
    rowGap: 28,
    paddingLeft: 0,
    paddingRight: 0,
    transition: '.3s ease-in-out',
  },
  mobile: {
    maxWidth: 525,
    flexDirection: 'column',
  },
  table: {
    maxWidth: 525,
  },
  desktop: {
    maxWidth: 595,
    flexDirection: 'row',
  },

  viewText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 21,
    marginInline: 'auto',
  },

  registerMTop: {
    marginTop: 0,
  },

  inlineText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginInline: 'auto',
    width: '100%',
    gap: 6,
  },

  mT16: {
    marginTop: 16,
  },
})
