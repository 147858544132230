import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: "100%",
        width: "100%",
        maxWidth: 528,
        display: "flex",
        justifyContent: "center",
        gap: 48,
    },

    containerBrowserIpad: {
        flex: 1,
        height: "100%",
        width: "100%",
        maxWidth: 528,
        display: "flex",
        justifyContent: "center",
        gap: 30,
    },

    videoImageContainer: {
        marginHorizontal: "auto",
        height: 746,
        maxHeight: 746,
        width: "100%",
        maxWidth: 528,
        position: "relative",
    },

    video: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },

    image: { resizeMode: "contain", height: "100%", width: "100%" },
});
