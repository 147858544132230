import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  step: {
    width: 48,
    height: 2,
    borderRadius: 50,
    marginHorizontal: 2,
  },
})

export default styles
