import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    overscrollBehavior: 'none',
    height: '100%',
    width: '100%',
    overflowY: 'hidden',
    overflow: 'hidden',
    justifyContent: 'center',
  },

  background: {
    resizeMode: 'cover',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },

  backgroundOverlay: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 3,
  },

  backgroundOverlayLoading: {
    resizeMode: 'cover',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    opacity: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 3,
  },

  charmsLayer: {
    flex: 1,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 3,
  },
})
