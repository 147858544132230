import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  text: {
    fontSize: 32,
    fontWeight: '300',
    fontFamily: 'BulgariBold',
    color: '#FFFAEC',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
})
