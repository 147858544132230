import { Text } from 'react-native'
import { styles } from './Title.styles'

export interface ITitleProps {
  title: string
}

export function Title(props: ITitleProps) {
  return <Text style={styles.text}>{props.title}</Text>
}
