import {
	IMailList,
	ISendEmailData,
	ISendEmailDataOffline,
	UserJourneyData,
	callLogin,
	callRegister,
	callSendEmail,
	callSendEmailOffline,
	callTrackId,
} from "./../api/BulgariServices";
import { create } from "zustand";
import { useStepManagerStore } from "./ExperienceStore";
import { ImageSourcePropType } from "react-native";
import { ImageDataset } from "../configuration/ImageDataset";
import { TFalseModal } from "../data/dataInterfaces";
import {
	configInformativeError,
	configInformativeRegisterSuccess,
	configInformativeSuccessMailSent,
	configInformativeSuccessExperienceSent,
} from "../configuration/steps/authInformativeData";
import { askForUserConfirm, enumLanguage, translate } from "../utils/utils";
import { setLanguage } from "../translations/translations";
import { AvailableSteps } from "../configuration/configuration";
import { ITemplateMessage } from "../configuration/steps/mailTemplate";
import { ICharmType } from "../components/CharmSelector/CharmSelector";
import { SocketManager } from "../utils/SocketManager";
import Constants from "expo-constants";
import SoundPlayer from "../utils/SoundManager";
import { shopsOnline } from "../configuration/selectsDataset";

export type TMoodType = "joy" | "love" | "care";
export interface OperationsManager {
	// parametri statici per criptaggio
	experienceId: string;
	experienceQtyId: string;
	apiKEY: string;
	CRIPT_KEY: string;
	VECTOR_KEY: string;
	readerId: string;
	trackId: string;

	// jwt per online
	jwt: string | null;

	isIos: string | null;
	loginRegisterId: string;

	// datas
	selectedLanguage: string;
	newLanguage: string | null;
	selectedLocale: string;
	selectedStoreId: string;
	selectedStore: string;
	selectedStoreCountry: string;
	selectedMessageTemplate: ITemplateMessage | undefined;

	userData: UserJourneyData | null;
	emailToSend: string;
	openRegisterForm: boolean;
	selectedCharms: ICharmType[];
	toggleCharm?: (charm: ICharmType) => void;
	userPrivacyAuthorization: boolean;

	// open false modal
	falseModal: TFalseModal | null;
	falseModalHistory: any[];
	prevInfo: TFalseModal | null;
	// subStep false modal
	userSendMultiEmail: string[];
	userSendSelfEmail: boolean;
	userCanScanQR: boolean;

	// userConfirm ( modale anche del reload )
	userConfirm: {
		title: string;
		message: string;
		error:
			| "login"
			| "error"
			| "network-error"
			| "charms-error"
			| "check-email"
			| "error-mail"
			| undefined;
	} | null;

	//hide header & footer
	hideHeader: boolean;
	hideFooter: boolean;
	hideTitle: boolean;
	hideActionButton: boolean;
	removeOverlay: boolean;
	animateLottie: boolean;

	doExitAnimation: boolean;

	//background
	background: ImageSourcePropType;

	//mood
	mood: TMoodType;
	moodSwipe: number;
	canSwipe: boolean;
	urlVideoGenerated: string | undefined;
	urlVideoCube: string | undefined;

	// attribute methods
	setReaderId: (value: string) => void;
	setTrackId: (value: string) => void;
	setIsIos: (value: string | null) => void;
	setJwt: (value: string) => void;
	setLoginRegisterId: (value: string) => void;
	setSelectedLanguage: (language: string) => void;
	setNewLanguage: (language: string | null) => void;
	setSelectedLocale: (locale: string) => void;
	setSelectedStoreId: (id: string) => void;
	setSelectedStore: (store: string) => void;
	setSelectedStoreCountry: (store: string) => void;
	setSelectedMessageTemplate: (template: ITemplateMessage) => void;
	setHideHeader: (hide: boolean) => void;
	setHideFooter: (hide: boolean) => void;
	setRemoveOverlay: (remove: boolean) => void;

	setAnimateLottie: (animate: boolean) => void;
	// exit animation
	setDoExitAnimation: (
		type?: string,
		configData?: any,
		isBack?: boolean,
		removeOverlay?: boolean,
	) => void;

	setBackground: (image: ImageSourcePropType) => void;
	setUrlVideoGenerated: (url: string) => void;
	setMood: (mood: TMoodType) => void;
	setSwipeMood: (mood: number) => void;
	setCanSwipeMood: (can: boolean) => void;
	hideHeaderFooter: (header: boolean, footer: boolean) => void;
	setHideTitle: (hide: boolean) => void;
	setUserData: (data: UserJourneyData) => void;
	setEmailToSend: (email: string) => void;
	setOpenRegisterForm: (open: boolean) => void;
	setSelectedCharm: (charm: ICharmType) => void;
	updateUserDataField: (field: string, value: string) => void;
	setUserPrivacyAuthorization: (value: boolean) => void;

	// false modal
	onChangeFalseModal: (type: TFalseModal | null) => void;
	setPrevInfo: (type: TFalseModal | null) => void;
	updateSendMultiEmailField: (value: string, index: number) => void;
	removeSendMultiEmailField: (index: number) => void;
	toggleUserSendSelfEmail: (close?: boolean) => void;

	// userConfirm ( modale anche del reload )
	setUserConfirm: (
		title: string,
		message: string | null,
		error?:
			| "login"
			| "error"
			| "network-error"
			| "charms-error"
			| "check-email"
			| "error-mail"
			| undefined,
	) => void;

	/// Operations
	controlOperations: (data: UserJourneyData) => boolean;
	authenticate: () => void;
	login: (data: UserJourneyData) => void;

	register: (data: UserJourneyData) => void;

	clear: () => void;
	sendGift: (lastFalseModal: string) => void;

	clearUserData: () => void;

	// LOTTIE SCROLL
	//-------------- proprietà per CAROUSEl se il lottie è visibile o no dopo due scroll
	lottieScroll: boolean;
	setLottieScroll: (value: boolean) => void;
}

export const useJourneyStore = create<OperationsManager>((set, get) => ({
	stepDataset: AvailableSteps(),
	// static parameters for encryption
	experienceId: "d8ee9df8-1b6f-4405-b49d-c4ae8c9e8fee", // PROD
	experienceQtyId: "dfc48ae9-f844-43c0-842e-cad360a8a079", // QTY
	apiKEY: "cnjgFIhTXeABRORd9LPmu4QqPlLfDTFZTHWi1cCCv_k1AzFuxUIj0A==",
	CRIPT_KEY: "SCYjWHlHY2JxWDZaRyVkLVhaQyp3ciRFP1JwNjcjTTY=",
	VECTOR_KEY: "TjZiJS54bjhwZ3MqSnohQA==",
	/* experienceId_uid: experienceId + '_' + isOffline, */
	readerId: "",
	trackId: "",

	// jwt for online
	jwt: null,

	// datas
	isIos: null,
	loginRegisterId: "",
	selectedLanguage: "en",
	newLanguage: null,
	selectedLocale: "",
	selectedStoreId: "",
	selectedStore: "",
	selectedStoreCountry: "",
	selectedMessageTemplate: undefined,
	userData: null,
	emailToSend: "",
	openRegisterForm: false,
	userPrivacyAuthorization: false,
	selectedCharms: [],
	hideHeader: false,
	hideFooter: false,
	hideTitle: false,
	hideActionButton: false,
	removeOverlay: false,
	animateLottie: false,
	// exit animation
	doExitAnimation: false,
	urlVideoGenerated: undefined,
	urlVideoCube: undefined,

	background: ImageDataset.background,
	mood: "joy",
	moodSwipe: 0,
	moodOldSwipe: 0,
	canSwipe: true,

	falseModal: null,
	falseModalHistory: [null],
	prevInfo: null,
	userSendMultiEmail: [" "],
	userSendSelfEmail: false,
	userCanScanQR: false,

	// userConfirm ( modale anche del reload )
	userConfirm: null,
	// LOTTIE SCROLL ON CAROUSEL
	lottieScroll: true,
	setLottieScroll: (value: boolean) => set(() => ({ lottieScroll: value })),

	// attribute methods
	setReaderId: (value: string) => set(() => ({ readerId: value })),
	setTrackId: (value: string) => set(() => ({ trackId: value })),
	setIsIos: (value: string | null) => set(() => ({ isIos: value })),
	setJwt: (value: string) => set(() => ({ jwt: value })),
	setLoginRegisterId: (value: string) =>
		set(() => ({ loginRegisterId: value })),
	setSelectedLanguage: (language: string) => {
		setLanguage(enumLanguage[language] ? language : "en");

		set(() => ({
			selectedLanguage: enumLanguage[language] ? language : "en",
		}));
	},
	setNewLanguage: (language: string | null) => {
		if (language === null) return set(() => ({ newLanguage: null }));
		set(() => ({ newLanguage: enumLanguage[language] ? language : "en" }));
	},
	setSelectedLocale: (locale: string) => {
		localStorage.setItem("BG_locale", locale);
		set(() => ({ selectedLocale: locale }));
	},
	setSelectedStoreId: (id: string) => {
		localStorage.setItem("BG_localStoreId", id);
		set(() => ({ selectedStoreId: id }));
	},
	setSelectedStore: (store: string) => {
		localStorage.setItem("BG_localStore", store);
		set(() => ({ selectedStore: store }));
	},
	setSelectedStoreCountry: (storeCountry: string) => {
		localStorage.setItem("BG_localStoreCountry", storeCountry);
		set(() => ({ selectedStoreCountry: storeCountry }));
	},
	setSelectedMessageTemplate: (template: ITemplateMessage) =>
		set(() => ({ selectedMessageTemplate: template })),
	setHideHeader: (hide: boolean) => set(() => ({ hideHeader: hide })),
	setHideFooter: (hide: boolean) => set(() => ({ hideFooter: hide })),
	setHideTitle: (hide: boolean) => set(() => ({ hideTitle: hide })),
	setHideActionButton: (hide: boolean) =>
		set(() => ({ hideActionButton: hide })),
	setRemoveOverlay: (remove: boolean) =>
		set(() => ({ removeOverlay: remove })),

	setAnimateLottie: (animate: boolean) =>
		set(() => ({ animateLottie: animate })),

	// exit animation
	setDoExitAnimation: (type, configData, isBack, removeOverlay) => {
		//console.log('type', type)

		switch (type) {
			case "charms":
				if (get().selectedCharms?.length < 3) {
					askForUserConfirm(
						"",
						translate("$errors.selectThreeCharms"),
					);
					return;
				}
				set(() => ({ animateLottie: false }));
				break;
			case "send_gift":
				set(() => ({ falseModal: null }));
				setTimeout(() => {
					useStepManagerStore.getState().increaseStep(configData);
				}, 100);
				return;

			case "mood_selector":
				setTimeout(() => {
					set(() => ({ animateLottie: true }));
				}, 3000);
				break;
			case "final_confirm":
				set(() => ({ falseModal: null, removeOverlay: false }));
				useStepManagerStore.getState().increaseStep(configData);
				return;

			default:
				break;
		}
		set(() => ({ doExitAnimation: true }));
		const { currentStepIndex } = useStepManagerStore.getState();

		setTimeout(() => {
			set(() => ({ doExitAnimation: false }));
			if (currentStepIndex === 8) return;

			isBack
				? useStepManagerStore.getState().decreaseStep(configData)
				: useStepManagerStore.getState().increaseStep(configData);
		}, 1500);
	},

	setBackground: (image: ImageSourcePropType) =>
		set(() => ({ background: image })),
	setUrlVideoGenerated: (url: string) => {
		//    SocketManager.getInstance().emitMessage(url)
		//SocketManager.getInstance().sendSocketCubeMessage(url)
		set(() => ({ urlVideoGenerated: url }));
	},

	setMood: (mood: TMoodType) => set(() => ({ mood: mood })),
	setSwipeMood: (mood: number) => {
		const { moodSwipe, setMood } = get();

		let swipe: number = moodSwipe + mood;
		if (swipe > 2) swipe = 0;
		if (swipe < 0) swipe = 2;

		swipe === 0 && setMood("joy");
		swipe === 1 && setMood("love");
		swipe === 2 && setMood("care");

		set(() => ({ moodSwipe: swipe }));
	},
	setCanSwipeMood: (can: boolean) => set(() => ({ canSwipe: can })),
	hideHeaderFooter: (header: boolean, footer: boolean) =>
		set(() => ({ hideHeader: header, hideFooter: footer })),
	setUserData: (data: UserJourneyData) => set(() => ({ userData: data })),
	setEmailToSend: (email: string) => set(() => ({ emailToSend: email })),
	setOpenRegisterForm: (open: boolean) =>
		set(() => ({ openRegisterForm: open })),
	setSelectedCharm: (charm: ICharmType) =>
		set(() => ({ selectedCharms: [...get().selectedCharms, charm] })),
	setUserPrivacyAuthorization: (value: boolean) =>
		set(() => ({ userPrivacyAuthorization: value })),

	updateUserDataField: (field: string, value: string) => {
		const { userData } = get();

		let data_: any = userData;
		if (!userData) {
			data_ = {};
		}

		if (field === "Interest" && value.length > 2) {
			data_[field] = value;
		} else data_[field] = value;
		//console.log('----', data_[field])

		set(() => ({ userData: data_ }));
	},

	// ---------------- false modal
	onChangeFalseModal: (type: TFalseModal | null) => {
		const { prevInfo } = get();
		set({ falseModalHistory: [...get().falseModalHistory, type] });
		if (type === "prev") {
			set(() => ({ falseModal: prevInfo }));
			return;
		}
		set(() => ({ falseModal: type }));
	},

	setPrevInfo: (type: TFalseModal | null) => set(() => ({ prevInfo: type })),

	updateSendMultiEmailField: (value: string, index: number) => {
		const { userSendMultiEmail } = get();
		let data_: any = userSendMultiEmail;
		data_[index] = value;
		set(() => ({ userSendMultiEmail: [...userSendMultiEmail] }));
	},

	removeSendMultiEmailField: (index: number) => {
		const { userSendMultiEmail } = get();

		userSendMultiEmail.splice(index, 1);

		set(() => ({ userSendMultiEmail: [...userSendMultiEmail] }));
	},

	toggleUserSendSelfEmail: (close?: boolean) => {
		const { userSendSelfEmail } = get();
		set(() => ({ userSendSelfEmail: close ? close : !userSendSelfEmail }));
	},

	// userConfirm ( modale anche del reload )
	setUserConfirm: (
		title: string = "",
		message: string | null,
		error = undefined,
	) => {
		if (!message) set(() => ({ userConfirm: null }));
		if (message) set(() => ({ userConfirm: { title, message, error } }));
	},

	/// Operations
	controlOperations: (data: UserJourneyData) => {
		const { userPrivacyAuthorization } = get();

		if (!userPrivacyAuthorization) {
			askForUserConfirm(
				"",
				translate("$errors.fillACheckboxField") +
					translate(
						"$loginPage.privacyInformation.title",
					).toUpperCase(),
				"check-email",
			);
			return false;
		}

		if (data.FirstName) {
			const regex = /^[a-zA-Z ]{3,40}$/;
			const isValid = regex.test(data.FirstName);

			if (!isValid) {
				askForUserConfirm(
					"",
					'Please fill in the field with your "Name" with a valid name',
					"check-email",
				);
				return false;
			}
		}

		if (!data.FirstName) {
			askForUserConfirm(
				"",
				translate("$errors.fillAField") +
					translate("$loginPage.inputName.label").toUpperCase(),
				"check-email",
			);
			return false;
		}

		if (data.LastName) {
			const regex = /^[a-zA-Z ]{2,40}$/;
			const isValid = regex.test(data.LastName);

			if (!isValid) {
				askForUserConfirm(
					"",
					translate("$errors.fillWithAValidValue") +
						translate(
							"$loginPage.inputSurname.label",
						).toUpperCase(),
					"check-email",
				);
				return false;
			}
		}

		if (!data.LastName) {
			askForUserConfirm(
				"",
				translate("$errors.fillAField") +
					translate("$loginPage.inputSurname.label").toUpperCase(),
				"check-email",
			);
			return false;
		}

		if (data.PersonEmail) {
			const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
			const isValid = regex.test(data.PersonEmail);

			if (!isValid) {
				askForUserConfirm(
					"",
					translate("$errors.fillWithAValidValue") +
						translate("$loginPage.inputEmail.label").toUpperCase(),
					"check-email",
				);
				return false;
			}
		}
		if (!data.PersonEmail) {
			askForUserConfirm(
				"",
				translate("$errors.fillAField") +
					translate("$loginPage.inputEmail.label").toUpperCase(),
				"check-email",
			);
			return false;
		}

		if (!data.CountryRegion) {
			askForUserConfirm(
				"",
				translate("$errors.fillAField") +
					translate("$loginPage.selectCountry.title").toUpperCase(),
				"check-email",
			);
			return false;
		}

		if (!data.ProfileAuthorization) {
			data.ProfileAuthorization = false;
		}

		if (!data.MarketingAuthorization) {
			data.MarketingAuthorization = false;
		}
		set(() => ({ userData: data }));

		return true;
	},

	// --- call api
	/// Operations
	login: async (data: UserJourneyData) => {
		const { controlOperations, falseModal, sendGift } = get();

		const controls = controlOperations(data);

		if (!controls) return;
		let registerFrom: string = "";
		if (falseModal === "send") {
			registerFrom = "send";
			get().setDoExitAnimation("send_gift");
		}
		if (falseModal === "invite") {
			registerFrom = "invite";
			get().setDoExitAnimation("send_gift");
		}
		if (falseModal === "qr") {
			registerFrom = "qr";
			set(() => ({ falseModal: null }));
			useStepManagerStore.getState().increaseStep();
		}

		const loginData = data;

		loginData.LastName.replace(/^\s+|\s+$/g, "");

		console.log(get().isIos);
		console.log(loginData);
		if (loginData.FirstName === undefined) loginData.FirstName = "";
		else
			loginData.FirstName = loginData.FirstName.replace(/^\s+|\s+$/g, "");
		try {
			const response: any = await callLogin(loginData);
			// console.log(response)
			set(() => ({ loginRegisterId: response.Id }));
			setTimeout(() => {
				if (falseModal !== "qr") {
					setTimeout(() => {
						set(() => ({ hideFooter: true, hideHeader: true }));
					}, 800);
					setTimeout(() => {
						/*     console.log("registrato", response); */
						sendGift(falseModal!);
						return;
					}, 2500);
				} else if (falseModal === "qr") {
					set(() => ({ userCanScanQR: true }));
					useStepManagerStore.getState().decreaseStep();
					set(() => ({
						falseModal: "qr",
						hideFooter: false,
						hideHeader: false,
					}));
				}
			}, 2000);

			/*    setTimeout(() => {
        useStepManagerStore.getState().increaseStep(configInformativeLoginSuccess)
      }, 2500) */
		} catch (e: any) {
			console.log(e);
			console.log(e.request.status);
			//console.log(e)
			setTimeout(() => {
				const { userData } = get();

				if (e.request.status === 404) {
					get().register(userData!);
				} else if (e.code === "ERR_NETWORK") {
					useStepManagerStore.getState().decreaseStep();
					askForUserConfirm(
						translate("$errors.noInternetConnectionHint"),
						translate("$errors.noInternetConnection"),
						"network-error",
					);
				} else {
					useStepManagerStore.getState().decreaseStep();
					askForUserConfirm(
						"",
						translate("$errors.somethingWentWrong") +
							" " +
							translate("$errors.somethingWentWrongHint"),
						"error",
					);
				}
			}, 3000);
		}
	},
	register: async (data: UserJourneyData) => {
		const {
			isIos,
			selectedLocale,
			selectedStoreId,
			selectedStore,
			sendGift,
			falseModal,
			falseModalHistory,
		} = get();

		/*     const { controlOperations } = get();

        const controls = controlOperations(data); */

		let registerFrom: string = "";
		/*    if (!controls) return; */
		if (falseModalHistory[0] === "send") {
			registerFrom = "send";
			/*   get().setDoExitAnimation("send_gift"); */
		}
		if (falseModalHistory[0] === "invite") {
			registerFrom = "invite";
			/*   get().setDoExitAnimation("send_gift"); */
		}
		if (falseModalHistory[0] === "qr") {
			registerFrom = "qr";
			set(() => ({ falseModal: null }));
			/*  useStepManagerStore.getState().increaseStep(); */
		}

		const shopOnline = isIos
			? shopsOnline.find((s) => s.COUNTRY === data.CountryRegion)
			: undefined;

		const IsOnlineShop = {
			Shop: shopOnline ? shopOnline.CODE : selectedStoreId,
			ShopName: shopOnline ? shopOnline.NAME : selectedStore,
		};

		const registerData = {
			...data,
			FirstName: data.FirstName?.toLowerCase() ?? "", // name input
			LastName: data.LastName.toLowerCase(), // surname input
			CountryRegion: data.CountryRegion, // country select
			PersonEmail: data.PersonEmail.toLowerCase(), // email input
			Title: "0379",
			ProfileAuthorization: data.ProfileAuthorization, // checkbox
			MarketingAuthorization: data.MarketingAuthorization, // checkbox
			Source: "042",
			DataAcquisitionType: "AOG",
			Subsource: "Generic_24",
			PersonMobilePhone: "",
			Prefix: "",
			Locale: selectedLocale,
			Shop: IsOnlineShop.Shop,
			ShopName: IsOnlineShop.ShopName,
		};

		registerData.LastName.replace(/^\s+|\s+$/g, "");

		if (registerData.FirstName === undefined) registerData.FirstName = "";
		else
			registerData.FirstName = registerData.FirstName.replace(
				/^\s+|\s+$/g,
				"",
			);
		// --- call api
		try {
			const response: any = await callRegister(registerData);
			// console.log(response)
			set(() => ({ loginRegisterId: response.Id }));
			console.log(registerFrom);
			if (registerFrom !== "qr") {
				setTimeout(() => {
					set(() => ({ hideFooter: true, hideHeader: true }));
				}, 800);
				setTimeout(() => {
					/*     console.log("registrato", response); */
					sendGift(registerFrom);
					return;
				}, 3500);
			} else if (registerFrom === "qr") {
				set(() => ({ userCanScanQR: true }));
				useStepManagerStore.getState().decreaseStep();
				set(() => ({
					falseModal: "qr",
					hideFooter: false,
					hideHeader: false,
				}));
			}
		} catch (e: any) {
			//console.log(e)
			/* useStepManagerStore.getState().increaseStep(configInformativeError) */
			setTimeout(() => {
				if (e.code === "ERR_NETWORK") {
					setTimeout(
						() =>
							askForUserConfirm(
								translate("$errors.noInternetConnectionHint"),
								translate("$errors.noInternetConnection"),
								"network-error",
							),
						1000,
					);
				} else if (e.request.status === 404) {
					useStepManagerStore.getState().decreaseStep();
					setTimeout(
						() =>
							askForUserConfirm(
								"",
								translate("$errors.somethingWentWrong") +
									"/n" +
									translate("$errors.somethingWentWrongHint"),
								"check-email",
							),
						1000,
					);
				} else {
					useStepManagerStore.getState().decreaseStep();
					setTimeout(
						() =>
							askForUserConfirm(
								"",
								translate("$errors.somethingWentWrong") +
									"/n" +
									translate("$errors.somethingWentWrongHint"),
								"check-email",
							),
						1000,
					);
				}
			}, 3000);
		}
	},
	authenticate: () => {
		try {
			const { userData, falseModal } = get();

			set(() => ({ falseModalHistory: [falseModal] }));

			if (!userData) {
				askForUserConfirm(
					"",
					translate("$errors.fillAllFields"),
					"check-email",
				);
				return;
			}
			get().login(userData);
			return;
			/*    get().register(userData); */
		} catch (e) {
			//console.log(e)
		}
	},

	clear: () => {
		set(() => ({
			prevInfo: null,
			removeOverlay: false,
			selectedLanguage: "",
			mood: undefined,
			urlVideoGenerated: undefined,
			userData: null,
			hideHeader: false,
			hideFooter: false,
			background: ImageDataset.background,
			userCanScanQR: false,
			falseModal: null,
			userSendMultiEmail: [],
			userSendSelfEmail: false,
			selectedCharms: [],
			animateLottie: false,
			openRegisterForm: false,
		}));
	},

	sendGift: async (lastFalseModal: string) => {
		const {
			falseModal,
			selectedLanguage,
			isIos,
			loginRegisterId,
			readerId,
			jwt,
			urlVideoGenerated,
			selectedMessageTemplate,
			emailToSend,
			userSendSelfEmail,
		} = get();

		const userSelfSendEmail = userSendSelfEmail;
		const userSendEmail = emailToSend.toLowerCase();
		/*  console.log("userSendEmail", userSendEmail); */

		let selectedMessageForEmail =
			selectedMessageTemplate?.id === "7"
				? ""
				: selectedMessageTemplate?.text;

		if (!userSendEmail) {
			askForUserConfirm(
				"",
				translate("$errors.fillAField") +
					translate(
						"Inserisci una mail per inviare il regalo",
					).toUpperCase(),
				"check-email",
			);
			return false;
		}

		if (userSendEmail) {
			const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
			const isValid = regex.test(userSendEmail);

			if (!isValid) {
				askForUserConfirm(
					"",
					translate("$errors.fillWithAValidValue") +
						translate("$loginPage.inputEmail.label").toUpperCase(),
					"check-email",
				);
				return false;
			}
		}

		let data: any;
		set(() => ({
			falseModal: null,
			emailToSend: "",
			userSendSelfEmail: false,
		}));

		try {
			// ------ call track id

			const response: any = await callTrackId({
				readerId: encodeURIComponent(readerId),
				salesForceId: encodeURIComponent(loginRegisterId),
			});

			if (response) {
				const appProd = Constants?.manifest?.extra?.APP_PROD;

				data = {
					trackId: response.trackId,
					anyPoint: {
						id: loginRegisterId,
						LanguageCode: selectedLanguage,
						urlArtwork: urlVideoGenerated,
						SendToMyself: userSelfSendEmail,
						definitionKey: appProd
							? "ArtOfGifting_PROD"
							: "ArtOfGifting_QA", // QTY
						// definitionKey: "ArtOfGifting_PROD", // PROD
						imageLink: "",
						DynamicText: translate(selectedMessageForEmail!),
						mailingList: [
							{
								email: userSendEmail,
								DynamicText: translate(
									selectedMessageForEmail!,
								),
								ArtworkToMyFriends:
									lastFalseModal === "send" ? true : false,
								JoinTheExperience:
									lastFalseModal === "invite" ? true : false,
							},
						] as IMailList[],
					},
				};

				// ------ call send email
				// console.log('faccio partire la seconda chiamta')
				const res = await callSendEmailOffline(
					data as ISendEmailDataOffline,
				);

				const isInvite = lastFalseModal === "invite" ? true : false;
				if (res) {
					set(() => ({
						falseModal: null,
						userSendMultiEmail: [],
						userSendSelfEmail: false,
					}));
					get().setDoExitAnimation(
						"final_confirm",
						isInvite
							? configInformativeSuccessExperienceSent
							: configInformativeSuccessMailSent,
					);
				}
			}
		} catch (error: any) {
			console.log("error", error);
			setTimeout(() => {
				set(() => ({
					falseModal: null,
					userSendMultiEmail: [],
					userSendSelfEmail: false,
					hideHeader: false,
					hideFooter: false,
					hideTitle: false,
				}));
				// get().setDoExitAnimation('final_confirm', configInformativeError)

				if (error.code === "ERR_NETWORK") {
					setTimeout(
						() =>
							askForUserConfirm(
								translate("$errors.noInternetConnectionHint"),
								translate("$errors.noInternetConnection"),
								"network-error",
							),
						1000,
					);
					return;
				}

				askForUserConfirm(
					"",
					translate("$errors.somethingWentWrong") +
						" " +
						translate("$errors.somethingWentWrongHint"),
					"error",
				);
				useStepManagerStore.getState().decreaseStep();
			}, 2000);
		}
	},

	clearUserData: () => {
		const dataUser = get().userData?.CountryRegion;
		set(() => ({
			userData: { CountryRegion: dataUser } as UserJourneyData,
			moodSwipe: 0,
			selectedMessageTemplate: undefined,
			selectedCharms: [],
		}));
		get().setSwipeMood(0);
		get().setMood("joy");
		setTimeout(
			() => get().updateUserDataField("CountryRegion", dataUser!),
			300,
		);
	},

	toggleCharm: (charm: ICharmType) => {
		const selectedCharms = get().selectedCharms;
		const newCharmList = selectedCharms.some((c) => c.id === charm.id)
			? selectedCharms.filter((c) => c.id !== charm.id)
			: [...selectedCharms, charm];
		if (newCharmList.length > selectedCharms.length)
			SoundPlayer.playSound("charmSelected");

		if (newCharmList.length < selectedCharms.length)
			SoundPlayer.playSound("charmUnselected");

		set((state) => ({ selectedCharms: newCharmList }));
	},
}));
