import { ResizeMode, Video } from "expo-av";
import React, { useEffect, useRef, useState } from "react";
import { FlatList, View } from "react-native";
import { styles } from "./FinalVideoViewer.styles";
import { getIsMadrid } from "../../utils/utils";
import BvlgariText from "../TextElement/BvlgariText";
import SoundPlayer from "../../utils/SoundManager";

interface Props {
    videoGenerated: string;
    swipeData: any[];
    swipe: "left" | "right";
}

const CarouselFinalVideo = ({ videoGenerated, swipe, swipeData }: Props) => {
    const [statusScreen, setStatusScreen] = useState<any>({});
    /* --------------  REF -------------- */
    let flatListRef = useRef<FlatList<any[]> | null>(null);
    const videoRef = useRef<Video>(null);

    useEffect(() => {
        // vai al video
        if (swipe === "left") {
            flatListRef.current?.scrollToOffset({
                animated: true,
                offset: -400,
            });
            SoundPlayer.playSound("swipe");
        }
        // vai al messaggio
        if (swipe === "right") {
            flatListRef.current?.scrollToOffset({
                animated: true,
                offset: 400,
            });
            SoundPlayer.playSound("swipe");
        }
    }, [swipe]);

    setTimeout(() => videoRef.current?.playAsync(), 300);

    return (
        <View
            style={{
                overflow: "hidden",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
            }}
        >
            <FlatList
                ref={(r) => (flatListRef.current = r)}
                style={{ width: getIsMadrid() ? "73%" : "65%" }}
                data={swipeData}
                alwaysBounceHorizontal={true}
                renderItem={({ item }) => {
                    // -----------------  VIDEO  -----------------
                    if (item === "video")
                        return (
                            <View
                                style={{
                                    marginRight: 10,
                                    minHeight: 746,
                                    minWidth: getIsMadrid() ? 400 : 350,
                                    width: getIsMadrid() ? 400 : 350,
                                }}
                            >
                                <Video
                                    ref={videoRef}
                                    style={[styles.video]}
                                    source={{ uri: videoGenerated }}
                                    useNativeControls={false}
                                    isMuted={true}
                                    isLooping={true}
                                    resizeMode={ResizeMode.COVER}
                                    onPlaybackStatusUpdate={(status) =>
                                        setStatusScreen(() => status)
                                    }
                                    shouldPlay={true}
                                    videoStyle={{
                                        marginHorizontal: "auto",
                                        marginVertical: "auto",
                                        maxHeight: 746,
                                        width: "100%",
                                        aspectRatio: getIsMadrid()
                                            ? 1 / 1
                                            : 9 / 16,
                                    }}
                                    onLoadStart={() => {
                                        setTimeout(
                                            () => videoRef.current?.playAsync(),
                                            300,
                                        );
                                    }}
                                />
                            </View>
                        );
                    // -----------------  MESSAGE  -----------------
                    else
                        return (
                            <View
                                style={{
                                    height: 746,
                                    width: getIsMadrid() ? 400 : 350,
                                }}
                            >
                                <View
                                    style={{
                                        marginHorizontal: "auto",
                                        marginVertical: "auto",
                                        maxHeight: 746,
                                        width: getIsMadrid() ? "100%" : "100%",
                                        maxWidth: getIsMadrid() ? 400 : 350,
                                        aspectRatio: getIsMadrid()
                                            ? 1 / 1
                                            : 9 / 16,
                                    }}
                                >
                                    <BvlgariText
                                        text={swipeData[1]}
                                        textType='xlLight'
                                        style={{
                                            color: "rgba(175, 132, 69, 1)",
                                            fontSize: 22,
                                            textAlign: "center",
                                            fontStyle: "italic",
                                            marginTop: "auto",
                                            marginBottom: "auto",
                                        }}
                                    />
                                </View>
                            </View>
                        );
                }}
                keyExtractor={(item, index) => index.toString()}
                horizontal
                pagingEnabled
                showsHorizontalScrollIndicator={false}
            />
        </View>
    );
};

export default CarouselFinalVideo;
