import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  stepContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    gap: 15,
    textAlign: 'center',
    paddingTop: 30,
    paddingInline: 16,
    zIndex: 8,
  },
})
