import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  prefix: {
    backgroundColor: 'rgba(71, 50, 19, 0.5)',
    height: 48,
    maxHeight: 48,
    color: '#ffffff',
    fontSize: 16,
    fontWeight: '400',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 24,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(255, 250, 236, 0.8)',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0,
    borderRightColor: 'rgba(71, 50, 19, 0.5)',
    transition: '.3s ease-in-out',
    zIndex: 10,
  },

  errorInput: {
    borderColor: 'rgba(255, 88, 88, 0.8)',
  },

  borderForOpenDropdown: {
    borderBottomLeftRadius: 0,
  },

  dropdown: {
    position: 'absolute',
    top: 42,
    left: -1,
    right: 0,
    height: 0,
    maxHeight: 0,
    backgroundColor: '#473213',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
    borderWidth: 0,
    borderStyle: 'solid',
    borderRadius: 8,
    borderTopWidth: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    padding: 0,
    transition: '.3s ease-in-out',
    zIndex: 9,
    overflow: 'hidden',
  },

  dropdownOpen: {
    top: 46,
    maxHeight: 200,
    height: 200,
    borderWidth: 1,
    borderColor: 'rgba(255, 250, 236, 0.8)',
    paddingTop: 16,
    paddingBottom: 16,
    overflow: 'scroll',
  },

  item: {
    width: '100%',
    padding: 8,
  },

  itemHover: {
    backgroundColor: 'rgba(255, 250, 236, 0.8)',
    borderRadius: 8,
  },

  itemActive: {
    backgroundColor: 'rgba(255, 250, 236, 0.8)',
    borderRadius: 8,
    fontWeight: '700',
  },
})
