import { glows } from "../../../assets/glows";
import { outline } from "../../../assets/outline";
import IStep, { OperationType } from "../../data/dataInterfaces";
import { useStepManagerStore } from "../../store/ExperienceStore";
import { useJourneyStore } from "../../store/JourneyStore";
import { Identifiers } from "../../utils/Indentifiers";
import { translate } from "../../utils/utils";
import { ImageDataset } from "../ImageDataset";

export const charmSelector: IStep = {
    title: "",
    id: Identifiers.CHARM_SELECTOR,
    operations: {
        type: OperationType.charmSelection,
        config: {
            step: 2,
            title: "$charmSelectionPage.title",
            charmsToBeSelected: 3,
            charmSelectedText: "$charmSelectionPage.charmSelectedText3",
            charmsObjects: [
                {
                    // b
                    title: "Charm b",
                    x: 961,
                    y: 602,
                    width: 160,
                    height: 220,
                    id: "1",
                    glowImage: glows.B,
                    outlineImage: outline.B,
                },
                {
                    // box
                    title: "Charm box",
                    x: 820,
                    y: 360,
                    width: 220,
                    height: 220,
                    rotate: -20,
                    id: "2",
                    glowImage: glows.box,
                    outlineImage: outline.box,
                },
                {
                    // heart
                    title: "Charm heart",
                    x: 955,
                    y: 798,
                    width: 140,
                    height: 130,
                    id: "3",
                    zIndex: 9,
                    glowImage: glows.heart,
                    outlineImage: outline.heart,
                },
                {
                    // infinito
                    title: "Charm infinito",
                    x: 1172,
                    y: 722,
                    width: 170,
                    height: 140,
                    rotate: -19,
                    id: "4",
                    glowImage: glows.infinite,
                    outlineImage: outline.infinite,
                },
                {
                    // moon
                    title: "Charm moon",
                    x: 744,
                    y: 649,
                    width: 145,
                    height: 145,
                    id: "5",
                    zIndex: 9,
                    glowImage: glows.moon,
                    outlineImage: outline.moon,
                },
                {
                    // star
                    title: "Charm star",
                    x: 1097,
                    y: 315,
                    width: 145,
                    height: 160,
                    id: "6",
                    glowImage: glows.star,
                    outlineImage: outline.star,
                },
            ],
            action: {
                id: "charmSelector",
                title: "Confirm",
                btnType: "filled",
                size: "default",
                action: () => {
                    useJourneyStore.getState().setDoExitAnimation("charms");
                },
            },
        },
    },
};
