import React, { useEffect, useState } from "react";
import {
    Dimensions,
    Platform,
    Pressable,
    SafeAreaView,
    ScrollView,
    View,
} from "react-native";
import { styles } from "./FormContainer.style";
import BvlgariText from "../TextElement/BvlgariText";
import { InputEl } from "../../data/dataInterfaces";
import BvlgariTitle from "../TitleElement/BvlgariTitle";
import RegisterDropdown from "./registerDropdown/RegisterDropdown";
import { __ } from "../../translations/translations";
import { useJourneyStore } from "../../store/JourneyStore";
import { Identifiers } from "../../utils/Indentifiers";
import { FormGenerator } from "./Generator/FormGenerator";
import { MotiView } from "moti";
import { addZero, translate } from "../../utils/utils";

interface Props {
    config: any;
}

const FormContainer = ({ config }: Props) => {
    const [mobileView, setMobileView] = useState<boolean>(
        Platform.OS === "android" || Platform.OS === "ios",
    );
    const [windowWidth, setWindowWidth] = useState<number>(
        Dimensions.get("window").width,
    );
    const [animationData, setAnimationData] = useState<{
        opacityStart: number;
        opacityEnd: number;
    }>({
        opacityStart: 0,
        opacityEnd: 1,
    });

    // -------- FOR RESTART BARBATRUCCO
    const [reload, setReload] = useState<boolean>(false);
    const unsubReloadExperience = useJourneyStore.subscribe((state) => {
        state.userData === null && setReload(!reload);
    });

    // ----------------------- Login State -----------------------
    const [inputValues, setInputValues] = useState<InputEl[]>(
        config.input as InputEl[],
    );
    /*   const [emailError, setEmailError] = useState<boolean>(false) */

    // ----------------------- Register State -----------------------
    const [openRegister, setOpenRegister] = useState<boolean>(false);

    // controllo viewport width se non siamo nella mobile mode
    const controlViewport = () => {
        const { width } = Dimensions.get("window");
        setMobileView(width < 525); // 600 per dire che è mobile
        setWindowWidth(width);
    };

    const onOpenRegisterField = () => {
        // ENABLE REGISTRATION MODE ON JOURNEY @JOURNEY
        useJourneyStore.getState().setOpenRegisterForm(!openRegister);
    };

    const unsubOpenRegisterForm = useJourneyStore.subscribe((state) => {
        state.openRegisterForm !== openRegister &&
            setOpenRegister(state.openRegisterForm);
    });

    // ----------------------- Invio dati allo store ( ZUSTAND )
    const onFormChange = (id: string, value: any /* isValid?: boolean */) => {
        // ------- update per il numero di telefono

        if (id === Identifiers.FIELD_USER_PERSON_MOBILE_PHONE) {
            const valueArr = value.split(" ");

            // per il PhoneNumber
            useJourneyStore.getState().updateUserDataField(id, valueArr[1]);

            // per il Prefix
            const options = config.register.phoneNumber.options;
            const option = options.find((p: any) => p.title === valueArr[0]);
            // console.log('prefix', option)

            useJourneyStore
                .getState()
                .updateUserDataField(Identifiers.FIELD_USER_PREFIX, option!.id);
            return;
        }

        // ------- update per il Gender
        if (id === Identifiers.FIELD_USER_TITLE) {
            const options = config.register.gender.options;
            const option = options.find(
                (g: any) =>
                    g.title === value /* && g.locale === selectedLanguage */,
            );
            // console.log('gender', addZero(option!.id))
            useJourneyStore
                .getState()
                .updateUserDataField(id, addZero(option!.id));
            return;
        }

        // ------- update per il l'autorizzazione della privacy
        if (id === Identifiers.FIELD_USER_PRIVACY_AUTHORIZATION) {
            // console.log('authorization privacy', value)
            useJourneyStore.getState().setUserPrivacyAuthorization(value);
            return;
        }

        // ------- update country region
        if (id === Identifiers.FIELD_USER_COUNTRY_REGION) {
            const options = config.register.country.options;
            const option = options.find((c: any) => c.title === value);
            // console.log('country', option)
            useJourneyStore.getState().updateUserDataField(id, option!.id);
            return;
        }

        useJourneyStore.getState().updateUserDataField(id, value);
    };

    // ----------------------- UseEffect -----------------------
    useEffect(() => {
        controlViewport();
        if (!mobileView) {
            Dimensions.addEventListener("change", controlViewport);
        }

        return () => {
            removeEventListener?.("change", controlViewport);
            setAnimationData({ opacityStart: 1, opacityEnd: 0 });
            unsubReloadExperience();
            unsubOpenRegisterForm();
        };
    }, []);

    return (
        <MotiView
            from={{ opacity: animationData.opacityStart }}
            animate={{ opacity: animationData.opacityEnd }}
            exit={{ opacity: 0 }}
            exitTransition={{ type: "timing", duration: 1500 }}
            transition={{ type: "timing", duration: 1500 }}
        >
            <ScrollView
                style={{
                    width: "100%",
                    maxHeight: Dimensions.get("window").height - 320,
                    flex: 1,
                }}
                showsVerticalScrollIndicator={false}
            >
                <SafeAreaView
                    style={[
                        styles.defaultContainer,
                        mobileView
                            ? styles.mobile
                            : windowWidth < 800
                            ? styles.table
                            : styles.desktop,
                    ]}
                >
                    {/* -------------- Parte Titolo con sottotitolo  */}
                    <BvlgariTitle
                        title={config.title}
                        subtitle={config.subtitle}
                    />

                    {/* -------------- Parte input -------- Sistemare direttamente in config  */}
                    <FormGenerator
                        isRegister={false}
                        form={inputValues.map((it) => {
                            return { ...it, type: "input" };
                        })}
                        onChange={onFormChange}
                    />

                    {/* --------------- register  */}
                    <RegisterDropdown
                        registerConfig={config.register}
                        isOpen={openRegister}
                        onChange={onFormChange}
                    />

                    {/* --------------- Parte testuale  */}
                    {/* -------------- Errore email al primo click  */}
                    {/*  <View style={[styles.viewText, openRegister && styles.registerMTop]}>
            
            {!inputValues[2].isValid && !openRegister && <BvlgariText text={inputValues[2].error} />}
          </View> */}
                </SafeAreaView>
            </ScrollView>
            {/* -------------- Testo in fondo al form  */}
            <View style={[styles.inlineText, openRegister && styles.mT16]}>
                <BvlgariText
                    text={
                        // se sei in mobile e sei in register
                        openRegister
                            ? "$authenticate.alreadyRegistered"
                            : "$authenticate.notRegistered"
                    }
                />
                <Pressable onPress={onOpenRegisterField}>
                    <BvlgariText
                        text={
                            openRegister
                                ? "$authenticate.login"
                                : "$authenticate.registerHere"
                        }
                        textType='mBoldUnderline'
                    />
                </Pressable>
            </View>
        </MotiView>
    );
};

export default FormContainer;
