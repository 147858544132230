import { StyleSheet, Dimensions } from "react-native";

export const styles = StyleSheet.create({
    absoluteContainer: {
        position: "absolute",
        height: Dimensions.get("window").height,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999999,
        paddingHorizontal: 32,
    },

    overlay: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: "#b6a286a6",
    },

    content: {
        backgroundColor: "rgba(238,238,238, 0.6)",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        rowGap: 32,
        columnGap: 16,
        width: "100%",
        maxWidth: 786,
        maxHeight: Dimensions.get("window").height - 250,
        position: "relative",
        paddingVertical: 40,
        paddingHorizontal: 40,
        borderRadius: 24,
    },

    inColumn: {
        maxWidth: 600,
        paddingHorizontal: 24,
    },

    contentMobile: {
        maxHeight: Dimensions.get("window").height - 180,
        paddingVertical: 32,
        paddingHorizontal: 20,
    },

    scroll: {
        width: "100%",
        height: "100%",
        paddingTop: 24,
        marginTop: 8,
        borderRadius: 24,
    },

    scrollContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        rowGap: 32,
        columnGap: 16,
        width: "100%",
        height: "100%",
    },

    closeIcon: {
        position: "absolute",
        width: 48,
        height: 48,
        top: 8,
        right: 8,
    },

    closeIconMobile: {
        top: 4,
        right: 4,
    },

    card: {
        backgroundColor: "#F8F8F8",
        minWidth: 345,
        maxWidth: 345,
        minHeight: 394,
        maxHeight: 394,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: 8,
        padding: 32,
        borderRadius: 16,
    },

    chip: {
        backgroundColor: "#FFFFFF",
        width: 78,
        height: 40,
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        top: -20,
        left: "50%",
        transform: [{ translateX: -39 }],
        borderRadius: 12,
    },
});
