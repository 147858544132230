import IStep, { OperationType } from "../../data/dataInterfaces";
import { useJourneyStore } from "../../store/JourneyStore";
import SoundPlayer from "../../utils/SoundManager";

export const tutorial: IStep = {
    title: "",
    id: "tutorial",
    operations: {
        type: OperationType.tutorial,
        config: {
            icon: "x-icon",
            cards: [
                {
                    id: "$tutorialPage.step1.step",
                    title: "$tutorialPage.step1.text",
                    image: require("/assets/tutorialImg/tutorial-step-1.png"),
                },
                {
                    id: "$tutorialPage.step2.step",
                    title: "$tutorialPage.step2.text",
                    image: require("/assets/tutorialImg/tutorial-step-2.png"),
                },
                {
                    id: "$tutorialPage.step3.step",
                    title: "$tutorialPage.step3.text",
                    image: require("/assets/tutorialImg/tutorial-step-3.png"),
                },
                {
                    id: "$tutorialPage.step4.step",
                    title: "$tutorialPage.step4.text",
                    image: require("/assets/tutorialImg/tutorial-step-4.png"),
                },
            ],
            action: () => {
                SoundPlayer.playSound("click");
                useJourneyStore.getState().onChangeFalseModal("prev");
            },
        },
    },
};
