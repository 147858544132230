import React, { useEffect, useState } from "react";
import { Dimensions, Platform, Pressable, View } from "react-native";
import BvlgariText, { PropsTextType } from "../TextElement/BvlgariText";
import { styles } from "./Informative.styles";
import { ActionButton } from "../ActionButton/ActionButton";
import { MotiView } from "moti";
import { IStepAction } from "../../data/dataInterfaces";
import { useJourneyStore } from "../../store/JourneyStore";

export interface IInformativeConfig {
    type: "success" | "error";
    removeHeaderFooter?: boolean;
    successText?: string;
    succMobileFirstLine?: string;
    succMobileSecondLine?: string;
    successTextType?: PropsTextType;
    errorTitle?: string;
    errorTitleType?: PropsTextType;
    errorSubtitle?: string;
    errorSubtitleType?: PropsTextType;
    errorMobile?: string;
    errorMobileType?: PropsTextType;
    action?: IStepAction;
}

export interface InformativeProps {
    config: IInformativeConfig;
}

export const Informative = ({ config }: InformativeProps) => {
    const [mobileView, setMobileView] = useState<boolean>(
        Platform.OS === "android" || Platform.OS === "ios",
    );
    const [windowWidth, setWindowWidth] = useState<number>(
        Dimensions.get("window").width,
    );

    const { onChangeFalseModal } = useJourneyStore.getState();
    const falseModal = useJourneyStore((state) => state.falseModal);

    // controllo viewport width se non siamo nella mobile mode
    const controlViewport = () => {
        const { width } = Dimensions.get("window");
        setMobileView(width < 525); // 600 per dire che è mobile
        setWindowWidth(width);
    };

    useEffect(() => {
        controlViewport();
        if (!mobileView) Dimensions.addEventListener("change", controlViewport);

        setTimeout(() => {
            if (config.type === "success")
                useJourneyStore.getState().setDoExitAnimation();
        }, 1500);

        return () => {
            removeEventListener?.("change", controlViewport);
        };
    }, []);

    return (
        <MotiView
            from={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={() => {
                "worklet";
                return {
                    opacity: 0,
                };
            }}
            exitTransition={{ type: "timing", duration: 1500 }}
            transition={{ type: "timing", duration: 1500 }}
        >
            {config.type === "success" ? (
                <>
                    <View
                        style={
                            mobileView
                                ? styles.mobileSuccContainer
                                : styles.successContainer
                        }
                    >
                        {!mobileView ? (
                            <BvlgariText
                                style={{
                                    textAlign: "center",
                                    color: "#473213",
                                }}
                                text={
                                    config.successText ??
                                    "Your account has been successfully created. Welcome to the BVLGARI world."
                                }
                                textType={config.successTextType ?? "xlLight"}
                            />
                        ) : (
                            <View>
                                <BvlgariText
                                    style={{
                                        textAlign: "center",
                                        color: "#473213",
                                    }}
                                    text={
                                        config.succMobileFirstLine ??
                                        "Your account has been created successfully"
                                    }
                                    textType={
                                        config.successTextType ?? "xlLight"
                                    }
                                />
                                <BvlgariText
                                    text={
                                        config.succMobileSecondLine
                                            ? ""
                                            : "Enjoy the experience"
                                    }
                                    style={{
                                        marginTop: 24,
                                        textAlign: "center",
                                        color: "#473213",
                                    }}
                                    textType={
                                        config.successTextType ?? "xlLight"
                                    }
                                />
                            </View>
                        )}
                    </View>
                </>
            ) : (
                <View
                    style={
                        mobileView
                            ? styles.mobileErrContainer
                            : styles.errorContainer
                    }
                >
                    {!mobileView ? (
                        <View>
                            <BvlgariText
                                text={config.errorTitle ?? "SORRY!"}
                                textType={config.errorTitleType ?? "xlBoldUp"}
                            />
                            <BvlgariText
                                style={{
                                    textAlign: "center",
                                    color: "#473213",
                                }}
                                text={
                                    config.errorSubtitle ??
                                    "Something went wrong Please try again."
                                }
                                textType={config.errorSubtitleType ?? "xlLight"}
                            />
                            <View style={styles.buttonContainer}>
                                {config.action && (
                                    <ActionButton action={config.action} />
                                )}
                            </View>
                        </View>
                    ) : (
                        <View>
                            <BvlgariText
                                text={
                                    config.errorMobile ?? "Something went wrong"
                                }
                                style={{
                                    textAlign: "center",
                                    color: "#473213",
                                }}
                                textType={config.errorMobileType ?? "xlLight"}
                            />
                        </View>
                    )}
                </View>
            )}
        </MotiView>
    );
};
