import { Margin, isIos } from "./../../../utils/utils";
import { Platform, StyleSheet } from "react-native";

export const styles = StyleSheet.create({
    view: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 8,
        width: "100%",
        minWidth: "45%",
        maxWidth: "48%",
        flex: 1,
        position: "relative",
        zIndex: 9,
    },

    text: {
        fontSize: 18,
        fontWeight: "700",
        textTransform: "uppercase",
        color: "#473213",
    },
    inputContainer: {
        width: "100%",
        borderBottomWidth: 1,
        borderBottomColor: "#473213",
    },
    input: {
        fontFamily: "BulgariRegular",

        color: "#473213",
        fontSize: 14,
        fontWeight: "400",
        width: "100%",
        height: 48,
        paddingLeft: 4,
        paddingRight: 24,
        textTransform: "uppercase",

        padding: isIos ? Margin.rem1 : 0,
        ...Platform.select({
            ios: {},
            default: {
                outline: "none",
                outlineColor: "transparent",
                outlineStyle: "none",
                transition: ".3s ease-in-out",
            },
        }),
    },
    errorInput: {
        borderColor: "rgba(255, 88, 88, 0.8)",
    },

    textError: {
        color: "rgba(255, 88, 88, 0.8)",
        fontSize: 14,
        fontWeight: "400",
        position: "absolute",
        bottom: -20,
        left: 10,
    },

    placeholder: {
        color: "rgba(71, 50, 19, 0.5)",
    },

    inputContainerWithPrefix: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 0,
    },

    inputPhoneNumber: {
        borderLeftWidth: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeftColor: "rgba(71, 50, 19, 0.5)",
        paddingLeft: 4,
    },
});
