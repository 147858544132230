import { __ } from "../translations/translations";
import { storeSetupData } from "./steps/storeSetup";
import { finalVideoData } from "./steps/finalVideo";
import { authInformativeData } from "./steps/authInformativeData";
import { welcomePageData } from "./steps/welcomePageData";
import { authenticationData } from "./steps/authenticationData";
import { charmSelector } from "./steps/charmSelector";
import { moodSelectorData } from "./steps/moodSelector";
import { mailTemplate } from "./steps/mailTemplate";
import { videoGenerationLoading } from "./steps/videoGenerationLoading";
import { loaderSetupData } from "./steps/loaderSetup";

// Steps of the experience
export const AvailableSteps = () => [
    storeSetupData, //   0
    welcomePageData, //   1
    moodSelectorData, //   2
    charmSelector, //   3
    mailTemplate, //   4
    videoGenerationLoading, //   5
    finalVideoData, //   6
    loaderSetupData, //   7
    authInformativeData, //   8
    loaderSetupData, //   9 // barbatrucco per il refresh
    // authenticationData,
    // loader,
    // loaderSetupData,
    // authInformativeData,
    // loader
];
