import { useFonts } from "expo-font";
import MainScreen from "./src/screens/MainScreen";
import { useCallback, useEffect, useState } from "react";
import { Platform, Pressable } from "react-native";
import useDeviceInfo from "./src/utils/useDeviceInfo";
import { useJourneyStore } from "./src/store/JourneyStore";
import { useStepManagerStore } from "./src/store/ExperienceStore";
import jwt_decode from "jwt-decode";
import NetInfo from "@react-native-community/netinfo";

import { welcomePageData } from "./src/configuration/steps/welcomePageData";
import "react-native-gesture-handler";
import { isIosApp, isScreenPage } from "./src/utils/utils";
import VideoScreen from "./src/screens/VideoScreen";
import { SuspendPinchZoom } from "./src/utils/SuspendPinchToZoom";
import DownloadLandingPage from "./src/screens/DownloadLandingPage";
import { Audio } from "expo-av";

const bulgariBold = require("./assets/fonts/Bulgari_Type_Bold.otf");
const bulgariRegular = require("./assets/fonts/Bulgari_Type_Regular.otf");
//const bulgariMedium = require('./assets/fonts/Bulgari_Type_Medium.otf')
const bulgariLight = require("./assets/fonts/Bulgari_Type_Light.otf");
const bulgariLightItalic = require("./assets/fonts/Bulgari_Type_Light_Italic.otf");
const bulgariCapitalis = require("./assets/fonts/Bvlgari_Type_Capitalis.otf");
const bulgariBoldItalic = require("./assets/fonts/Bulgari_Type_Bold_Italic.otf");

export default function App() {
	const [isDownloadPage, setIsDownloadPage] = useState(
		new URLSearchParams(window.location.search).get("download"),
	);

	// ---- per non inserire la gestione corretta con il router ----
	if (isScreenPage()) return <VideoScreen />;

	/* --------------- GIRO ONLINE/OFFLINE  */
	const [isIos, setIsIos] = useState<boolean>(
		new URLSearchParams(window.location.search)
			.get("device")
			?.slice(0, 3) === "ios",
	);

	const defaultLanguage =
		new URLSearchParams(window.location.search).get("language") || "en";

	// giro offline perché siamo su table
	if (isIos) useJourneyStore.getState().setIsIos(null);

	// giro online perché siamo su web
	if (!isIos) {
		useJourneyStore.getState().setIsIos("online");
		useStepManagerStore.getState().increaseStep(welcomePageData);
		useJourneyStore.getState().setSelectedLocale(defaultLanguage);
	} else {
		const storageLocale = localStorage.getItem("BG_locale");
		const storageStoreId = localStorage.getItem("BG_localStoreId");
		const storageStoreName = localStorage.getItem("BG_localStore");
		const storageCountryRegion = localStorage.getItem(
			"BG_localStoreCountry",
		);

		if (storageLocale)
			useJourneyStore.getState().setSelectedLanguage(storageLocale);

		if (storageStoreId)
			useJourneyStore.getState().setSelectedStoreId(storageStoreId);
		if (storageStoreName)
			useJourneyStore.getState().setSelectedStore(storageStoreName);
		if (storageLocale && storageStoreId && storageStoreName)
			useStepManagerStore.getState().setCurrentStepIndex(1);
		if (storageCountryRegion)
			useJourneyStore
				.getState()
				.updateUserDataField("CountryRegion", storageCountryRegion);
	}

	const [loaded, error] = useFonts({
		BulgariBold: bulgariBold,
		BulgariRegular: bulgariRegular,
		BulgariLight: bulgariLight,
		BulgariCapitalis: bulgariCapitalis,
		BulgariBoldItalic: bulgariBoldItalic,
		BulgariLightItalic: bulgariLightItalic,
	});

	const onLayoutRootView = useCallback(async () => {
		if (loaded || error) {
			console.log("loaded");
		}
	}, [loaded, error]);

	useEffect(() => {
		const unsubscribe = NetInfo.addEventListener((state) => {
			/*     console.log("Is connected?", state.isInternetReachable); */
		});
		return () => unsubscribe();
	}, []);

	// Add this in node_modules/react-dom/index.js
	return (
		<SuspendPinchZoom>
			{!isDownloadPage && <MainScreen />}
			{isDownloadPage && <DownloadLandingPage />}
		</SuspendPinchZoom>
	);
}
