import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  footerTextContainer: {},

  // --------- PLATFMOR WIDTH --------- //
  mobile: {
    maxWidth: "100%",
    width: 525,
  },
  table: {
    maxWidth: "100%",
    width: 525,
  },
  desktop: {
    maxWidth: "100%",
    width: 595,
  },

  headerLogo: { height: 44, width: 144 },
  headerImageContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },

  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 15,
  },

  allSmallBtnNoWrap: {
    flexWrap: "nowrap",
    justifyContent: "center",
    gap: 24,
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    borderRadius: 8,
    maxWidth: 750,
  },

  allSmallBtnWrap: {
    flexWrap: "wrap",
    justifyContent: "center",
    gap: 24,
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    borderRadius: 8,
  },

  footerText: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
  },
  stepFooter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 6,
    textAlign: "center",
    marginBottom: 20,
    zIndex: 999,
  },
});
