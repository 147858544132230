import { AnimatePresence, MotiView, View } from "moti";
import { IStepAction } from "../../data/dataInterfaces";
import styles from "./WelcomePage.styles";
import { ActionButton } from "../ActionButton/ActionButton";
import BvlgariText from "../TextElement/BvlgariText";
import { useState } from "react";
import { Dimensions, Platform } from "react-native";
import { useStepManagerStore } from "../../store/ExperienceStore";
import {
    BIG_IPAD_FINAL_VIDEO_SCALE,
    BIG_WEB_FINAL_VIDEO_SCALE,
    IPAD_FINAL_VIDEO_SCALE,
    WEB_FINAL_VIDEO_SCALE,
    isIosApp,
} from "../../utils/utils";

interface IWelcomePageConfig {
    firstAction: { title: string; subtitle: string };
    secondAction: { title: string; subtitle: string };
    action: IStepAction;
}

export interface IWelcomePageProps {
    config: IWelcomePageConfig;
}

export default function WelcomePage({ config }: IWelcomePageProps) {
    const [mobileView] = useState<boolean>(
        Platform.OS === "android" || Platform.OS === "ios",
    );
    const [windowWidth] = useState<number>(Dimensions.get("window").width);
    const [currentStep, setCurrentStep] = useState<number>(
        useStepManagerStore.getState().currentStepIndex,
    );
    const [windowHeight] = useState<number>(Dimensions.get("window").height);

    /* ----------- SCALE----------- */
    const getScale = () => {
        if (!isIosApp) {
            if (
                windowHeight > 500 &&
                windowHeight < 800 &&
                windowWidth > 1200 &&
                currentStep !== 4
            ) {
                return WEB_FINAL_VIDEO_SCALE * 1.5; // RISOLUZIONE ALICIA no welcome
            } else if (
                windowHeight > 500 &&
                windowHeight < 800 &&
                windowWidth > 1200 &&
                currentStep === 4
            ) {
                return WEB_FINAL_VIDEO_SCALE * 1; // RISOLUZIONE ALICIA si welcome
            } else if (windowHeight < 800) {
                return WEB_FINAL_VIDEO_SCALE * 1.5; // per mobile
            } else if (windowHeight < 1000) {
                return WEB_FINAL_VIDEO_SCALE * 1.2; // per tablet
            } else {
                return BIG_WEB_FINAL_VIDEO_SCALE;
            }
        } else {
            if (windowHeight < 1100) {
                return IPAD_FINAL_VIDEO_SCALE * 1.1; // SCALE MOBILE WELCOOME PAGE
            } else {
                return BIG_IPAD_FINAL_VIDEO_SCALE;
            }
        }
    };

    return (
        <AnimatePresence exitBeforeEnter>
            <View
                style={styles.container}
                key={`${currentStep}_welcomePage`}
                exit={() => {
                    "worklet";
                    return {
                        opacity: 0,
                    };
                }}
                transition={{ type: "timing", duration: 1500 }}
            >
                {/* ----- First Title & Subtitle ----- */}
                <View
                    from={{
                        opacity: 0,
                        scale: 0.8,
                    }}
                    animate={{
                        opacity: 1,
                        scale: getScale(),
                    }}
                    transition={{
                        type: "timing",
                        duration: 1500,
                    }}
                    style={{
                        width: "100%",
                        minWidth: "100%",
                    }}
                >
                    <View style={styles.titleWrapper}>
                        <BvlgariText
                            text={config.firstAction.title}
                            textType='capitalis'
                            style={{
                                color: "#473213",
                                textTransform: "uppercase",
                                /* fontSize: currentFontSize,
                                lineHeight: currentFontSize * 1.2, */
                                fontSize:
                                    windowWidth < 400
                                        ? 16
                                        : windowWidth < 530
                                        ? 16
                                        : windowWidth < 600
                                        ? 20
                                        : 24,
                                lineHeight: 20,
                                textAlign: "center",
                                minWidth: "100%",
                                fontWeight: "300",
                            }}
                        />
                    </View>
                    <View style={styles.subtitleWrapper}>
                        <BvlgariText
                            text={config.firstAction.subtitle}
                            textType='capitalis'
                            style={{
                                color: "#473213",
                                textTransform: "uppercase",
                                /* fontSize: currentFontSizeSubtitle,
                                lineHeight: currentFontSize * 1.2, */
                                fontSize:
                                    windowWidth < 400
                                        ? 20
                                        : windowWidth < 530
                                        ? 18
                                        : windowWidth < 600
                                        ? 24
                                        : 28,
                                lineHeight: 28,
                                textAlign: "center",
                                fontWeight: "300",
                            }}
                        />
                    </View>
                </View>

                {/* ----- Second Title ----- */}
                <View
                    from={{
                        opacity: 0,
                        scale: 0.8,
                    }}
                    animate={{
                        opacity: 1,
                        scale: getScale(),
                    }}
                    transition={{
                        type: "timing",
                        duration: 1500,
                        delay: 1500,
                    }}
                    style={{
                        /*  marginTop: mobileView || windowWidth < 600 ? 30 : 40, */
                        marginTop: 24,
                    }}
                >
                    <View style={styles.titleWrapper}>
                        <BvlgariText
                            text={config.secondAction.title}
                            textType='xlLightUp'
                            style={{
                                color: "#473213",
                                /*  fontSize: currentFontSize, */
                                fontSize:
                                    windowWidth < 400
                                        ? 18
                                        : windowWidth < 530
                                        ? 22
                                        : windowWidth < 600
                                        ? 28
                                        : 32,
                                lineHeight: 32,
                                textAlign: "center",
                                fontWeight: "300",
                                marginTop: 24,
                            }}
                        />
                    </View>
                </View>

                {/* ----- Action with description ----- */}
                <AnimatePresence>
                    <MotiView
                        from={{
                            opacity: 0,
                            scale: 0.8,
                        }}
                        key={`${currentStep}_button`}
                        animate={{
                            opacity: 1,
                            scale: getScale(),
                        }}
                        transition={{
                            type: "timing",
                            duration: 1500,
                            delay: 3000,
                        }}
                        style={[
                            styles.actionContainer,
                            windowWidth < 400
                                ? { minWidth: 400, bottom: -330 }
                                : windowWidth < 530
                                ? {
                                      minWidth: 400,
                                      paddingHorizontal: 30,
                                      bottom: -360,
                                  }
                                : windowWidth < 600
                                ? {
                                      minWidth: 530,
                                      paddingHorizontal: 50,
                                      bottom: -400,
                                  }
                                : {},
                        ]}
                    >
                        {/* ----- Action  description ----- */}
                        <View
                            style={[
                                styles.textContainer,
                                windowWidth < 400
                                    ? { maxWidth: 400, minWidth: 380 }
                                    : windowWidth < 600
                                    ? { maxWidth: 540 }
                                    : {},
                            ]}
                        >
                            <BvlgariText
                                text={config.secondAction.subtitle}
                                textType='xlLight'
                                style={{
                                    color: "#473213",
                                    /* fontSize: currentFontSize, */
                                    fontSize:
                                        windowWidth < 400
                                            ? 18
                                            : windowWidth < 600
                                            ? 20
                                            : 24,
                                    fontWeight: "300",
                                }}
                            />
                        </View>
                        <View
                            style={
                                mobileView || windowWidth < 525
                                    ? styles.mobileButtonContainer
                                    : styles.buttonContainer
                            }
                        >
                            <ActionButton action={config.action} />
                        </View>
                    </MotiView>
                </AnimatePresence>
            </View>
        </AnimatePresence>
    );
}
