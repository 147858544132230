import { StyleSheet, Dimensions } from "react-native";

export const styles = StyleSheet.create({
    absoluteContainer: {
        position: "absolute",
        height: Dimensions.get("window").height * 1.2 + 100,
        minHeight: Dimensions.get("window").height * 1.2 + 100,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        zIndex: 99998,
    },

    content: {
        paddingTop: 50,
        width: "100%",
        height: "100%",
        maxWidth: 772,
        minHeight: 790,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 48,
        position: "relative",
    },

    contentDesktop: {
        paddingTop: 0,
        gap: 30,
    },

    contentTabletWeb: {
        paddingTop: 0,
        gap: 38,
    },

    contentMobile: {
        paddingTop: 35,
        gap: 35,
    },

    contentSmallMobile: {
        paddingTop: 8,
        gap: 24,
    },

    scrollContainer: {
        width: "100%",
        maxWidth: 635,
        minHeight: 790,
        height: "100%",
        /* maxHeight: 718, */
        display: "flex",
        flexDirection: "column",
    },

    scrollMobile: {
        maxHeight: 550,
        minHeight: 550,
        height: 550,
    },

    scrollDesktop: {},
    scrollDesktopSmall: { maxHeight: 660, minHeight: 660, height: 660 },

    infoContainer: {
        width: "100%",
        maxWidth: 635,
        height: "100%",
        maxHeight: 515,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: 75,
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        paddingVertical: 60,
        paddingHorizontal: 40,
        border: "1.4px solid #FFFFFF",
        borderRadius: 16,
        marginBottom: 48,
        zIndex: 1,
    },

    InfoContainerTabletWebInvite: {
        maxHeight: 440,
    },

    InfoContainerDesktopInvite: { maxHeight: 440 },

    infoContainerMobile: {
        gap: 24,
        maxHeight: 500,
        marginBottom: 24,
        paddingHorizontal: 30,
    },
    infoContainerSmallMobile: {
        gap: 20,
        maxHeight: 480,
        marginBottom: 20,
        paddingHorizontal: 20,
    },

    infoContainerMobileInvite: { maxHeight: 440 },

    infoContainerSmallMobileInvite: { maxHeight: 420 },

    firstInfo: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 24,
    },

    firstInfoMobile: {
        gap: 2,
    },

    inputForm: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexWrap: "wrap",
        paddingHorizontal: 14,
        maxHeight: 116,
        gap: 24,
    },

    inputFormMobile: {
        maxHeight: 350,
        paddingHorizontal: 10,
    },

    inputFromContainer: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        maxWidth: "100%",
        flexWrap: "wrap",
        columnGap: 20,
        rowGap: 20,
        paddingLeft: 0,
        paddingRight: 0,
    },
    checkboxForm: {
        width: "100%",
        maxWidth: "100%",
        height: 44,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingHorizontal: 14,
        paddingVertical: 20,
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        borderRadius: 4,
        zIndex: -1,
    },

    checkboxFormMobile: {
        height: "auto",
        paddingVertical: 6,
        paddingHorizontal: 8,
        marginTop: 16,
    },

    checkboxFormSmallMobile: {
        height: "auto",
        paddingVertical: 6,
        paddingHorizontal: 6,
        marginTop: 16,
    },

    emailForm: {
        width: "100%",
        minWidth: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexWrap: "wrap",
        paddingHorizontal: 14,
        height: 60,
        gap: 24,
    },

    secondInfo: {
        width: "100%",
        minWidth: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 24,
    },

    secondInfoMobile: {
        gap: 2,
    },

    checkboxContainer: {
        minWidth: 635,
        maxWidth: 635,
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },

    privacyPolicyConditions: {
        width: "100%",
        padding: 14,
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        borderRadius: 4,
    },

    lastCheckboxContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        textAlign: "left",
        padding: 14,
        gap: 8,
    },

    lastCheckbox: {
        width: "100%",
        minHeight: 68,
        display: "flex",
        gap: 8,
    },

    bottomContainer: {
        marginTop: 10,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 8,
    },

    actionsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        flex: 1,
        minWidth: "100%",
        width: "100%",
        minHeight: 38,
        maxWidth: 632,
        paddingHorizontal: 16,
        gap: 15,
    },

    allSmallBtn: {
        flexWrap: "nowrap",
        justifyContent: "center",
    },
});
