import React, { useState } from "react";
import { View, Image, Pressable, Dimensions } from "react-native";
import { styles } from "./ReloadModal.styles";

import BvlgariText from "../TextElement/BvlgariText";
import { translate } from "../../utils/utils";

interface Props {
    card: {
        id: string;
        title: string;
        titleLong: string;
        questions: string;
        btnRestart: string;
        btnLogin: string;
        btnRegister: string;
        btnError: string;
        btnBack: string;
        changeLanguageQuestions: string;
        btnRestartLang: string;
    };
    longPress: boolean;
    userConfirm: {
        title: string;
        message: string;
        error:
            | "login"
            | "error"
            | "network-error"
            | "check-email"
            | "error-mail"
            | "charms-error"
            | undefined;
    } | null;
    reloadExperience: (lang?: string) => void;
    closeWithoutInternet: () => void;
    handleCloseModal: () => void;
}

const ReloadCard = ({
    card,
    userConfirm,
    longPress,
    reloadExperience,
    closeWithoutInternet,
    handleCloseModal,
}: Props) => {
    const [windowWidth] = useState(Dimensions.get("window").width);

    const messageToDisplay = longPress
        ? card.titleLong
        : userConfirm?.message
        ? userConfirm.message
        : card.title;

    //  console.log(messageToDisplay);

    return (
        <View style={styles.card}>
            <BvlgariText
                text={translate(messageToDisplay)}
                textType='xlLight'
                style={{
                    color: "#B0882F",
                    fontSize: windowWidth < 600 ? 16 : 18,
                }}
            />

            {/* TESTO IN MEZZO TRA TESTO E BOTTONE  se non esiste lo userConfirm viene il messaggio del reload */}
            {!userConfirm && (
                <BvlgariText
                    text={translate(card.questions)}
                    textType='xlLight'
                    style={{
                        color: "#B0882F",
                        fontSize: windowWidth < 600 ? 16 : 18,
                        fontWeight: 600,
                    }}
                />
            )}

            {userConfirm?.title && (
                <BvlgariText
                    text={
                        userConfirm.title === "Language"
                            ? translate(card.questions)
                            : translate(userConfirm.title)
                    }
                    textType='xlLight'
                    style={{
                        color: "#B0882F",
                        fontSize: windowWidth < 600 ? 16 : 18,
                        fontWeight: 600,
                    }}
                />
            )}

            {/* BOTTONI */}
            {(!userConfirm || userConfirm.title === "Language") && (
                <Pressable
                    accessibilityLabel={
                        userConfirm?.title === "Language"
                            ? translate(card.btnRestartLang)
                            : translate(card.btnRestart)
                    }
                    onPress={() => reloadExperience()}
                    style={styles.btnReload}
                >
                    <BvlgariText
                        text={
                            userConfirm?.title === "Language"
                                ? translate(card.btnRestartLang)
                                : translate(card.btnRestart)
                        }
                        textType='mRegular'
                        style={{
                            fontSize: windowWidth < 600 ? 14 : 16,
                            textTransform: "uppercase",
                        }}
                    />
                </Pressable>
            )}

            {userConfirm &&
                userConfirm?.error !== "error" &&
                userConfirm?.title !== "Language" &&
                userConfirm.error !== "error-mail" &&
                userConfirm.error !== "check-email" && (
                    <Pressable
                        accessibilityLabel={translate("$reloadModal.btnOk")}
                        onPress={handleCloseModal}
                        style={styles.btnReload}
                    >
                        <BvlgariText
                            text={translate("$reloadModal.btnOk")}
                            textType='mRegular'
                            style={{
                                fontSize: windowWidth < 600 ? 14 : 16,
                                textTransform: "uppercase",
                            }}
                        />
                    </Pressable>
                )}

            {userConfirm && userConfirm.error === "error-mail" && (
                <Pressable
                    accessibilityLabel={"Errore email offline"}
                    onPress={closeWithoutInternet}
                    style={styles.btnReload}
                >
                    <BvlgariText
                        text={"Errore email offline"}
                        textType='mRegular'
                        style={{
                            fontSize: windowWidth < 600 ? 14 : 16,
                            textTransform: "uppercase",
                        }}
                    />
                </Pressable>
            )}

            {userConfirm && userConfirm.error === "check-email" && (
                <Pressable
                    onPress={handleCloseModal}
                    style={styles.btnReload}
                    accessibilityLabel={translate("$reloadModal.btnOk")}
                >
                    <BvlgariText
                        text={translate("$reloadModal.btnOk")}
                        textType='mRegular'
                        style={{
                            fontSize: windowWidth < 600 ? 14 : 16,
                            textTransform: "uppercase",
                        }}
                    />
                </Pressable>
            )}

            {userConfirm?.error === "error" && (
                <View style={styles.errorBtnContainer}>
                    <Pressable
                        accessibilityLabel={"go " + translate(card.btnBack)}
                        onPress={() => handleCloseModal()}
                        style={styles.btnError}
                    >
                        <BvlgariText
                            text={translate(card.btnBack)}
                            textType='mRegular'
                            style={{
                                fontSize: windowWidth < 600 ? 14 : 16,
                                textTransform: "uppercase",
                            }}
                        />
                    </Pressable>

                    <Pressable
                        accessibilityLabel={"card.btnError"}
                        onPress={() => reloadExperience()}
                        style={styles.btnError}
                    >
                        <BvlgariText
                            text={translate(card.btnError)}
                            textType='mRegular'
                            style={{
                                fontSize: windowWidth < 600 ? 14 : 16,
                                textTransform: "uppercase",
                            }}
                        />
                    </Pressable>
                </View>
            )}
        </View>
    );
};

export default ReloadCard;
