import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        maxWidth: "100%",
        height: "100%",
        maxHeight: 34,
        overflow: "hidden",
        transition: "all 0.2s ease-in-out",
    },

    containerReverse: {
        flexDirection: "row-reverse",
    },

    check: {
        width: 20,
        height: 20,
        flexShrink: 0,
        borderRadius: 100,
        marginRight: 8,
    },

    unchecked: {
        borderWidth: 1.5,
        borderStyle: "solid",
        borderColor: "#473213",
    },

    checkHover: {
        borderWidth: 3,
        borderStyle: "solid",
        borderColor: "#473213",
    },

    checkSelected: {
        backgroundColor: "#473213",
    },

    text: {
        fontSize: 14,
        fontWeight: "400",
        textAlign: "left",
    },

    textRevert: {
        fontSize: 14,
        fontWeight: "400",
        textAlign: "right",
    },
    highlighted: {
        flex: 1,
        backgroundColor: "rgba(71,50,19, 0.2)",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 8,
        padding: 24,
        borderRadius: 10,
    },
});
