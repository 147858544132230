import IStep, { OperationType } from '../../data/dataInterfaces'
import { Identifiers } from '../../utils/Indentifiers'

export const loaderSetupData: IStep = {
  title: '',
  id: Identifiers.LOADING_AFTER_ACTION,
  operations: {
    type: OperationType.loader,
    config: {
      lottie: require('/assets/loading-star.json'),
    },
  },
  actions: [{ id: 'none', title: '', action: () => {} }],
}
