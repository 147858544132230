import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        maxWidth: 707,
        minWidth: "100%",
        maxHeight: 500,
        height: "100%",
    },
    titleWrapper: {
        marginBottom: 16,
    },
    subtitleWrapper: {
        minWidth: "100%",
        marginBottom: 8,
    },
    buttonContainer: {
        width: "100%",
        height: 48,
    },
    mobileButtonContainer: {
        width: 280,
        height: 44,
    },

    textContainer: {
        maxWidth: 556,
        width: "100%",
    },

    actionContainer: {
        maxWidth: 604,
        minWidth: 604,
        width: "100%",
        padding: 24,
        paddingHorizontal: 90,
        alignItems: "center",
        gap: 24,
        borderRadius: 8,
        backgroundColor: "rgba(255, 255, 255, 0.20)",
        zIndex: 10,

        position: "absolute",
        bottom: -275 - 163,
    },
});

export default styles;
