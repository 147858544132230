import { Platform, StyleSheet } from "react-native";

export const styles = StyleSheet.create({
    container: {
        width: 555,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

        zIndex: 1,
    },

    containerOnSend: {
        width: "100%",
        maxWidth: "48%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

        zIndex: 1,
    },

    selectContainer: {
        color: "#ffffff",
        width: "100%",
        maxWidth: 527,
        height: 48,
        maxHeight: 48,
        flex: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        paddingLeft: 4,
        paddingRight: 24,
        borderBottomWidth: 1,
        borderBottomColor: "rgba(71, 50, 19, 1)",
    },

    selectContainerOnSend: {
        color: "#473213",
        width: "100%",
        maxWidth: 527,
        height: 48,
        maxHeight: 48,
        flex: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        paddingLeft: 4,
        paddingRight: 24,

        borderBottomWidth: 1.9,
        borderStyle: "solid",

        borderBottomColor: "#473213",
    },

    open: {
        zIndex: 99,
    },

    titleContainer: {
        color: "#ffffff",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 4,
    },

    select: {
        flex: 1,
        height: 48,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },

    label: {
        color: "#473213",
        flex: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        overflow: "hidden",
        marginRight: 10,
        /* borderWidth: 1,
        borderStyle: "solid",
        borderColor: "transparent", */
        borderRadius: 0,
        letterSpacing: 0.8,

        ...Platform.select({
            ios: {},
            default: {
                outline: "none",
                outlineColor: "trasnsparent",
                outlineStyle: "none",
            },
        }),
    },

    labelSend: {
        color: "rgba(71, 50, 19, 0.5)",
        flex: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        overflow: "hidden",
        marginRight: 10,
        /* borderWidth: 1,
        borderStyle: "solid",
        borderColor: "transparent",
        borderRadius: 0, */
        textTransform: "uppercase",
        letterSpacing: 0.8,
        ...Platform.select({
            ios: {},
            default: {
                outline: "none",
                outlineColor: "trasnsparent",
                outlineStyle: "none",
            },
        }),
    },

    placeholder: {
        color: "rgba(71, 50, 19, 0.5)",
        fontSize: 14,
        fontWeight: "400",
        letterSpacing: 0.8,
    },
    arrow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        minWidth: 20,
    },

    dropdown: {
        backgroundColor: "#473213",
        zIndex: 999,
        position: "absolute",
        left: -1,
        right: 0,
        top: 56,
        maxHeight: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: 8,
        paddingLeft: 16,
        paddingRight: 16,
        overflow: "scroll",
        transition: ".3s ease, opacity .5s ease",
        borderWidth: 0,
        borderStyle: "solid",
        borderColor: "rgba(255,250,236, 0.3)",
        borderRadius: 8,
        opacity: 0,
    },

    dropdownOpen: {
        width: "100%",
        maxHeight: 196,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "rgba(255,250,236, 0.3)",
        opacity: 1,
        padding: 16,
    },

    option: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: 8,
        borderRadius: 8,
    },

    optHover: {
        backgroundColor: "rgba(255, 250, 255, 0.1)",
    },

    optSelected: {
        backgroundColor: "rgba(255, 250, 255, 0.1)",
        fontWeight: "700",
    },
});
