import { StyleSheet, Dimensions } from "react-native";

export const styles = StyleSheet.create({
    absoluteContainer: {
        position: "absolute",
        height: Dimensions.get("window").height,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999999,
        paddingHorizontal: 32,
    },

    overlay: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: "rgba(0,0,0, 0.6)",
    },

    content: {
        backgroundColor: "rgba(238,238,238, 0.6)",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        rowGap: 32,
        columnGap: 16,
        width: "100%",
        maxWidth: 505,

        position: "relative",
        paddingVertical: 48,
        paddingHorizontal: 40,
        borderRadius: 24,
    },

    contentMobile: {
        paddingVertical: 28,
        paddingHorizontal: 24,
    },

    inColumn: {
        maxWidth: 600,
        paddingHorizontal: 24,
    },

    scroll: {
        width: "100%",
        height: "100%",
        paddingTop: 24,
        marginTop: 8,
        borderRadius: 24,
    },

    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        rowGap: 32,
        columnGap: 16,
        width: "100%",
        height: "100%",
    },

    closeIcon: {
        position: "absolute",
        width: 48,
        height: 48,
        top: 8,
        right: 8,
    },

    closeIconMobile: {
        top: -4,
        right: -4,
    },

    card: {
        backgroundColor: "#F8F8F8",
        width: "100%",
        minHeight: 100,
        maxHeight: 300,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 12,
        padding: 32,
        borderRadius: 16,
    },

    btnReload: {
        width: "100%",
        height: 48,
        backgroundColor: "rgba(173, 139, 69, 1)",
        marginTop: 12,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 6,
    },

    errorBtnContainer: {
        width: "100%",
        height: 48,

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        gap: 14,
        marginTop: 12,
    },

    btnError: {
        flex: 1,
        height: 48,
        backgroundColor: "rgba(173, 139, 69, 1)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 6,
    },
});
