import { io } from "socket.io-client";
import { useJourneyStore } from "../store/JourneyStore";
import { getQueryParams } from "./utils";

//const socket = io('https://bulgari-christmas-backend-61feacc5c98e.herokuapp.com/')

/* const ROOM_CODE = 'dubai-1' // DA CAMBIARE OGNI VOLTA IN BASE ALLA STANZA ( PRENDERE DA ZUSTAND , STORE SETUP) */

const MESSAGE_ID = "private_message";
const MESSAGE_ID_CUBE = "private_message_cube";

//// ------ alternativa per gestione socket ------

export class SocketManager {
  static instance: SocketManager | null = null;
  private socket: any = null;

  STORE_DEFAULT_CODE = "dubai-1";

  constructor() {
    this.socket = io(
      "https://bulgari-christmas-backend-61feacc5c98e.herokuapp.com/"
    );
  }

  static getInstance() {
    if (this.instance == null) {
      this.instance = new SocketManager();
    }
    return this.instance;
  }

  private getRoomCode = () => {
    const { storeId } = getQueryParams(window.location.search);
    const roomCode =
      storeId && storeId.length > 0
        ? storeId
        : useJourneyStore.getState().selectedStoreId;
    return roomCode && roomCode.length > 0 ? roomCode : this.STORE_DEFAULT_CODE;
  };

  private isCube = () => {
    const { isCube } = getQueryParams(window.location.search);
    return isCube !== undefined;
  };

  connectAndListen = (callback: (data: any) => void) => {
    console.log("Sono conness alla stanza: ", this.getRoomCode());
    this.socket.on("connect", () => {
      const roomCode = this.getRoomCode();
      this.socket.emit("join_room", { roomCode: roomCode });
      !this.isCube() &&
        this.socket.on(MESSAGE_ID, (data: any) => callback(data));
      this.isCube() &&
        this.socket.on(MESSAGE_ID_CUBE, (data: any) => callback(data));
    });
  };

  connectAndListenCube = (callback: (data: any) => void) => {
    this.socket.on("connect", () => {
      const roomCode = this.getRoomCode();
      this.socket.emit("join_room", { roomCode: roomCode });
      this.socket.on(MESSAGE_ID_CUBE, (data: any) => callback(data));
    });
  };

  sendSocketCubeMessage = (videoUrl?: string) => {
    const roomCode = this.getRoomCode();
    this.socket.emit(MESSAGE_ID_CUBE, {
      roomCode: roomCode,
      payload: {
        video_status: "generated",
        video_url: videoUrl,
      },
    });
  };

  private connect = (callback: () => void) => {
    this.socket.on("connect", () => {
      const roomCode = this.getRoomCode();
      this.socket.emit("join_room", { roomCode: roomCode });
      this.socket.on(MESSAGE_ID, (data: any) => console.log(data));
      callback();
    });
  };

  private emitPrivateMessage = (_videoUrl?: string) => {
    const videoUrl =
      _videoUrl ??
      "https://bulgari-digital-craft.s3.eu-central-1.amazonaws.com/bulgari_videos/video-loop-magnificent.mp4";
    this.sendSocketMessage({
      video_status: "generated",
      video_url: videoUrl,
    });
  };

  /// ------ da richiamare per emettere il messaggio  -----
  emitMessage = (_videoUrl?: string) => {
    console.log("Emetto messaggio: ", _videoUrl);
    if (!this.socket.connected)
      this.connect(() => this.emitPrivateMessage(_videoUrl));
    else this.emitPrivateMessage(_videoUrl);
  };

  emitNewExperience = () => {
    const payload = { video_status: "start_new_experience" };
    if (!this.socket.connected)
      this.connect(() => this.sendSocketMessage(payload));
    else this.sendSocketMessage(payload);
  };

  emitNewCubeVideo = (_videoUrl?: string) => {
    const payload = { video_status: "start_new_experience" };
    if (!this.socket.connected)
      this.connect(() => this.sendSocketCubeMessage(_videoUrl));
    else this.sendSocketCubeMessage(_videoUrl);
  };

  sendSocketMessage = (payload: any) => {
    const roomCode = this.getRoomCode();
    this.socket.emit(MESSAGE_ID, {
      roomCode: roomCode,
      payload,
    });
  };
}

/*



export const emitVideoGenerated = () => {
  const socket = io('https://bulgari-christmas-backend-61feacc5c98e.herokuapp.com/', {
    withCredentials: true,
    extraHeaders: {
      Authorization: 'auth',
    },
  })
  socket.on('connect', () => {
    console.log('Connesso al server!')

    //Questo è obbligatorio per connettersi alla stanza
    socket.emit('join_room', { roomCode: ROOM_CODE })

    setTimeout(() => {
      //Questo serve per inviare un messaggio privato alla stanza
      socket.emit('private_message', {
        roomCode: ROOM_CODE,
        payload: {
          video_status: 'generated',
          // video_url: 'https:bulgari-christmas-backend-61feacc5c98e.herokuapp.com/uploads/1611531373.mp4',
          video_url: useJourneyStore.getState().urlVideoGenerated,
        },
      })
    }, 1000)

    //      Questa è l'evento di risposta del messaggio privato ( è un listener )
    socket.on('private_message', (data) => console.log(data))
  })
}

*/
