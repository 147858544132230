import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
    stepCenter: {
        flex: 1,
        justifyContent: "center",
        zIndex: 8,
        maxHeight: 1000,
    },
    stepCenterInner: {
        alignItems: "center",
        justifyContent: "center",
    },
    absolute: {
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
    },
});
