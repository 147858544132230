import React, { useEffect, useRef, useState } from "react";
import { Dimensions, FlatList, Image, Pressable, View } from "react-native";
import { useJourneyStore } from "../../store/JourneyStore";
import { ImageDataset } from "../../configuration/ImageDataset";
import SvgComponent from "../Svg/SvgComponent";
import { styles } from "./MoodSelector.styles";
import { MotiView } from "moti";
import BvlgariText from "../TextElement/BvlgariText";
import SoundPlayer from "../../utils/SoundManager";

interface Props {
    swipe: number;
}

const MoodCarousel = ({ swipe }: Props) => {
    /* ------------ ZUSTAND ------------ */
    const { setSwipeMood, setCanSwipeMood } = useJourneyStore.getState();
    const { canSwipe, falseModal } = useJourneyStore((state) => ({
        canSwipe: state.canSwipe,
        falseModal: state.falseModal,
    }));

    /* ------------ STATE ------------ */
    const [windowWidth] = useState<number>(Dimensions.get("window").width);

    /* ------------ REF ------------ */
    let flatListRef = useRef<FlatList<any[]> | null>(null);

    const moodVersionText =
        windowWidth < 600 ? "Mobile" : windowWidth > 1280 ? "Desktop" : "";

    /* ------------ VARIABLES ------------ */
    const swipeData: any[] = [
        {
            image: ImageDataset[`moodJoy${moodVersionText}`],
            id: 0,
            alt: "Emotion Joy",
        },
        {
            image: ImageDataset[`moodLove${moodVersionText}`],
            id: 1,
            alt: "Emotioj Love",
        },
        {
            image: ImageDataset[`moodCare${moodVersionText}`],
            id: 2,
            alt: "Emotion Care",
        },
    ];

    /* ------------ METHODS ------------ */
    const onRightPress = () => {
        if (!canSwipe) return;
        SoundPlayer.playSound("click");
        setSwipeMood(1);
        setCanSwipeMood(false);

        setTimeout(() => {
            setCanSwipeMood(true);
        }, 500);
    };

    const onLeftPress = () => {
        if (!canSwipe) return;
        SoundPlayer.playSound("click");

        setSwipeMood(-1);
        setCanSwipeMood(false);

        setTimeout(() => {
            setCanSwipeMood(true);
        }, 500);
    };

    useEffect(() => {
        const newSwipeIndex = Math.max(
            0,
            Math.min(swipe, swipeData.length - 1),
        );
        const offset = Dimensions.get("window").width * newSwipeIndex;

        flatListRef.current?.scrollToOffset({
            animated: true,
            offset,
        });
    }, [swipe]);

    return (
        <>
            <MotiView
                from={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ type: "timing", duration: 750 }}
                exit={{ opacity: 0 }}
                style={{
                    /* overflow: "hidden", */
                    position: "absolute",
                    width: Dimensions.get("window").width,
                    height: Dimensions.get("window").height,
                    minWidth: Dimensions.get("window").width,
                    minHeight: Dimensions.get("window").height,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                }}
            >
                {/* ------------ CAROUSEL ------------ */}
                <FlatList
                    ref={(r) => {
                        if (r !== null) flatListRef.current = r;
                    }}
                    style={styles.flatListCarousel}
                    data={swipeData}
                    alwaysBounceHorizontal={true}
                    renderItem={({ item }) => {
                        return (
                            <View style={styles.flatListCarousel}>
                                <Image
                                    source={item.image}
                                    resizeMode={
                                        windowWidth < 600 ? "cover" : "cover"
                                    }
                                    style={styles.flatListCarousel}
                                    alt={item.alt}
                                />
                            </View>
                        );
                    }}
                    keyExtractor={(item, index) => index.toString()}
                    horizontal
                    pagingEnabled
                    showsHorizontalScrollIndicator={false}
                />
            </MotiView>
            {/* ------------ CAROUSEL ARROW ------------ */}
            <View
                style={[
                    styles.carousel,
                    windowWidth < 600 ? { paddingHorizontal: 0 } : {},
                    falseModal !== null ? { zIndex: 5 } : {},
                ]}
            >
                {/* ------------ LEFT ARROW ------------ */}
                <Pressable
                    id='pressable_left'
                    style={[
                        innerWidth > 512 && innerWidth < 900
                            ? styles.arrowTablet
                            : styles.arrow,
                        { alignItems: "flex-start" },
                        windowWidth < 600 ? { height: 30 } : {},
                    ]}
                    onPress={onLeftPress}
                >
                    <SvgComponent type='left-arrow' />
                    {/* TESTO PER NON VEDENTI */}
                    <BvlgariText
                        text={"Go to previous emotion"}
                        style={{
                            color: "transparent",
                            opacity: 0,
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }}
                    />
                </Pressable>

                {/* ------------ RIGHT ARROW ------------ */}
                <Pressable
                    id='pressable_right'
                    style={[
                        innerWidth > 512 && innerWidth < 900
                            ? styles.arrowTablet
                            : styles.arrow,
                        { alignItems: "flex-end" },
                        windowWidth < 600 ? { height: 30 } : {},
                    ]}
                    onPress={onRightPress}
                >
                    <SvgComponent type='right-arrow' />
                    {/* TESTO PER NON VEDENTI */}
                    <BvlgariText
                        text={"Go to next emotion"}
                        style={{
                            color: "transparent",
                            opacity: 0,
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }}
                    />
                </Pressable>
            </View>
        </>
    );
};

export default MoodCarousel;
