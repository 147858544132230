import { useEffect, useState } from "react";
import { shareUrl, translate } from "../utils/utils";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import BvlgariText from "../components/TextElement/BvlgariText";

export default function DownloadLandingPage() {
  const [isDownloading, setIsDownloading] = useState(false);
  const downloadPage = new URLSearchParams(window.location.search).get(
    "download"
  );
  const baseUrl =
    "https://bulgari-digital-craft.s3.eu-central-1.amazonaws.com/magnificentmoments/";

  //const link = https://bulgari-digital-craft.s3.eu-central-1.amazonaws.com/bulgari_videos/bzero1.mp4'
  const onPress = async () => {
    try {
      setIsDownloading(true);
      //await shareUrl(baseUrl, "_self");

      await shareUrl(baseUrl + downloadPage + ".mp4", "_self");
    } catch (error) {
      console.log(error);
    }
    setIsDownloading(false);
  };

  return (
    <View style={styles.container}>
      <video
        //ref={videoRef}
        style={{
          position: "absolute",
          objectFit: "cover",
          bottom: 0,
          top: 0,
          height: "100%",
          width: "100%",
        }}
        autoPlay
        muted
        loop
        playsInline
      >
        <source
          //src={baseUrl}
          src={baseUrl + downloadPage + ".mp4"}
          type="video/mp4"
        />
      </video>
      {/* <BvlgariText text={'Click the button to download the experience'} textType="mBRegular" style={styles.title} /> */}
      <div style={{ paddingBottom: 40 }}>
        <TouchableOpacity style={styles.button} onPress={onPress}>
          <BvlgariText
            text={
              isDownloading
                ? "Downloading.."
                : translate("$finalVideoPage.buttonDownloadArtwork")
            }
            textType="mBRegular"
            style={styles.buttonText}
          />
        </TouchableOpacity>
      </div>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end", // Allinea il contenuto alla parte inferiore
  },

  title: {
    color: "#473213",
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 20,
    zIndex: 1,
  },
  button: {
    backgroundColor: "rgba(216, 193, 174, 0.2)",
    padding: 20,
    borderRadius: 6,
    borderWidth: 2,
    alignSelf: "center", // Centra rispetto all'altezza dello schermo
    boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.15)",
    backdropFilter: "blur(18px)",
  },
  buttonText: {
    color: "rgb(71, 50, 19)",
    textTransform: "uppercase",
    fontSize: 15,
  },
});
